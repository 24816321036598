import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Translator, { __ } from "services/Translator";
import SendFeedbackForm from "elements/SendFeedbackForm";

const SendFeedback = (props) => {
  const [fullContent, setFullContent] = useState(false);
  return (
    <>
      <div className="feedback_container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div
                className={
                  "feedback_content_area " +
                  (fullContent ? "showFull" : "showLess")
                }
              >
                {/* <SendFeedbackForm {...props} /> */}
                <p>
                  <strong>Miss Slotty&apos;s Ultimate Slots Adventure</strong>
                </p>

                <p>
                  Embark on an exhilarating journey through Miss Slotty&apos;s
                  world of over 7,000 unique slot games! From nostalgic classics
                  to cutting-edge Megaways, our collection showcases an
                  impressive diversity, highlighting partnerships with over 100
                  renowned online game developers. Experience the thrill of a
                  real casino and the chance to win big with our vibrant and
                  captivating slot selection.
                </p>

                <p>
                  <strong>Revolutionizing Online Slot Machines</strong>
                </p>

                <p>
                  Witness the remarkable evolution of slot machines from their
                  humble beginnings to today&apos;s digital marvels. No need to
                  travel to Las Vegas &ndash; Miss Slotty brings the excitement
                  of one-armed bandits straight to you, online. With Genie
                  Jackpot, you&apos;re just a few clicks away from the heart of
                  gambling entertainment, neatly categorized and featuring
                  transparent, fair, and thrilling gameplay.
                </p>

                <p>
                  <strong>Mastering Video Slots</strong>
                </p>

                <p>
                  Dive into the world of video slots with our comprehensive
                  guide, featuring insights from the industry&apos;s top
                  providers. Learn about the evolution from classic one-line
                  slots to complex Megaways with over 100,000 winning
                  combinations. Discover the unique features, RTP, volatility,
                  and bonus structures that set each game apart, all detailed in
                  our expertly crafted article on slots.
                </p>

                <p>
                  <strong>Exploring Themes and Graphics in Slots</strong>
                </p>

                <p>
                  Immerse yourself in the diverse themes and stunning graphics
                  that modern slots offer. From traditional one-armed bandit
                  designs to advanced game mechanics, we showcase the creative
                  prowess of top providers. Experience the thrill of Megaways,
                  where each spin could lead to over 100,000 ways to win, as
                  detailed in our in-depth slots guide.
                </p>

                <p>
                  <strong>Explore the Thrill of Crash Games</strong>
                </p>

                <p>
                  Step into the fast-paced world of crash games, where strategy
                  and timing are key. Each round challenges you to cash out
                  before the crash, offering a blend of excitement and tactical
                  play. Our guide introduces you to essential strategies and
                  features like auto cash-out and bonus rounds, enhancing your
                  gaming experience.
                </p>

                <p>
                  These games aren&apos;t just thrilling; they&apos;re
                  versatile, supporting various cryptocurrencies like Ethereum,
                  Tron, Tether, Litecoin, Dogecoin, and Bitcoin. This diversity
                  ensures a seamless and secure gaming experience, tailored to
                  your preference.
                </p>

                <p>
                  Perfect for both newcomers and seasoned players, our crash
                  games provide a dynamic and engaging way to potentially
                  multiply your cryptocurrency in an environment of calculated
                  risk and reward. Join the adventure and discover the unique
                  rush of crash games today!
                </p>
                <p>Celebrating Top Software Providers</p>

                <p>
                  Miss Slotty celebrates the artistry of over a hundred software
                  providers, from fan favorites to emerging talents. Discover
                  games from Pragmatic Play, Spinomenal, Wazdan, and more, each
                  offering their unique spin on exciting tournaments with
                  substantial prize pools. Find your favorite provider easily
                  with our intuitive search system and customize your gaming
                  experience in our crypto world of fun.
                </p>

                <p>
                  <strong>Unmatched Betting Opportunities</strong>
                </p>

                <p>
                  Miss Slotty offers unparalleled flexibility in (crypto /
                  multi) currency betting. Play with regular currency or with
                  Bitcoin and a variety of other cryptocurrencies, earning
                  rewards in games that pay out in Bitcoin, BFG, ETH, TRX, USDT,
                  and more. Mine, bet, and play to potentially become a
                  successful crypto owner.
                </p>

                <p>
                  <strong>Generous Free Spins and No Deposit Bonuses</strong>
                </p>

                <p>
                  New players at Miss Slotty are greeted with an enticing
                  Welcome Pack, including free spins for video slots and a range
                  of lucrative crypto offers. Register now to unlock these
                  exclusive benefits and enhance your gaming journey.
                </p>

                <p>
                  <strong>
                    Participate in Exciting Online Gambling Events
                  </strong>
                </p>

                <p>
                  Join the vibrant community of players in thrilling tournaments
                  and competitions hosted by Miss Slotty and top providers. With
                  valuable rewards and continuous updates on our social
                  networks, you won&apos;t want to miss out on these captivating
                  events. Join us at Miss Slotty for an unforgettable online
                  gambling experience!
                </p>
              </div>
              <Link
                className="commonBtn filledBtn"
                onClick={() => {
                  setFullContent(!fullContent);
                }}
              >
                {fullContent ? "Show Less" : "Show More"}
              </Link>
              <br />
            </div>
            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default SendFeedback;
