import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link, useHistory, useParams } from "react-router-dom";

import api from "services/api";
import ls from "services/ls";
import emailValidate from "services/emailValidate";
import CategoryGames from "elements/games_elements/CategoryGames";
import GamesFilter from "elements/games_elements/GamesFilter";
import Translator, { __ } from "services/Translator";
// new design_v1
import HomeBanner from "elements/home/HomeBanner";
import GameSlider from "elements/games_elements/GameSlider";
import LandingDesktopContent from "elements/LandingDesktopContent";
import LandingMobileContent from "elements/LandingMobileContent";

export default function Landing(props) {
  const params = useParams();
  const [gameList, setGameList] = useState([]);
  const history = useHistory();
  const closeAllModals = () => {
    history.push("/");
  };

  const justActivated = props.activated ? true : false;
  const showCashier = props.showCashier ? true : false;

  const [gameSorting, setGameSorting] = useState(null);
  const [pagination, setPagination] = useState(null);
  const getGameList = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 20,
      game_types: ["popular"],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);

      if (page === 1) {
        setGameList(response.data.games?.popular?.gamelist ?? []);
      } else {
        var gamesClone = JSON.parse(JSON.stringify(gameList));
        console.log("gamesClone", gamesClone);
        gamesClone = [...gamesClone, ...response.data.games["popular"].gamelist];
        setGameList(gamesClone);
      }

      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    getGameList(page);
  };

  useEffect(() => {
    getGameList(1);
  }, [gameSorting, props.selectedProviders]);

  useEffect(() => {
    if (showCashier) {
      setTimeout(() => {
        props.setOpenWalletModalTab("deposit");
      }, 2000);
    }
  }, [showCashier]);

  useEffect(() => {
    if (props.openSignup) {
      props.setModalSignUpPropsData(true);
      setTimeout(function () {
        props.setModalSignUpPropsData(false);
      }, 500);
    }
  }, [props.openSignup]);

  useEffect(() => {
    if (params.referralCode) {
      ls.set("referralCode", params.referralCode);
      props.setReferralCode(params.referralCode);
    } else if (ls.get("referralCode")) {
      props.setReferralCode(ls.get("referralCode"));
    }
  }, [params.referralCode]);

  // expand searcbar

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      console.log("getSearchSuggestions", response.data);
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  const [visible, setVisible] = useState(true);
  const hideNoImageFoundGames = () => {
    setVisible(false);
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 500,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [searchFocus, setSearchFocus] = useState(false);
  useEffect(() => {
    console.log("PROPS", props);
    if (props.location.state && props.location.state.focusSearch) {
      setSearchFocus(true);
      document.getElementById("searchFocusInput").focus();
    } else {
      setSearchFocus(false);
    }
  }, [params]);

  //Newsletter section
  const [subscribeEmail, setSubscribeEmail] = useState("");

  const newsletterSubscription = async (ev) => {
    ev.preventDefault();
    var valid = true;
    if (subscribeEmail.length < 2 || subscribeEmail.length > 250) {
      props.showAlert("Warning!!", __("Please enter your email address"));
      valid = false;
    }
    if (!emailValidate(subscribeEmail)) {
      props.showAlert("Warning!!", __("Please enter your email address"));
      valid = false;
    }

    if (valid) {
      var response = await api.post("/newsletter-subscription", {
        subscribeEmail: subscribeEmail,
      });

      if (response.status === 200) {
        props.showAlert("Success!!", response.data.message);
        setSubscribeEmail("");
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
    }
  };

  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  useEffect(async () => {
    checkIframe();
  }, []);

  return (
    <div className="landing">
      <HomeBanner {...props} />
      {props.isMobileDevice && <GamesFilter {...props} />}

      <div className="main_content_area">
        {!props.isMobileDevice && <LandingDesktopContent {...props} />}
        {props.isMobileDevice && <LandingMobileContent {...props} />}
        {/* <TypeBanner {...props} /> */}

        {/* <PromotionSlider categoryName="Slots" showLebel={"Promotions"} /> */}

        <div class="buy-crypto-block d-none">
          <span class="buy-crypto-block__text">Buy crypto quickly and easily</span>
          <div class="buy-crypto-block__methods">
            <div class="buy-crypto-block__method">
              <img src={require("assets/images/v2/icons/gpay.webp").default} alt="Google Pay" />
            </div>
            <div class="buy-crypto-block__method">
              <img src={require("assets/images/v2/icons/applepay.webp").default} alt="Apple Pay" />
            </div>
            <div class="buy-crypto-block__method">
              <img src={require("assets/images/v2/icons/mastercard.webp").default} alt="Mastercard" />
            </div>
            <div class="buy-crypto-block__method">
              <img src={require("assets/images/v2/icons/visa.webp").default} alt="Visa" />
            </div>
          </div>

          {!props.isAuthenticated && (
            <Link
              onClick={() => {
                props.setModalSignUpPropsData(true);
                setTimeout(function () {
                  props.setModalSignUpPropsData(false);
                }, 500);
              }}
              className="commonBtn filledBtn"
            >
              {__("Sign Up & Buy Crypto")}
            </Link>
          )}

          {props.isAuthenticated && (
            <Link
              onClick={() => {
                props.setOpenWalletModalTab("deposit");
              }}
              className="commonBtn filledBtn"
            >
              {__("Buy Crypto  |  Deposit")}
            </Link>
          )}
        </div>

        {/* <Winner {...props} /> */}
      </div>

      {/* <HomeSlider {...props} /> */}

      {/* <div className="main_content_area"></div> */}
      <div className="containerss d-none">
        <div className="game_part_area">
          <GamesFilter {...props} />
          <CategoryGames
            {...props}
            gameList={gameList}
            categoryName=""
            category="all"
            setGameSorting={setGameSorting}
            gameSorting={gameSorting}
          />
        </div>
      </div>

      {/* <GameList /> */}
      {/* <BottomBanner {...props} /> */}
    </div>
  );
}
