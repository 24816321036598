import React, { useState, useEffect } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Redirect, Route, Link } from "react-router-dom";

import Translator, { __ } from "services/Translator";
import api from "services/api";

const GameItem = ({ game, isAuthenticated }) => {
  const [visible, setVisible] = useState(true);
  const [processed, setProcessed] = useState(false);
  const loaderImage = require("assets/images/logos/game-loader.gif").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(loaderImage);
  const defaultImage = require("assets/images/games/default.png").default;
  const params = useParams();

  const gameCategory = params.category ? params.category : "all";

  const hideNoImageFoundGames = () => {
    // setVisible(false);
    setGameSnapshotUrl(defaultImage);
  };

  const loadGameImage = () => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      setProcessed(true);
    }
  };

  const fixImageRatio = (event) => {
    if (!processed) {
      setGameSnapshotUrl(game.game_snapshot_url);
      var img = event.target;
      console.log(img.width);
      var width = img.width;
      var height = width * 1;
      img.height = height;
      setTimeout(() => {
        //Sometimes the width is read wrong the first time
        //This is the fallback to fix it
        var width = img.width;
        var height = width * 1;
        img.height = height;
      }, 1000);
      setProcessed(true);
    }
  };

  useEffect(() => {
    // setProcessed(false);
    // setVisible(true);
    // setGameSnapshotUrl(loaderImage);

    setVisible(true);
    setGameSnapshotUrl(loaderImage);
    setProcessed(false);
  }, [game.id]);
  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", { gameId: game.id });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  return (
    <>
      <div className="single_game_v1">
        <div className="hoverSelector"></div>

        <div className="game_thumb">
          <Link
            hrefLang={Translator.getSelectedLanguage().code}
            to={"/play/" + game.slug}
          >
            <img
              src={gameSnapshotUrl}
              onError={hideNoImageFoundGames}
              onLoad={fixImageRatio}
              alt={game.game_name ?? ""}
            />
            {/* <div className="provider_name">{game.provider_id}</div> */}
          </Link>
          <div class="game_overlay d-none">
            {!!game.rtp && (
              <div className="rtp_info">
                <div className="left rtp_text">{game.rtp}% RTP</div>
                <div className="right d-none">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    viewBox="0 0 128 128"
                  >
                    <path d="M 64 6 C 32 6 6 32 6 64 C 6 96 32 122 64 122 C 96 122 122 96 122 64 C 122 32 96 6 64 6 z M 64 12 C 92.7 12 116 35.3 116 64 C 116 92.7 92.7 116 64 116 C 35.3 116 12 92.7 12 64 C 12 35.3 35.3 12 64 12 z M 64 30 A 9 9 0 0 0 64 48 A 9 9 0 0 0 64 30 z M 64 59 C 59 59 55 63 55 68 L 55 92 C 55 97 59 101 64 101 C 69 101 73 97 73 92 L 73 68 C 73 63 69 59 64 59 z"></path>
                  </svg>
                </div>
              </div>
            )}
            <div class="game_action">
              {!isAuthenticated && (
                <Link
                  class="game_play_icon"
                  to={"/play/" + game.slug + "/demo"}
                >
                  <i class="fas fa-play"></i>
                </Link>
              )}
              {isAuthenticated && (
                <Link
                  class="game_play_icon"
                  to={"/play/" + game.slug + "/real"}
                >
                  <i class="fas fa-play"></i>
                </Link>
              )}
            </div>
            <div className="game_info">
              <div className="left">
                <h3 class="game_name">{game.game_name}</h3>
                <h4 class="game_provider_name">{game.provider_id}</h4>
              </div>
              <div className="right">
                {isAuthenticated && (
                  <Link onClick={toggleFavoriteGame} className="favorite_icon">
                    {isFavorite ? (
                      <>
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2081_3472)">
                            <path
                              d="M14.6 0C12.5 0 10.8 1.3 10 2C9.2 1.3 7.4 0 5.4 0C2.4 0 0 2.4 0 5.4C0 7.2 1.7 8.9 1.8 9L10 17.2L18.2 9C18.3 8.9 20 7.2 20 5.4C20 2.4 17.6 0 14.6 0Z"
                              fill="#4698ED"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2081_3472">
                              <rect width="20" height="17.2" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          width="20"
                          height="18"
                          viewBox="0 0 20 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_2081_1449)">
                            <path
                              d="M5.4 0C2.4 0 0 2.4 0 5.4C0 6.4 0.5 7.3 0.9 7.9C1.3 8.5 1.8 8.9 1.8 8.9L9.5 16.6L10 17.1L10.5 16.6L18.2 9C18.2 9 20 7.4 20 5.4C20 2.4 17.6 0 14.6 0C12.1 0 10.6 1.5 10 2.1C9.4 1.5 7.8 0 5.4 0ZM5.4 1.4C7.5 1.4 9.5 3.5 9.5 3.5L10 4.1L10.5 3.5C10.5 3.5 12.5 1.4 14.6 1.4C16.8 1.4 18.5 3.2 18.5 5.3C18.5 6.4 17.2 7.9 17.2 7.9L10 15.2L2.8 7.9C2.8 7.9 2.5 7.6 2.1 7.1C1.7 6.6 1.4 5.9 1.4 5.4C1.4 3.2 3.2 1.4 5.4 1.4Z"
                              fill="#4698ED"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2081_1449">
                              <rect width="20" height="17.2" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </>
                    )}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <Link className="goToBtn" to={"/play/" + game.slug + "/real"}>
          {__("Play")}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M2.5 8H13.5"
              stroke="#057383"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9 3.5L13.5 8L9 12.5"
              stroke="#057383"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Link>
        <div className="gameName">{game.game_name}</div>
      </div>
    </>
  );
};

export default GameItem;
