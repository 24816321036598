import { Accordion } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SelectBox from "elements/SelectBox";
import InputBox from "elements/InputBox";
import Pagination from "elements/Pagination";
import DateSelector from "elements/DateSelector";
import NoRecordsFound from "elements/NoRecordsFound";
import DOMPurify from "dompurify";
import api from "services/api";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function Notifications(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/player/notifications", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  const deleteItem = async (itemId) => {
    if (window.confirm("Confirm deletion?")) {
      var response = await api.post("/player/notification/delete", {
        id: itemId,
      });
      props.showAlert(null, response.data.message, response.status !== 200);
      getResults();
    }
  };

  return (
    <>
      <PageHeadline page_name={__("Notifications")} />
      <div className="page_content_area">
        <div className="table_area">
          <div className="d-block player_message_container">
            {results.length ? (
              <>
                {results.map((item, index) => (
                  <ul className="notifications-list">
                    {results.map((val, index) => (
                      <li className="notification-item white" key={index}>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(val.notification_str),
                          }}
                        ></p>
                        <div className="notification-time">
                          {val.notification_time}
                        </div>
                        <a
                          href="#"
                          className="delete_notification"
                          onClick={() => deleteItem(val.id)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </a>
                      </li>
                    ))}
                  </ul>
                ))}

                <div className="paginationArea">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
