import React, { useState, useEffect, useRef } from "react";
import Translator, { __ } from "services/Translator";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import api from "services/api";
import moneyFormatter from "services/moneyFormatter";

export default function VipLevels(props) {
  const sliderRef = useRef();
  const setSpinner = props.setSpinner;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    autoplaySpeed: 10000,
    arrows: true,
    // className: "center",
    centerMode: false,
    // slidesToShow: 1,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,

        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
        },
      },
    ],
  };

  const [profile, setProfile] = useState({});
  const [ranks, setRanks] = useState([]);

  const getRanks = async () => {
    setSpinner(true);
    var response = await api.get("/player-levels");
    setSpinner(false);
    if (response.status === 200) {
      setRanks(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  useEffect(() => {
    getRanks();
  }, []);

  const [selectedMenu, setSelectedMenu] = useState(1);
  const handleClick = (slideNumber) => {
    setSelectedMenu(slideNumber);
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(slideNumber - 1);
    }
  };
  return (
    <div className="vip_levels_area">
      <div className="vipLavelTop">
        <h3 className="rewards_common_block_heading">{__("VIP Levels")}</h3>

        <ul className="tabMenu">
          <li
            onClick={() => {
              handleClick(1);
            }}
            className={selectedMenu == 1 ? "active" : ""}
          >
            {__("Bronze")}
            <div className="selectedMenuBar"></div>
          </li>
          <li
            onClick={() => {
              handleClick(6);
            }}
            className={selectedMenu == 6 ? "active" : ""}
          >
            {__("Silver")}
            <div className="selectedMenuBar"></div>
          </li>
          <li
            onClick={() => {
              handleClick(11);
            }}
            className={selectedMenu == 11 ? "active" : ""}
          >
            {__("Gold")}
            <div className="selectedMenuBar"></div>
          </li>
          <li
            onClick={() => {
              handleClick(16);
            }}
            className={selectedMenu == 16 ? "active" : ""}
          >
            {__("Platinum")}
            <div className="selectedMenuBar"></div>
          </li>
          <li
            onClick={() => {
              handleClick(21);
            }}
            className={selectedMenu == 21 ? "active" : ""}
          >
            {__("Diamond")}
            <div className="selectedMenuBar"></div>
          </li>
        </ul>
      </div>

      <ul>
        <Slider ref={sliderRef} {...settings}>
          {ranks.map((rank, index) => (
            <li
              className={
                "rank_item " + (rank.level == profile.identity ? "active" : "")
              }
            >
              <div className="content">
                <div className="badge">
                  {rank.level <= 25 && (
                    <img
                      alt=""
                      className="main"
                      src={
                        require("../../assets/images/kjb/rewards/webp/level_icon_" +
                          rank.level +
                          ".png").default
                      }
                    />
                  )}
                  <div className="stars d-none">
                    <>
                      {new Array(Math.ceil(rank.level / 3))
                        .fill(1)
                        .map((item, index) => (
                          <img
                            alt=""
                            src={
                              require("assets/images/kjb/icons/star-icon.png")
                                .default
                            }
                          />
                        ))}
                    </>
                  </div>
                </div>
                <div className="level_content">
                  <h3 className="level_heading">{rank.level_name}</h3>
                  <h5 className="sub_heading">
                    {moneyFormatter.balanceFormatDE(rank.required_deposit)}{" "}
                    {__("Coins")}
                  </h5>
                  <p className="level_info">
                    {rank.level == 1 && (
                      <span className="d-none">
                        {__("20 Free Spins")} <br /> {__("1 Silver-Wheel Spin")}
                      </span>
                    )}
                    {rank.level == 2 && (
                      <span>
                        {__("25 Free Spins")} <br /> {__("1 Silver-Wheel Spin")}
                      </span>
                    )}
                    {rank.level == 3 && (
                      <span>
                        {__("35 Free Spins")} <br /> {__("1 Silver-Wheel Spin")}
                      </span>
                    )}
                    {rank.level == 4 && (
                      <span>
                        {__("45 Free Spins")} <br /> {__("1 Silver-Wheel Spin")}
                      </span>
                    )}
                    {rank.level == 5 && (
                      <span>
                        {__("50 Free Spins")} <br /> {__("1 Silver-Wheel Spin")}
                      </span>
                    )}
                    {rank.level == 6 && (
                      <span>
                        {__("50 Free Spins")}
                        <br />
                        {__("1 Silver-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 7 && (
                      <span>
                        {__("50 Free Spins")}
                        <br />
                        {__("1 Silver-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 8 && (
                      <span>
                        {__("50 Free Spins")}
                        <br />
                        {__("1 Silver-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 9 && (
                      <span>
                        {__("50 Free Spins")}
                        <br />
                        {__("1 Silver-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 10 && (
                      <span>
                        {__("50 Free Spins")}
                        <br />
                        {__("1 Silver-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 11 && (
                      <span>
                        {__("€50 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("5% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 12 && (
                      <span>
                        {__("€50 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("6% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 13 && (
                      <span>
                        {__("€50 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("7% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                      </span>
                    )}
                    {rank.level == 14 && (
                      <span>
                        {__("€50 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("8% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 15 && (
                      <span>
                        {__("€50 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("9% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 16 && (
                      <span>
                        {__("€75 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("10% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 17 && (
                      <span>
                        {__("€75 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("11% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 18 && (
                      <span>
                        {__("€75 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("12% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 19 && (
                      <span>
                        {__("€75 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("13% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 20 && (
                      <span>
                        {__("€75 Cash")}
                        <br />
                        {__("50 Free Spins")}
                        <br />
                        {__("14% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 21 && (
                      <span>
                        {__("€125 Cash")}
                        <br />
                        {__("125 Free Spins")}
                        <br />
                        {__("15% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 22 && (
                      <span>
                        {__("€125 Cash")}
                        <br />
                        {__("125 Free Spins")}
                        <br />
                        {__("16% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 23 && (
                      <span>
                        {__("€250 Cash")}
                        <br />
                        {__("250 Free Spins")}
                        <br />
                        {__("17% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 24 && (
                      <span>
                        {__("€500 Cash")}
                        <br />
                        {__("500 Free Spins")}
                        <br />
                        {__("18% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                    {rank.level == 25 && (
                      <span>
                        {__("€1000 Cash")}
                        <br />
                        {__("1000 Free Spins")}
                        <br />
                        {__("20% Cashback")}
                        <br />
                        {__("1 Gold-Wheel Spin")}
                        <br />
                        {__("VIP Manager")}
                        <br />
                        {__("+ Extra Perks")}
                      </span>
                    )}
                  </p>
                  <p className="level_info d-none">
                    {rank.level >= 6 && rank.level <= 10 && (
                      <span>{__("1 Silver-Wheel Spin")}</span>
                    )}
                    {rank.level >= 11 && <span>{__("1 Gold-Wheel Spin")}</span>}
                    <br />
                    {rank.level >= 11 && rank.level <= 20 && (
                      <span>
                        {rank.level - 6}% {__("Cashback")}
                      </span>
                    )}
                    {rank.level >= 21 && rank.level <= 24 && (
                      <span>
                        {rank.level - 7}% {__("Cashback")}
                      </span>
                    )}
                    {rank.level == 25 && <span>20% {__("Cashback")}</span>}
                    <br />
                    {rank.level >= 1 && rank.level <= 5 && (
                      <span>5% {__("Rakeback")}</span>
                    )}
                    {rank.level >= 6 && rank.level <= 8 && (
                      <span>6% {__("Rakeback")}</span>
                    )}
                    {rank.level >= 9 && rank.level <= 10 && (
                      <span>7% {__("Rakeback")}</span>
                    )}
                    {rank.level >= 11 && rank.level <= 12 && (
                      <span>8% {__("Rakeback")}</span>
                    )}
                    {rank.level >= 13 && rank.level <= 15 && (
                      <span>10% {__("Rakeback")}</span>
                    )}
                    {rank.level == 16 && <span>12% {__("Rakeback")}</span>}
                    {rank.level >= 17 && rank.level <= 18 && (
                      <span>13% {__("Rakeback")}</span>
                    )}
                    {rank.level == 19 && <span>14% {__("Rakeback")}</span>}
                    {rank.level >= 20 && rank.level <= 22 && (
                      <span>15% {__("Rakeback")}</span>
                    )}
                    {rank.level == 23 && <span>16% {__("Rakeback")}</span>}
                    {rank.level == 24 && <span>17% {__("Rakeback")}</span>}
                    {rank.level == 25 && <span>20% {__("Rakeback")}</span>}
                    <br />

                    {rank.level >= 2 && rank.level <= 15 && (
                      <span>50 {__("Free spins")}</span>
                    )}
                    {rank.level >= 16 && rank.level <= 20 && (
                      <span>75 {__("Free spins")}</span>
                    )}
                    {rank.level >= 21 && rank.level <= 23 && (
                      <span>125 {__("Free spins")}</span>
                    )}
                    {rank.level >= 24 && <span>150 {__("Free spins")}</span>}
                    <br />

                    {rank.level >= 11 && rank.level <= 15 && (
                      <span>50% {__("One time deposit bonus")}</span>
                    )}
                    {rank.level >= 16 && rank.level <= 20 && (
                      <span>75% {__("One time deposit bonus")}</span>
                    )}
                    {rank.level >= 21 && rank.level <= 23 && (
                      <span>125% {__("One time deposit bonus")}</span>
                    )}
                    {rank.level >= 24 && (
                      <span>150% {__("One time deposit bonus")}</span>
                    )}
                    <br />
                    {rank.level >= 14 && <span>{__("VIP Manager")}</span>}
                  </p>
                </div>
                <div className="level_content d-none">
                  <span>
                    {__("Wheel:")}{" "}
                    <b>
                      {rank.level > 9
                        ? __("Diamond")
                        : rank.level > 6
                        ? __("Gold")
                        : rank.level > 5
                        ? __("Silver")
                        : __("Bronze")}
                    </b>
                  </span>
                  <br />
                  <span>
                    {__("Cashback:")}{" "}
                    <b>
                      {" "}
                      {rank.level <= 3
                        ? 12
                        : rank.level <= 6
                        ? 14
                        : rank.level <= 9
                        ? 16
                        : rank.level <= 12
                        ? 18
                        : rank.level <= 15
                        ? 20
                        : ""}
                      %
                    </b>
                  </span>
                  <br />
                  <span>
                    {__("Rakeback:")}{" "}
                    <b>
                      {rank.level == 15
                        ? 12
                        : rank.level == 14
                        ? 17
                        : rank.level == 13
                        ? 15
                        : parseInt(rank.level) + 1}
                      %
                    </b>
                  </span>
                  <br />
                  <span>
                    {__("Personal Manager:")}{" "}
                    <b>{rank.level > 12 ? __("Yes") : __("No")}</b>
                  </span>
                  <br />
                  <span>
                    {__("FreeSpins:")}{" "}
                    <b>
                      {rank.level >= 13
                        ? 500
                        : rank.level >= 10
                        ? 200
                        : rank.level >= 7
                        ? 100
                        : rank.level >= 4
                        ? 50
                        : 0}
                    </b>
                  </span>
                  <br />
                  <span>
                    {__("Bonus:")}{" "}
                    <b>
                      {rank.level >= 13
                        ? 125
                        : rank.level >= 10
                        ? 125
                        : rank.level >= 7
                        ? 75
                        : rank.level >= 4
                        ? 50
                        : 0}
                      %
                    </b>
                  </span>
                </div>
                {/* <div className="level_bottom">{rank.level_name}</div> */}
                <div className="level_bottom">
                  {__("Level ")}
                  {rank.level}
                </div>
              </div>
            </li>
          ))}
        </Slider>
      </ul>
    </div>
  );
}
