import React, { useState, useEffect, useRef } from "react";
import Translator, { __ } from "services/Translator";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
export default function WheelInformation(props) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3500,
    speed: 1000,
    arrows: true,
    fade: false,
    slidesToShow: 4,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const [showAffiliateTermsModal, setShowAffiliateTermsModal] = useState(false);

  return (
    <>
      <div className="wheelInformaionContainer">
        <div className="wheel_banner">
          <div className="banner_content">
            <h1 className="banner_heading">
              Slotty <span>Wheel</span>
            </h1>
            <h4 className="banner_sub_heading">
              {__("Guaranteed Prizes Worth ")}
              <br />
              {__("up to €2,000!")}
            </h4>
          </div>
          <img
            alt=""
            src={require("../assets/images/kjb/wheels/banner.jpg").default}
          />
        </div>
        <div className="how_it_works">
          <div className="heading">{__("How it works")}</div>
          <ul className="items">
            <li>
              <img
                alt=""
                src={
                  require("../assets/images/kjb/wheels/level-up-icon.png")
                    .default
                }
              />
              <br />
              <label>{__("Level Up")}</label>
              <br />
              <p>
                {__(
                  "Climb the ranks to unlock the Slotty Wheel for every status level-up"
                )}
              </p>
            </li>
            <li>
              <img
                alt=""
                src={
                  require("../assets/images/kjb/wheels/wheel-icon.png").default
                }
              />
              <br />
              <label>{__("Spin the Wheel")}</label>
              <br />
              <p>{__("The bonus is awarded immediately")}</p>
            </li>
            <li>
              <img
                alt=""
                src={
                  require("../assets/images/kjb/wheels/guaranteed-prizes-icon.png")
                    .default
                }
              />
              <br />
              <label>{__("Guaranteed Prizes")}</label>
              <br />
              <p>
                {__("Silver: up to 1 Gold Spin")}
                <br />
                {__("Gold: up to €2.000")}
              </p>
            </li>
          </ul>
        </div>

        <center>
          <Link to="/vip" className="commonBtn bannerBtn">
            {__("Miss Slotty VIP Club")}
          </Link>
        </center>
        <br />
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {" "}
              {__("Miss Slotty's Wheel Of Fortune Terms")}
            </Accordion.Header>
            <Accordion.Body>
              <p className="wheel_info">
                {__(
                  "For each level achieved in the VIP program, the player gets to spin a wheel that gives them a random prize."
                )}
                <br />
                <br />
                {__(
                  "Spins are awarded automatically upon reaching a new level in the VIP program."
                )}
                <br />
                <br />
                {__("There are two types of wheels: Silver and Gold")}
                <br />
                <br />
                {__(
                  "The Silver random prizes are: 50 FS, 100 FS, €10, €20, €50, €200, 1 Gold Spin"
                )}
                <br />
                <br />
                {__(
                  "The Gold random prizes are: 300 FS, 400 FS, 500 FS, 1000 FS, €100, €200, €500, €2000"
                )}
                <br />
                <br />
                {__("The wagering requirements are: FS-x20, Cash-x20")}
                <br />
                <br />
                {__("The prize FS will be credited to")}
                <br />
                <br />
                {__(
                  "The time limit for wagering all promo bonuses is seven days. An awarded bonus becomes null in 7 days after getting it."
                )}
                <br />
                <br />
                {__(
                  "Miss Slotty has the right to end the promo campaign at any time."
                )}
                <br />
                <br />
                {__("General terms and conditions apply.")}
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}
