import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import api from "services/api";

// components
import ProfileDetails from "elements/dashboard_elements/ProfileDetails";
import Cashier from "elements/dashboard_elements/Cashier";
import AccountActvity from "elements/dashboard_elements/AccountActvity";
import TransactionHistory from "elements/dashboard_elements/TransactionHistory";
import GamingHistory from "elements/dashboard_elements/GamingHistory";
import FreeSpins from "elements/dashboard_elements/FreeSpins";
import Notifications from "elements/dashboard_elements/Notifications";
import Messages from "elements/dashboard_elements/Messages";
import AccessControl from "elements/dashboard_elements/AccessControl";
import FavoriteGames from "elements/dashboard_elements/FavoriteGames";
import ReferAndEarn from "elements/dashboard_elements/ReferAndEarn";
import NftHolders from "elements/dashboard_elements/NftHolders";
import Verify from "elements/dashboard_elements/Verify";
import Withdraw from "elements/dashboard_elements/Withdraw";
import { Tabs, Tab } from "react-bootstrap";
export default function UserDashboard(props) {
  const params = useParams();
  const tab = params.tab;
  const [activeTab, setActiveTab] = useState("account-details");
  const [activeTabName, setActiveTabName] = useState("Account Details");
  const history = useHistory();

  const closeDetails = () => {
    history.push("/games/all");
  };

  const [travelingToRight, setTravelingToRight] = useState(false);

  useEffect(() => {
    setActiveTab(tab);

    setTravelingToRight(true);
    setTimeout(function () {
      setTravelingToRight(false);
    }, 500);

    // alert(tab)
  }, [tab]);

  //Verify id checking

  const [level2submitted, setLevel2submitted] = useState(false);
  const [level2Verification, setLevel2Verification] = useState(false);

  const [level3submitted, setLevel3submitted] = useState(false);
  const [level3Verification, setLevel3Verification] = useState(false);

  const [level4submitted, setLevel4submitted] = useState(false);
  const [level4Verification, setLevel4Verification] = useState(false);

  const getIdUploads = async () => {
    var response = await api.get("/id-uploads");
    console.log("id-uploadsid-uploads", response);
    if (response.status === 200 && response.data) {
      if (response.data.poif && response.data.poib) {
        if (
          response.data.poif.approved == "approved" &&
          response.data.poib.approved == "approved"
        ) {
          setLevel2Verification(true);
        } else if (
          response.data.poif.approved == "submitted" &&
          response.data.poib.approved == "submitted"
        ) {
          setLevel2submitted(true);
        }
      }

      if (response.data.poa) {
        if (response.data.poa.approved == "approved") {
          setLevel3Verification(true);
        } else if (response.data.poa.approved == "submitted") {
          setLevel3submitted(true);
        }
      }
      if (response.data.sof) {
        if (response.data.sof.approved == "approved") {
          setLevel4Verification(true);
        } else if (response.data.sof.approved == "submitted") {
          setLevel4submitted(true);
        }
      }
    }
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      getIdUploads();
    }
  }, []);

  const [showMainNotification, setShowMainNotification] = useState(false);
  useEffect(() => {
    if (
      props.freeSpinCount > 0 ||
      props.notifictionCount > 0 ||
      props.messageCount ||
      !level2submitted ||
      !level3submitted
    ) {
      setShowMainNotification(true);
    } else {
      setShowMainNotification(false);
    }
  }, [props]);

  //Custom action for Tabs
  const [key, setKey] = useState("account");

  const handleSelect = (k) => {
    console.log(`Selected tab: ${k}`);
    // Add your custom action here
    customAction(k);
    setKey(k);
  };

  const customAction = (selectedKey) => {
    switch (selectedKey) {
      case "account":
        console.log("Home tab selected");
        // Add any custom logic for the Home tab here
        break;
      case "verify":
        console.log("Profile tab selected");
        // Add any custom logic for the Profile tab here
        break;
      case "TransactionHistory":
        console.log("Contact tab selected");
        // Add any custom logic for the Contact tab here
        break;

      case "AccountActivity":
        console.log("Contact tab selected");
        // Add any custom logic for the Contact tab here
        break;
      case "GamingHistory":
        console.log("Contact tab selected");
        // Add any custom logic for the Contact tab here
        break;
      case "ResponsibleGaming":
        console.log("Contact tab selected");
        // Add any custom logic for the Contact tab here
        break;
      default:
        break;
    }
  };

  //Edit Account Action
  const [editAccountAction, setEditAccountAction] = useState(false);

  const handleDataFromProfileDetails = (childData) => {
    setEditAccountAction(childData);
    if (childData == true) {
      setKey("verify");
    }
  };

  return (
    <div className="container">
      <div
        className={
          "user_dashboard " + (travelingToRight ? "travelingToRight" : "")
        }
      >
        {[
          "account-details",
          "verify",
          "TransactionHistory",
          "AccountActivity",
          "GamingHistory",
          "ResponsibleGaming",
        ].includes(activeTab) && (
          <>
            <div className="account_tabs">
              <Tabs
                defaultActiveKey="account"
                id="uncontrolled-tab-example"
                className="mb-3"
                activeKey={key}
                onSelect={handleSelect}
              >
                <Tab eventKey="account" title="Account">
                  <ProfileDetails
                    sendDataToParent={handleDataFromProfileDetails}
                    {...props}
                  />
                </Tab>
                <Tab
                  eventKey="verify"
                  title={
                    ((!level2submitted || !level3submitted) && (
                      <>
                        {"Verify "} <div className="notification_round"></div>
                      </>
                    )) ||
                    ((level2submitted || level3submitted) && (
                      <>
                        {"Verify "}{" "}
                        <div className="notification_round orenge"></div>
                      </>
                    ))
                  }
                >
                  {(!level2submitted || !level3submitted) && (
                    <div className="notification_round"></div>
                  )}

                  {level2submitted && level3submitted && (
                    <div className="notification_round orenge"></div>
                  )}
                  <Verify {...props} />
                </Tab>
                <Tab eventKey="TransactionHistory" title="Transaction History">
                  <TransactionHistory {...props} />
                </Tab>
                <Tab eventKey="AccountActivity" title="Account Activity">
                  <AccountActvity {...props} />
                </Tab>
                <Tab eventKey="GamingHistory" title="Gaming History">
                  <GamingHistory {...props} />
                </Tab>
                <Tab eventKey="ResponsibleGaming" title="Responsible Gaming">
                  <AccessControl {...props} />
                </Tab>
              </Tabs>
            </div>
          </>
        )}

        {activeTab === "cashier" && <Cashier {...props} />}

        {activeTab === "transaction-history" && (
          <TransactionHistory {...props} />
        )}

        {activeTab === "free-spins" && <FreeSpins {...props} />}
        {activeTab === "notifications" && <Notifications {...props} />}
        {activeTab === "messages" && <Messages {...props} />}

        {activeTab === "my-games" && <FavoriteGames {...props} />}
        {activeTab === "refer-and-earn" && <ReferAndEarn {...props} />}
        {activeTab === "nft-holders" && <NftHolders {...props} />}

        {activeTab === "withdraw" && <Withdraw {...props} />}
      </div>
    </div>
  );
}
