import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import { __ } from "services/Translator";
import { Modal, Dropdown } from "react-bootstrap";
import InputBox from "elements/InputBox";

import moneyFormatter from "services/moneyFormatter";

function BalanceTransfer(props) {
  // const __ = (text) => text;

  const defaultForm = {
    receiverEmail: "",
    amount: 0,
  };

  // const [form, setForm] = useState(defaultForm);
  const [transferForm, setTransferForm] = useState({
    receiverEmail: "",
    amount: 0,
  });

  const updateForm = (field, value) => {
    setTransferForm({ ...transferForm, [field]: value });
    console.log("transferForm", field);
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    updateForm(field, value);
  };

  const transferBalance = async () => {
    props.setSpinner(true);
    var response = await api.post("/transfer-balance", transferForm);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert(null, response.data.message);
      setTransferForm({
        receiverEmail: "",
        amount: 0,
      });
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  //Copied
  const [selectedDepositWallet, setSelectedDepositWallet] = useState("BTC");
  const fiatMethods = ["USD"];
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  return (
    <>
      <div className="balance_transfer_area">
        <div className="form_area">
          <div className="input_item d-none">
            <div className="name">{__("Cryptocurrency")}</div>

            <Dropdown className="currency_select_menu">
              <Dropdown.Toggle
                variant="success"
                className="right select_crypto"
                id="dropdown-basic1"
              >
                <span className="dipwid vaultCryptoIcon">
                  {moneyFormatter.currencyFullWithIcon(selectedDepositWallet)}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="wallets_menu ">
                {!!userBalance.wallets?.length &&
                  userBalance.wallets
                    .filter((item) => fiatMethods.indexOf(item.currency) === -1)
                    .map((item, index) => {
                      return (
                        <Dropdown.Item as="button" key={index}>
                          <div
                            className="wallets_balance"
                            onClick={() =>
                              setSelectedDepositWallet(item.currency)
                            }
                          >
                            {moneyFormatter.currencyFullWithIcon(item.currency)}
                          </div>
                        </Dropdown.Item>
                      );
                    })}
              </Dropdown.Menu>
            </Dropdown>
            <div className="clearfix"></div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div className="input_item walletAddress">
                <div className="name">
                  {__("Receiver Email")}{" "}
                  <span className="requried_icon">*</span>
                </div>
                <input
                  type="email"
                  name="receiverEmail"
                  value={transferForm.receiverEmail}
                  onChange={updateInput}
                  className="form-control name_value"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="input_item walletAddress">
                <div className="name">
                  {__("Amount")} <span className="requried_icon">*</span>
                </div>
                <div class="input-group name_value" style={{ padding: 0 }}>
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      {props.userBalance.currency}
                    </span>
                  </div>
                  <input
                    type="text"
                    name="amount"
                    value={transferForm.amount}
                    onChange={updateInput}
                    className="form-control name_value"
                  />
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
            <div className="login_button">
              <button
                type="button"
                className="commonBtn filledBtn"
                onClick={transferBalance}
              >
                {__("Transfer")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BalanceTransfer;
