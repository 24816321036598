import React, { useState, useEffect, useContext, useRef } from "react";
import auth from "services/auth";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import emailValidate from "services/emailValidate";
import Translator, { __ } from "services/Translator";
import { Modal, Button } from "react-bootstrap";
import {
  Redirect,
  Route,
  Link,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
// custom component
import LeftSidebar from "elements/LeftSidebar";
import RightSidebar from "elements/RightSidebar";
import CashierModals from "elements/dashboard_elements/CashierModals";
import SupportSidebar from "elements/SupportSidebar";
import LanguageSwitcher from "elements/LanguageSwitcher";
import LoginReg, { loginData } from "elements/LoginReg";
import Logo from "elements/icons/Logo";
import SearchBox from "../elements/SearchBox";

import UserDropDownMenu from "elements/UserDropDownMenu";

import {
  isMobile,
  isMobileByViewPort,
  cauculateLandScape,
} from "services/isMobile";

const Header = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const activeMenu = location.pathname;
  const { updateUserObj } = useContext(AppContext);
  const gamesCategory = params.category;

  const isAuthenticated = props.isAuthenticated;
  const userBalance = props.userBalance;
  const getUserBalance = props.getUserBalance;

  const logout = async (ev) => {
    ev.preventDefault();
    await auth.logout();
    await updateUserObj();
  };

  const [notificationCount, setNotifictionCount] = useState(0);

  // userMenu
  const [rightSidebarOpen, setRightSidebarOpen] = useState(false);
  const toggleRightSidebar = () => {
    setRightSidebarOpen(!rightSidebarOpen);
  };

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const focusSearchBar = () => {
    searchTextBox.current.focus();
  };

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  useEffect(() => {
    // if (isMobile()) {
    //   props.setLeftSidebarSlide(false);
    // }
    // alert(props.leftSidebarSlide);
  }, [props]);

  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };

  return (
    <>
      <header>
        <div className="header_menu_area">
          <div className="left_button_group">
            <input
              type="checkbox"
              role="button"
              aria-label="Display the menu"
              class="menuBar"
              defaultChecked={props.leftSidebarSlide ? true : false}
              checked={props.leftSidebarSlide ? true : false}
              onChange={() => {
                props.setLeftSidebarSlide(!props.leftSidebarSlide);
              }}
            />

            <div
              onClick={() => {
                props.setLeftSidebarSlide(!props.leftSidebarSlide);
              }}
              className="menu_bar_area blackBtnBG d-none"
            >
              <img
                src={require("assets/images/v2/common/menu-bar.svg").default}
                alt=""
              />
            </div>
            {!props.leftSidebarSlide && (
              <>
                <div className="casino_type">
                  <Link
                    to="/games/all"
                    onClick={() => {
                      if (props.leftSidebarSlide && props.isMobileDevice) {
                        props.setLeftSidebarSlide(false);
                      }
                    }}
                    className=" casinoBtn commonBtn"
                  >
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2005_4425)">
                          <path
                            d="M18.7108 1.35376L11.1691 0.0239542C10.3209 -0.125616 9.51195 0.44079 9.36238 1.28903L9.29102 1.69384C10.3817 1.82821 11.3031 2.61391 11.5932 3.69653L12.0541 5.41665C12.2982 5.31083 12.5748 5.2727 12.8564 5.32235C13.3906 5.41657 13.8005 5.80106 13.9532 6.28493C14.2622 5.88247 14.7789 5.66134 15.3131 5.75556C16.0778 5.8904 16.5884 6.61958 16.4535 7.38423C16.1605 9.04571 14.3776 10.3811 13.5315 10.9304L14.8233 15.7516C14.8926 16.01 14.9214 16.2698 14.9151 16.5247L16.002 16.7163C16.8502 16.8659 17.6591 16.2995 17.8087 15.4512L19.9759 3.16056C20.1255 2.31224 19.5591 1.50333 18.7108 1.35376Z"
                            fill="white"
                          />
                          <path
                            d="M10.4636 3.99935C10.2768 3.30255 9.64673 2.84298 8.9581 2.84302C8.8245 2.84302 8.68864 2.86032 8.55345 2.89653L1.15634 4.87856C0.324348 5.10149 -0.169402 5.95669 0.0535276 6.78868L3.28364 18.8437C3.47036 19.5405 4.10048 20 4.78911 20C4.92271 20 5.05857 19.9827 5.19372 19.9465L12.5908 17.9645C13.4228 17.7415 13.9166 16.8863 13.6936 16.0543L10.4636 3.99935ZM4.70743 12.2396C4.582 12.1293 4.53517 11.9544 4.58861 11.7961L5.7197 8.447C5.81431 8.16685 6.16361 8.07325 6.38564 8.26856L9.03978 10.6035C9.16521 10.7138 9.21204 10.8887 9.15861 11.047L8.02751 14.3961C7.9329 14.6763 7.58361 14.7699 7.36157 14.5745L4.70743 12.2396Z"
                            fill="white"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2005_4425">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span className="text">{__("Casino Games")}</span>
                  </Link>
                  <Link
                    onClick={() => {
                      if (props.leftSidebarSlide && props.isMobileDevice) {
                        props.setLeftSidebarSlide(false);
                      }
                    }}
                    to="/games/live-casino"
                    className="commonBtn sportsBtn"
                  >
                    <span className="icon">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <circle
                          opacity="0.45"
                          cx="10"
                          cy="10"
                          r="9.5"
                          stroke="white"
                        />
                        <circle
                          opacity="0.65"
                          cx="10"
                          cy="10"
                          r="6.5"
                          stroke="white"
                        />
                        <circle
                          opacity="0.85"
                          cx="10"
                          cy="10"
                          r="3"
                          fill="white"
                        />
                      </svg>
                    </span>
                    <span className="text">{__("Live Casino")}</span>
                  </Link>
                </div>
              </>
            )}
          </div>
          {!props.isMobileDevice ||
            (!props.isAuthenticated && (
              <div className="logo_area">
                <Link to="/">
                  <img
                    className="with_logo"
                    src={require("assets/images/kjb/logo/logo.png").default}
                    alt=""
                  />
                </Link>
              </div>
            ))}
          {!props.isMobileDevice && (
            <div className="logo_area">
              <Link to="/">
                <img
                  className="with_logo"
                  src={require("assets/images/kjb/logo/logo.png").default}
                  alt=""
                />
              </Link>
            </div>
          )}

          {!props.isMobileDevice && (
            <div className="search_block d-none">
              <SearchBox {...props} />
            </div>
          )}

          {isAuthenticated && !props.isMobileDevice && (
            <div className="user_menu_area">
              <div className="wallet_area">
                <CashierModals {...props} />
              </div>
              <UserDropDownMenu {...props} />
            </div>
          )}

          {isAuthenticated && props.isMobileDevice && (
            <>
              <div className="user_menu_area">
                <div className="wallet_area">
                  <CashierModals {...props} />
                </div>
              </div>
              <UserDropDownMenu {...props} />
            </>
          )}
          {!isAuthenticated && (
            <>
              <div className="login_reg_btn_area">
                <Link
                  hrefLang={Translator.getSelectedLanguage().code}
                  onClick={() => {
                    props.setModalLoginPropsData(true);
                    setTimeout(function () {
                      props.setModalLoginPropsData(false);
                    }, 500);
                  }}
                  className="commonBtn blackBtnBG  signInBtn"
                >
                  {__("Login")}
                </Link>
                <Link
                  hrefLang={Translator.getSelectedLanguage().code}
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(function () {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className=" commonBtn blackBtnBG signUpBtn"
                >
                  {__("Sign Up")}
                </Link>
                {/* <Link
                    className="commonBtn filledBtn"
                    onClick={() => {
                      props.setPlayerLiveChatSlide(!props.playerLiveChatSlide);
                    }}
                  >
                    Open Player Chat
                  </Link> */}
              </div>
            </>
          )}
        </div>
        <SupportSidebar {...props} />
      </header>
    </>
  );
};

export default Header;
