import React, { useState, useEffect, useRef } from "react";
import api from "services/api";
import { Collapse } from "react-bootstrap";
import { useHistory, Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
// import Components from "element/CommonComponents";

const StickyFooterSearchBox = (props) => {
  const history = useHistory();
  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const searchTextBox = useRef(null);

  const [expandSearchBar, setExpandSearchBar] = useState(false);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        providers: props.selectedProviders,
        extensiveInfo: true,
      });
      console.log("getSearchSuggestions", response.data);
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);
  const doSearchByGameName = (text) => {
    setSearchStr("");
    props.setSearchStr(text);
    setSearchSuggestions([]);
    toggleExpandedSearchBar(true);
    history.push("/games/search");
  };
  return (
    <>
      <div
        className={
          "searchbar_searchbox sticky_search_box " +
          (props.showSearchBox ? "showSearchBox " : "hideSearchBox")
        }
      >
        <input
          className="form-control"
          id="searchFocusInput"
          placeholder={__("Search...")}
          autoComplete="off"
          value={searchStr}
          onChange={(ev) => setSearchStr(ev.target.value)}
          type="text"
          ref={searchTextBox}
        />

        {!!searchSuggestions.length && (
          <div
            onClick={() => setSearchSuggestions([])}
            className="backDrop"
          ></div>
        )}

        <Collapse style={{ zIndex: 3050 }} in={!!searchSuggestions.length}>
          <div className="search_container">
            <div className="caretUp d-none">
              <svg
                width="40"
                height="40"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.1018 16.9814C5.02785 16.9814 4.45387 15.7165 5.16108 14.9083L10.6829 8.59762C11.3801 7.80079 12.6197 7.80079 13.3169 8.59762L18.8388 14.9083C19.5459 15.7165 18.972 16.9814 17.898 16.9814H6.1018Z"
                  fill="#212121"
                />
              </svg>
            </div>

            <ul className="search_suggestions">
              {searchSuggestions.map((item, index) => (
                <li
                  onClick={() => doSearchByGameName(item.game_name)}
                  key={index}
                >
                  <img
                    alt={item.game_name}
                    src={item.game_snapshot_url}
                    onError={(event) =>
                      (event.target.parentElement.style.display = "none")
                    }
                  />
                  {item.game_name}
                </li>
              ))}
            </ul>
            <div className="searchBottom">
              <label>{searchStr}</label>
              <div
                onClick={() => {
                  setSearchSuggestions([]);
                }}
                className="modal_close_reg small"
              >
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.68964 12.2073L0.9655 4.78928C0.551706 4.39188 0.275845 3.79579 0.275845 3.1997C0.275845 2.60361 0.551706 2.00752 0.9655 1.61012C1.86205 0.7491 3.37929 0.7491 4.27584 1.61012L12 9.02815L19.7241 1.61012C20.6207 0.7491 22.1379 0.7491 23.0345 1.61012C23.4483 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4483 4.39188 23.0345 4.78928L15.3103 12.2073L23.0345 19.6253C23.4483 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4483 22.4071 23.0345 22.8045C22.6207 23.2019 22 23.4668 21.3793 23.4668C20.7586 23.4668 20.1379 23.2019 19.7241 22.8045L12 15.3865L4.27584 22.8045C3.37929 23.6655 1.86205 23.6655 0.9655 22.8045C0.551706 22.4071 0.275845 21.811 0.275845 21.2149C0.275845 20.6188 0.551706 20.0227 0.9655 19.6253L8.68964 12.2073Z"
                    fill="#10294F"
                  />
                </svg>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
};
export default StickyFooterSearchBox;
