import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "services/auth";
import GameItem from "elements/games_elements/GameItem";
import ls from "local-storage";
import AppContext from "contexts/AppContext";
import api from "services/api";
import { Button } from "react-bootstrap";
import Flickity from "react-flickity-component";
import Slider from "react-slick";
import { __ } from "services/Translator";
import SearchBox from "elements/SearchBox";

const GameSlider = (props) => {
  // const menuOpen = props.menuOpen;

  const flkty = useRef(null);

  const nextLoad = () => {
    // flkty.current.next();
    sliderRef.current.slickNext();
  };

  const prevLoad = () => {
    // flkty.current.previous();
    sliderRef.current.slickPrev();
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const categoryName = props.categoryName;
  const [gameListsData, setGameListsData] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(18);
  const [gameType, setGameType] = useState("");
  const [gameSlug, setGameSlug] = useState("");

  const getGames = async () => {
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: [gameType],
      // specific_games: props.specificGames,
    });
    setGameListsData([]);
    if (response.status === 200 && response.data) {
      // setPaginationData(response.data.paginationData);
      // setPageActivate(page);

      setGameListsData(response.data.games[gameType]?.gamelist ?? []);
    }
  };

  const getResults = async () => {
    props.setSpinner(true);

    var response = await api.post("/games/recently-played", {
      page: 1,
      type: "all",
      perPage: 10,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      // setResults(response.data.data);
      // setPagination(response.data.paginationData);

      setGameListsData(response.data.data ?? []);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(async () => {
    if (categoryName == "New Games" || categoryName == "New Releases") {
      setGameType("new");
      setGameSlug("new");
    } else if (categoryName == "Table Games") {
      setGameType("table_games");
      setGameSlug("table-games");
    } else if (categoryName == "Slots") {
      setGameType("video_slots");
      setGameSlug("slots");
    } else if (categoryName == "Featured Slots") {
      setGameType("featured");
      setGameSlug("featured");
    } else if (categoryName == "Jackpot Games") {
      setGameType("jackpot");
      setGameSlug("jackpots");
    } else if (categoryName == "Live Casino") {
      setGameType("live_casino");
      setGameSlug("live-games");
    } else if (categoryName == "Game Shows") {
      setGameType("game_shows");
      setGameSlug("game-shows");
    } else if (categoryName == "Mega Ways") {
      setGameType("mega_ways");
      setGameSlug("mega_ways");
    } else if (categoryName == "Bonus Buy") {
      setGameType("bonus_buy");
      setGameSlug("bonus_buy");
    } else if (categoryName == "Popular") {
      setGameType("popular");
      setGameSlug("popular");
    } else if (categoryName == "recently-played") {
      setGameType("recently-played");
      setGameSlug("recently-played");
    }
    setPage(1);
  }, [categoryName]);

  useEffect(async () => {
    if (gameType != "recently-played") {
      getGames();
    } else {
      if (props.isAuthenticated) {
        getResults();
      }
    }
  }, [gameType]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    autoplay: false,
    autoplaySpeed: 10000,
    arrows: false,

    // slidesToShow: 1,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,

        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          className: "center",
          centerMode: true,
          // centerPadding: "20px",
          // width: "80px",
        },
      },
    ],
  };

  var settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const sliderRef = useRef();

  return (
    <>
      <div className="category_games slide_games">
        <div className="category_games_header">
          <h4 className="game_headline">
            {categoryName === "New Releases" && (
              <svg
                id="Layer_1"
                enable-background="new 0 0 512 512"
                height="22"
                viewBox="0 0 512 512"
                width="22"
                xmlns="http://www.w3.org/2000/svg"
                fill="#d5dceb"
              >
                <g>
                  <path d="m50.3 120.2 27.8 135.8h300.1l27.9-135.8c12.9-2.3 21.5-14.6 19.2-27.6-2.3-12.9-14.6-21.5-27.6-19.2-12.9 2.3-21.5 14.6-19.2 27.6 1.6 9.1 8.3 16.4 17.2 18.8-5.9 26.4-32 43-58.4 37.1-21.3-4.7-36.9-23-38.2-44.8 4.8-9.2 7.8-19.2 8.7-29.5 12.9-2.6 21.2-15.1 18.7-27.9s-15.1-21.2-27.9-18.7-21.2 15-18.7 27.9c1.8 9.2 8.8 16.4 17.9 18.5-4 38.5-38.5 66.4-77 62.4-32.9-3.5-58.9-29.5-62.4-62.4 12.8-3 20.7-15.7 17.7-28.5s-15.7-20.7-28.5-17.7c-12.6 2.9-20.6 15.7-17.6 28.5 2.1 9.1 9.4 16.1 18.5 17.9 1 10.3 3.9 20.3 8.7 29.4-1.6 27-24.8 47.6-51.7 46-21.8-1.3-40.1-16.9-44.9-38.2 12.7-3.4 20.2-16.4 16.8-29.1s-16.4-20.3-29.1-16.9-20.2 16.4-16.8 29.1c2.3 9 9.7 15.7 18.8 17.3z" />
                  <path d="m107.3 303.5h241.8c11.3 0 21-8 23.3-19l3.8-18.5h-296l3.8 18.5c2.3 11.1 12 19 23.3 19z" />
                  <path d="m402.6 331-1.8-3.5-1.8 3.5c-5.1 10.2-13.3 18.4-23.5 23.5l-3.6 1.8 3.6 1.8c10.2 5.1 18.4 13.4 23.5 23.5l1.8 3.5 1.8-3.5c5.1-10.2 13.3-18.4 23.5-23.5l3.6-1.8-3.6-1.8c-10.2-5.1-18.4-13.3-23.5-23.5z" />
                  <path d="m411.5 386.1-6.2 12.5c-1.2 2.5-4.2 3.5-6.7 2.2-1-.5-1.8-1.3-2.2-2.2l-6.2-12.5c-4.1-8.2-10.8-14.9-19-19l-12.5-6.2c-2.5-1.2-3.5-4.2-2.2-6.7.5-1 1.3-1.8 2.2-2.2l12.5-6.2c1.5-.8 3-1.6 4.5-2.6h-340.7v133.5h386.4v-103.4c-4.1 3.6-7.4 7.9-9.9 12.8zm-217.9-3.8-16.9 51.9c-.8 2.3-4.3 3.4-7.3 3.4-3.2 0-6.6-1.1-7.1-3.3l-5.9-26.8-5.9 26.9c-.5 2.1-4 3.2-7.1 3.2-3 0-6.6-1.1-7.3-3.4l-16.9-52c-.1-.2-.1-.4-.1-.6 0-1.7 3.8-3.8 7.1-3.8 1.6 0 2.6.5 2.8 1.4l14.6 49.1 8-34.3c.3-1.3 2.4-2.3 4.9-2.3s4.6 1 4.9 2.3l8 34.3 14.6-49.1c.3-.9 1.2-1.4 2.8-1.4 3.3 0 7.1 2 7.1 3.8-.2.2-.2.4-.3.7zm41 52.2c0 1.7-2.6 2.6-5.1 2.6s-5.1-.9-5.1-2.6v-54.2c0-1.7 2.7-2.5 5.1-2.5 2.5 0 5.1.8 5.1 2.5zm75.5 0c0 1.7-2.6 2.6-5.1 2.6-3.3 0-5-.8-6.1-2.8l-19-34.6v34.8c0 1.7-2.6 2.6-5.1 2.6s-5.1-.9-5.1-2.6v-54.1c0-1.9 3.1-2.6 5.1-2.6 4.1 0 5.4 0 8.5 5.8l16.6 31.7v-35c0-1.7 2.7-2.5 5.1-2.5s5.1.8 5.1 2.5z" />
                  <path d="m480.8 289c-.5-1-1.3-1.8-2.2-2.2l-7.8-3.9c-4.8-2.4-8.7-6.3-11.1-11.1l-3.9-7.8c-1.2-2.5-4.2-3.5-6.7-2.2-1 .5-1.8 1.3-2.2 2.2l-3.9 7.8c-2.4 4.8-6.3 8.7-11.1 11.1l-7.8 3.9c-2.5 1.2-3.5 4.2-2.2 6.7.5 1 1.3 1.8 2.2 2.2l7.8 3.9c4.8 2.4 8.7 6.3 11.1 11.1l3.9 7.8c1.2 2.5 4.2 3.5 6.7 2.2 1-.5 1.8-1.3 2.2-2.2l3.9-7.8c2.4-4.8 6.3-8.7 11.1-11.1l7.8-3.9c2.5-1.2 3.5-4.2 2.2-6.7z" />
                </g>
              </svg>
            )}
            {categoryName === "Featured Slots" && (
              <svg
                id="Layer_1"
                enable-background="new 0 0 464 464"
                height="22"
                viewBox="0 0 512 512"
                width="22"
                xmlns="http://www.w3.org/2000/svg"
                fill="#d5dceb"
              >
                <g>
                  <path d="m464 238.755v71.74c0 8.84-7.16 16-16 16h-22.19v-103.74h6.29c0-126.784-.061-118.584.11-119.66-9.31-3.25-15.98-12.11-15.98-22.53 0-13.19 10.68-23.87 23.87-23.87 13.18 0 23.87 10.68 23.87 23.87 0 10.42-6.68 19.28-15.98 22.53.171 1.076.11-7.106.11 119.66 8.79.05 15.9 7.19 15.9 16z" />
                  <g>
                    <path d="m0 373.917v17.387c0 8.837 7.164 16 16 16h377.806c8.837 0 16-7.163 16-16v-17.387z" />
                    <path d="m141.807 191.337v166.58h126.193v-166.58zm59.716 134.592c-1.1 4.259-5.446 6.852-9.747 5.745-4.277-1.105-6.85-5.469-5.745-9.747 6.912-26.76 17.707-51.941 32.179-75.11-15.259-1.04-20.557-11.293-42.467-3.001-4.129 1.562-8.749-.519-10.313-4.651-1.563-4.132.519-8.75 4.651-10.313 19.703-7.453 30.946-3.543 39.979-.401 7.728 2.688 12.391 4.309 22.813-1.75 3.286-1.907 7.458-1.244 9.986 1.587 2.53 2.833 2.721 7.054.455 10.102-19.507 26.251-33.569 55.704-41.791 87.539z" />
                    <path d="m0 191.337v166.58h125.807v-166.58zm63.023 134.592c-1.1 4.259-5.446 6.852-9.747 5.745-4.277-1.105-6.85-5.469-5.745-9.747 6.912-26.76 17.707-51.941 32.179-75.11-15.259-1.04-20.557-11.293-42.467-3.001-4.129 1.562-8.75-.519-10.313-4.651s.519-8.75 4.651-10.313c19.703-7.453 30.946-3.543 39.979-.401 7.728 2.688 12.391 4.309 22.813-1.75 3.286-1.907 7.458-1.244 9.986 1.587 2.53 2.833 2.721 7.054.455 10.102-19.507 26.251-33.569 55.704-41.791 87.539z" />
                    <path d="m409.806 175.337v-17.387c0-8.837-7.163-16-16-16h-377.806c-8.836 0-16 7.163-16 16v17.387z" />
                    <path d="m409.806 191.337h-125.806v166.58h125.806zm-71.783 134.592c-1.1 4.259-5.446 6.852-9.747 5.745-4.277-1.105-6.85-5.469-5.745-9.747 6.912-26.76 17.707-51.941 32.179-75.11-15.259-1.04-20.557-11.293-42.467-3.001-4.13 1.562-8.75-.519-10.313-4.651s.519-8.75 4.651-10.313c19.702-7.453 30.946-3.543 39.979-.401 7.728 2.688 12.391 4.309 22.813-1.75 3.286-1.907 7.458-1.244 9.986 1.587 2.53 2.833 2.721 7.054.455 10.102-19.507 26.251-33.569 55.704-41.791 87.539z" />
                  </g>
                </g>
              </svg>
            )}{" "}
            {/* {categoryName === "Live Casino" && (
              <svg
                id="Capa_1"
                enable-background="new 0 0 512 512"
                height="22"
                viewBox="0 0 512 512"
                width="22"
                xmlns="http://www.w3.org/2000/svg"
                fill="#d5dceb"
                style={{ marginRight: "6px" }}
              >
                <g>
                  <path d="m238.518 87-46.535-48.72c-5.534-5.796-12.995-9.089-21.007-9.272-8.029-.19-15.615 2.764-21.409 8.299l-140.287 133.997c-5.795 5.535-9.088 12.995-9.271 21.007s2.764 15.615 8.299 21.408l90.692 94.951v-161.67c0-33.084 26.916-60 60-60z" />
                  <path d="m489.371 90.458-188.047-47.685c-7.768-1.973-15.838-.798-22.724 3.302s-11.762 10.636-13.731 18.402l-5.711 22.523h93.842c33.084 0 60 26.916 60 60v298.506c3.176-.676 6.255-1.87 9.127-3.58 6.885-4.1 11.762-10.635 13.732-18.402l75.217-296.612c4.066-16.035-5.671-32.388-21.705-36.454z" />
                  <path d="m353 117h-194c-16.542 0-30 13.458-30 30v306c0 16.542 13.458 30 30 30h194c16.542 0 30-13.458 30-30v-306c0-16.542-13.458-30-30-30zm-153 56.001c8.284 0 15 6.716 15 15s-6.716 15-15 15-15-6.716-15-15 6.716-15 15-15zm56 197.999c-3.577 0-7.153-1.273-10.001-3.82l-44.729-40.012c-.101-.09-.199-.181-.298-.273-11.223-10.598-16.621-26.625-14.442-42.872 2.072-15.453 10.873-28.715 23.544-35.477 6.155-3.277 12.656-4.936 19.335-4.936 10.132 0 19.393 3.86 26.591 10.327 7.198-6.467 16.459-10.327 26.591-10.327 6.679 0 13.18 1.658 19.323 4.93 12.683 6.768 21.483 20.029 23.556 35.482 2.179 16.247-3.22 32.274-14.442 42.872-.099.093-.197.184-.298.273l-44.729 40.012c-2.847 2.548-6.424 3.821-10.001 3.821zm56 55.998c-8.284 0-15-6.715-15-15 0-8.284 6.716-15 15-15s15 6.716 15 15-6.716 15-15 15z" />
                </g>
              </svg>
            )} */}
            {/* {__(categoryName)} */}
            {props.showLebel ? props.showLebel : categoryName}
          </h4>
          {!props.isMobileDevice && props.search && (
            <div className="search_block ">
              <SearchBox {...props} />
            </div>
          )}
          <div className="slider_controlls">
            <Link onClick={prevLoad} className="commonBtn blackBtnBG">
              {"<"}
            </Link>
            <Link onClick={nextLoad} className="commonBtn blackBtnBG">
              {">"}
            </Link>
            <Link class="commonBtn blackBtnBG" to={"/games/" + gameSlug}>
              {__("View All")}
            </Link>
          </div>
        </div>
        <div className="slide_game_list_area">
          <>
            <Slider ref={sliderRef} {...settings}>
              {gameListsData?.map((game, index) => (
                <GameItem
                  game={game}
                  isAuthenticated={props.isAuthenticated}
                  key={index}
                  showAlert={props.showAlert}
                />
              ))}
            </Slider>
            <div class="shadowOverlay"></div>
          </>
        </div>
      </div>
    </>
  );
};

export default GameSlider;
