import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "../services/auth";
import Spinner from "../elements/Spinner";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
import "styles/_security_popup.scss";
import Translator, { __ } from "services/Translator";

import validator from "validator";
import swal from "sweetalert";

import { Modal, Button, Alert } from "react-bootstrap";

const SecurityPopup = (props) => {
  const history = useHistory();
  const spinner = props.spinner;
  const setSpinner = props.setSpinner;
  const isAuthenticated = props.isAuthenticated;

  const [security, setSecurity] = useState(false);
  const [securityPopupModal, setSecurityPopupModal] = useState(false);
  const [securityPopupData, setSecurityPopupData] = useState({
    login: "",
    password: "",
  });

  const [securityPopupDataErrors, setSecurityPopupDataErrors] = useState({
    login: "",
    password: "",
  });

  const updateSecurityPopupInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    // updateRegistrationForm(field, value);
    setSecurityPopupData({ ...securityPopupData, [field]: value });
  };

  const securityPopupLogin = async (ev) => {
    ev.preventDefault();
    var valid = validateSecurityPopupForm();
    if (valid) {
      setSpinner(true);
      var response = await api.post("/pre-login", securityPopupData);
      setSpinner(false);
      if (response.status == 200) {
        //Here, no actual user token is provided. So, user is still not logged in
        //However, this temporary token will get past the security popup
        await auth.login(response.data.user);
        setSecurity(false);
      }
    }
  };

  const validateSecurityPopupForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    if (
      securityPopupData.login.length < 2 ||
      securityPopupData.login.length > 250
    ) {
      errorsTmp = { ...errorsTmp, login: __("Please enter the user name") };
      valid = false;
    }

    if (
      securityPopupData.password.length < 2 ||
      securityPopupData.password.length > 250
    ) {
      errorsTmp = { ...errorsTmp, password: __("Please enter the password") };
      valid = false;
    }

    setSecurityPopupDataErrors(errorsTmp);

    return valid;
  };

  const ping = async () => {
    var response = await api.get("/ping");
    if (response.status === 200) {
      setSecurity(false);
    } else if (response.status === 403) {
      if (response.data && response.data.showSecurityPopup) {
        var userId = auth.getUserId();
        if (!userId) {
          setSecurity(true);
        }
      }
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      ping();
    }
    const interval = setInterval(() => {
      if (!isAuthenticated) {
        ping();
      }
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  const openModal = () => {
    setSecurityPopupModal(true);
  };
  const closeModal = () => {
    setSecurityPopupModal(false);
  };

  return (
    <>
      {security && (
        <>
          <div className="security_overlay">
            <div className="security_popup_logo">
              <img
                src={require("assets/images/kjb/logo/logo.png").default}
                alt={__("Coming Soon")}
              />
            </div>

            <Link
              hrefLang={Translator.getSelectedLanguage().code}
              to="#"
              onClick={openModal}
              className="security_popup_trigger commonBtn only_border"
            >
              {__("Early Access")}
            </Link>
          </div>
          <Modal show={securityPopupModal}>
            <Modal.Body className="login_modal security_popup">
              <div onClick={closeModal} className="modal_close_reg small">
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                    fill="#10294F"
                  />
                </svg>
              </div>
              <div className="modal_headline">
                {__("Maintenance. Back online in 30 minutes…")}
              </div>
              <form onSubmit={securityPopupLogin}>
                <div className="form_area">
                  <div className="input_item">
                    <div className="name">
                      {__("User Name")} <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="text"
                      name="login"
                      onChange={updateSecurityPopupInput}
                      value={securityPopupData.login}
                      className={
                        "form-control name_value " +
                        (securityPopupDataErrors.login ? "error" : "")
                      }
                    />
                    <div className="errorMsg">
                      {securityPopupDataErrors.login}
                    </div>
                  </div>

                  <div className="input_item">
                    <div className="name">
                      {__("Password")}
                      <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="password"
                      name="password"
                      onChange={updateSecurityPopupInput}
                      value={securityPopupData.password}
                      className={
                        "form-control name_value " +
                        (securityPopupDataErrors.password ? "error" : "")
                      }
                    />
                    <div className="errorMsg">
                      {securityPopupDataErrors.password}
                    </div>
                  </div>
                  <div className="login_button">
                    <Button type="submit" className="commonBtn">
                      {__("Login")}
                    </Button>
                  </div>
                </div>
              </form>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default SecurityPopup;
