import React, { useState, useEffect, useRef } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import SelectBox from "elements/SelectBox";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import moneyFormatter from "services/moneyFormatter";
import ls from "services/ls";
import UserLeftArea from "elements/dashboard_elements/UserLeftArea";
import { isMobile } from "services/isMobile";
import Translator, { __ } from "services/Translator";
import { Modal } from "react-bootstrap";

import PageHeadline from "../PageHeadline";

import FiatCashier from "elements/dashboard_elements/FiatCashier";

export default function Cashier(props) {
  // const __ = (text) => text;
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [selectedWallet, setSelectedWalelt] = useState(null);
  const [selectItems, setSelectItems] = useState([]);

  const [deleteBonusModalOpen, setDeleteBonusModalOpen] = useState(false);

  const doDeleteBonuses = async () => {
    props.setSpinner(true);
    var response = await api.post("/delete-bonus");
    if (response.status === 200) {
      props.setSpinner(false);
      toggleDeleteBonusModalOpen();
      props.getUserBalance();
      props.showAlert("Warning!!", response.data.message);
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  const toggleDeleteBonusModalOpen = () => {
    setDeleteBonusModalOpen(!deleteBonusModalOpen);
  };

  useEffect(() => {
    if (props.userBalance.wallets.length) {
      setSelectedWalelt(props.userBalance.wallets[0].currency);
      setSelectItems(
        props.userBalance.wallets.map((item) => {
          return { label: item.currency, value: item.value };
        })
      );
    }
  }, [props.userBalance]);

  const today =
    new Date().getFullYear() +
    "-" +
    new Date().getMonth() +
    "-" +
    new Date().getDate();

  return (
    <>
      <PageHeadline page_name="Cashier" />
      <div className="page_content_area">
        <div className="progress_chart_area">
          <div className="progress_content">
            <div className="content_title">
              {__("Balance")}
              <img
                src={require("assets/images/icons/mastercard.png").default}
                alt={__("Mastercard Icon")}
              />
            </div>
            <div className="content_details">
              <div className="text_section">
                <h3 className="balance">
                  {"$" + props.userBalance.total_balance}
                </h3>
                <div className="date_details">
                  <div className="paragraph no_margin white">
                    {__("Current Date")}
                  </div>
                  <div className="date_value">{today}</div>
                </div>
              </div>
              <div className="progress_section">
                <CircularProgressbarWithChildren
                  className="progress_container"
                  value={props.userBalance.total_balance}
                  counterClockwise={true}
                >
                  <div className="progress_value">
                    {"$" + props.userBalance.total_balance}
                  </div>
                </CircularProgressbarWithChildren>
              </div>
            </div>
          </div>
          <div className="progress_content chart_area">
            <div className="content_title">{__("Cash Balance")}</div>
            <div className="content_details">
              <div className="text_section">
                <h3 className="balance">
                  {"$" + props.userBalance.cash_balance}
                </h3>
              </div>
            </div>
          </div>
        </div>

        <div className="bonus_intro">
          <div className="intro_title">
            {__("Bonus Balance")}: {"$" + props.userBalance.bonus_balance}
          </div>
          <div className="intro_title">
            {__("Bonus Unlock Progress")}: (
            {"$" + props.userBalance.wagering_requirements_achieved}/
            {"$" + props.userBalance.wagering_requirements_total})
          </div>
          <Link
            hrefLang={Translator.getSelectedLanguage().code}
            to="#"
            onClick={toggleDeleteBonusModalOpen}
            className="commonBtn red "
          >
            {__("Delete Bonus")}
          </Link>
        </div>

        <Modal show={deleteBonusModalOpen} onHide={toggleDeleteBonusModalOpen}>
          <Modal.Body className={"login_modal " + props.theme}>
            <div
              onClick={toggleDeleteBonusModalOpen}
              className="modal_close_reg small"
            >
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                  fill="#10294F"
                />
              </svg>
            </div>

            <div className="modal_headline">
              {__("Are you sure to delete your bonus balance?")}
            </div>

            <div className="row">
              <div className="col-6 col-md-4 offset-md-2 col-lg-3 offset-lg-3">
                <button
                  type="button"
                  className="commonBtn"
                  onClick={toggleDeleteBonusModalOpen}
                >
                  {__("No")}
                </button>
              </div>
              <div className="col-6 col-md-4 col-lg-3">
                <button
                  type="button"
                  className="commonBtn red"
                  onClick={doDeleteBonuses}
                >
                  {__("Yes")}
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <br />
        <FiatCashier {...props} />
      </div>
    </>
  );
}
