import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation, useHistory, Link } from "react-router-dom";
import CategoryGames from "elements/games_elements/CategoryGames";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "local-storage";
import { Badge, Table, Modal } from "react-bootstrap";
import { Accordion, OverlayTrigger, Tooltip } from "react-bootstrap";
import VipLevelText from "elements/VipLevelText";
import SingleCategoryGames from "elements/games_elements/SingleCategoryGames";
import Translator, { __ } from "services/Translator";
import SearchBox from "../elements/SearchBox";
import jQuery from "jquery";

export default function GamePlay(props) {
  const [gameList, setGameList] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);

  const getGameList = async () => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 5,
      game_types: ["popular"],
      page: 1,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setGameList(response.data.games?.popular?.gamelist ?? []);
    }
  };

  const searchTextBox = useRef(null);

  const focusSearchBar = () => {
    searchTextBox.current.focus();
  };

  // const __ = (text) => text;

  useEffect(() => {
    getGameList();
  }, [gameSorting, props.selectedProviders]);

  const params = useParams();
  const history = useHistory();
  const isAuthenticated = props.isAuthenticated;
  const [launchUrl, setLaunchUrl] = useState("");
  const [gameDetails, setGameDetails] = useState("");
  const [gamePlayMode, setGamePlayMode] = useState(
    params.mode ? params.mode : "real"
  );
  const [gameSessionId, setGameSessionId] = useState(null);

  const toggleFullScreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = document.getElementById("game_play_window");
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  const iframeSrcChanged = (event) => {
    // alert(event.target.contentWindow.location);
  };

  const getPlay = async () => {
    var response = await api.post("/game/initsession", {
      slug: params.slug,
      mode: gamePlayMode,
    });

    if (response.status === 200 && response.data) {
      if (response.data.launchUrl) {
        setLaunchUrl(response.data.launchUrl);
      } else if (response.data.launchOptions) {
        setLaunchUrl("");
        setTimeout(() => {
          gameLaunch(response.data.launchOptions);
        }, 1000);
      }
      setGameSessionId(response.data.sessionId);
      setGameDetails(response.data.details);
      setIsFavorite(response.data.is_favorite_game);
    } else {
      setGameDetails(response?.data?.details);
      props.showAlert("Warning!!", response.data.message, true, () => {
        // history.push("/play/" + params.slug + "/demo");
      });
    }
  };

  const gameLaunch = (launchOptions) => {
    jQuery("div#game_play_window").html("");
    var gameLaunchOptions = { target_element: "game_play_window" };
    // launch_options that you've received in start demo or create session response.
    gameLaunchOptions["launch_options"] = launchOptions;
    var success = function (gameDispatcher) {
      console.log("gameDispatcher", gameDispatcher);
    };
    var error = function (error) {
      console.log("errorerror", error);
    };

    window.sg.launch(gameLaunchOptions, success, error);
  };

  useEffect(() => {
    getPlay();
  }, [gamePlayMode, params.slug]);

  const [isFavorite, setIsFavorite] = useState(gameDetails?.is_favorite_game);
  const toggleFavoriteGame = async () => {
    var response = await api.post("/toggle-favorite-game", {
      gameId: gameDetails.id,
    });
    if (response.status === 200) {
      if (response.data.status === "added") {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }
    }
  };

  const [bonusUnsupportedWarningModal, setBonusUnsupportedWarningModal] =
    useState({ show: false });

  useEffect(() => {
    if (
      gameDetails &&
      props.userBalance &&
      gamePlayMode !== "demo" &&
      !gameDetails.bonus_supported &&
      parseFloat(props.userBalance.cash_balance) === 0.0
    ) {
      setBonusUnsupportedWarningModal({ show: true });
    } else {
      setBonusUnsupportedWarningModal({ show: false });
    }
  }, [gamePlayMode, props.userBalance.cash_balance, gameDetails]);

  const closeAllModals = () => {
    setGameHubMessageModal({ show: false });
    setBonusUnsupportedWarningModal({ show: false });
  };

  //GameHub message functionality
  const [gameHubMessageModal, setGameHubMessageModal] = useState({
    title: "",
    message: "",
    show: false,
  });

  const loadGameHubMessage = async () => {
    if (!gameDetails) return;
    var response = await api.post("/load-game-hub_message", {
      provider: gameDetails.game_aggregator,
      game_session_id: gameSessionId,
    });
    if (response.status === 200) {
      if (response.data.type == "found") {
        //There is a message to show.
        var modalData = {
          ...modalData,
          show: true,
          title: __("Warning!!"),
          message: response.data.gamehub_message.message,
          message_type: response.data.gamehub_message.message_type,
        };

        setGameHubMessageModal(modalData);
      } else if (response.data.type == "session_created") {
        //Session type changed. Message to show and game area to reload
        setGameHubMessageModal({
          ...gameHubMessageModal,
          message_type: response.data.gamehub_message.message_type,
          title: __("Balance type changed!"),
          message: response.data.gamehub_message.message,
          show: true,
        });
        setGameSessionId(response.data.newSession.sessionId);
        setLaunchUrl(response.data.newSession.launchUrl);
      }
    }
  };

  useEffect(() => {
    var interval = setInterval(function () {
      if (gamePlayMode !== "demo") {
        loadGameHubMessage();
      }
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [gameSessionId, gameDetails, isAuthenticated, gamePlayMode]);

  //GamePlay Page detection
  const isMobileDevice = props.isMobileDevice;
  const isMobileDeviceByViewPort = props.isMobileDeviceByViewPort;
  const isLandscape = props.isLandscape;
  const [mobileIframeStyle, setMobileIframeStyle] = useState({});
  const calclateMobileIframeStyle = () => {
    var style = {
      width: "100vw",
      height: "calc(100vh - 52px)",
    };
    if (isLandscape) {
      style.height = "100vh";
    }
    setMobileIframeStyle(style);
  };

  useEffect(() => {
    window.addEventListener("resize", () => {
      calclateMobileIframeStyle();
    });
    calclateMobileIframeStyle();

    window.gotoGamesPage = () => {
      history.push("/");
    };
  }, []);

  const [topWinners, setTopWinners] = useState([]);

  const getTopWinners = async () => {
    var response = await api.get("/games/top-winners?limit=5");
    if (response.status === 200) {
      setTopWinners(response.data.data);
    } else {
      console.log(response.data);
    }
  };
  const [detailsTab, setDetailsTab] = useState("Description");
  const [theaterMode, setTheaterMode] = useState(false);
  useEffect(() => {
    getTopWinners();
    // if(isAuthenticated){
    //   setGamePlayMode('real');
    // } else {
    //   setGamePlayMode('demo');
    // }
  }, []);

  const [maximumWagersPerRoundSettings, setMaximumWagersPerRoundSettings] =
    useState([]);
  const getMaximumWagersPerRoundSettings = async () => {
    var response = await api.post("/maximum-wagers-per-round-settings", {
      type: "Cash",
    });
    if (response.status === 200) {
      setMaximumWagersPerRoundSettings(response.data);
      // console.log('maximumWagersPerRoundSettings', response.data);
    }
  };

  const [
    maximumBonusWagersPerRoundSettings,
    setMaximumBonusWagersPerRoundSettings,
  ] = useState([]);
  const getMaximumBonusWagersPerRoundSettings = async () => {
    var response = await api.post("/maximum-wagers-per-round-settings", {
      type: "Bonus",
    });
    if (response.status === 200) {
      setMaximumBonusWagersPerRoundSettings(response.data);
      // console.log('maximumWagersPerRoundSettings', response.data);
    }
  };

  useEffect(() => {
    getMaximumWagersPerRoundSettings();
    getMaximumBonusWagersPerRoundSettings();
  }, []);

  return (
    <>
      {!isMobileDevice && (
        <>
          <div className="container">
            <div
              className={
                "game_play " + (theaterMode ? "theaterModeEnabled" : "")
              }
            >
              <div className="row">
                <div className="col-12 col-xl-12">
                  <div className={"game_content "}>
                    <div className="game_head">
                      <div className="game_title">
                        {gameDetails?.game_name
                          ? gameDetails?.game_name
                          : "Game Name"}
                      </div>
                      <div className="game_title">12:30</div>
                    </div>
                    {launchUrl !== "" ? (
                      <iframe
                        style={{ display: "block !important;" }}
                        id="game_play_window"
                        width="100%"
                        height="100%"
                        src={launchUrl}
                      ></iframe>
                    ) : (
                      <div
                        style={{
                          display: "block !important",
                          width: "100%",
                          height: "650px",
                        }}
                        id="game_play_window"
                      ></div>
                    )}
                    <div className="clear clearfix"></div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="frame_bottom">
                    <div className="actions_btn">
                      {/* <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customSwitches"
                          defaultChecked={gamePlayMode == "real" ? true : false}
                          onChange={(ev) => {
                            var target = ev.target;
                            if (target.checked) {
                              setGamePlayMode("real");
                            } else {
                              setGamePlayMode("demo");
                            }
                          }}
                        />
                        <label
                          class="custom-control-label"
                          for="customSwitches"
                        >
                          {__("Real Play")}
                        </label>
                      </div> */}
                      <Link className="home_link" to="/games/all">
                        <svg
                          fill="#000000"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 495.398 495.398"
                          xml="preserve"
                        >
                          <g>
                            <g>
                              <g>
                                <path
                                  d="M487.083,225.514l-75.08-75.08V63.704c0-15.682-12.708-28.391-28.413-28.391c-15.669,0-28.377,12.709-28.377,28.391
				v29.941L299.31,37.74c-27.639-27.624-75.694-27.575-103.27,0.05L8.312,225.514c-11.082,11.104-11.082,29.071,0,40.158
				c11.087,11.101,29.089,11.101,40.172,0l187.71-187.729c6.115-6.083,16.893-6.083,22.976-0.018l187.742,187.747
				c5.567,5.551,12.825,8.312,20.081,8.312c7.271,0,14.541-2.764,20.091-8.312C498.17,254.586,498.17,236.619,487.083,225.514z"
                                />
                                <path
                                  d="M257.561,131.836c-5.454-5.451-14.285-5.451-19.723,0L72.712,296.913c-2.607,2.606-4.085,6.164-4.085,9.877v120.401
				c0,28.253,22.908,51.16,51.16,51.16h81.754v-126.61h92.299v126.61h81.755c28.251,0,51.159-22.907,51.159-51.159V306.79
				c0-3.713-1.465-7.271-4.085-9.877L257.561,131.836z"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Link>
                    </div>

                    <div className="text_area">
                      {isAuthenticated && (
                        <i
                          onClick={toggleFavoriteGame}
                          className={(isFavorite ? "fas" : "far") + " fa-heart"}
                        ></i>
                      )}{" "}
                      {gameDetails?.game_name
                        ? gameDetails?.game_name
                        : "Game Name"}
                    </div>
                    <div className="actions_icon">
                      {/* <SearchBox /> */}

                      <Link hrefLang={Translator.getSelectedLanguage().code}>
                        <OverlayTrigger
                          className="tooltipStyle"
                          overlay={<Tooltip>{__("Theater Mode")}</Tooltip>}
                        >
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2650_28240)">
                              <path
                                d="M3.7998 3.73633V13.7363H16.2998V3.73633H3.7998ZM4.9998 5.03638H14.9998V12.5364H4.9998V5.03638Z"
                                fill="#8C8C8C"
                              />
                              <path
                                d="M0 12.5371V17.5371H5V16.3371H1.2V12.5371H0Z"
                                fill="#8C8C8C"
                              />
                              <path
                                d="M18.8 12.5371V16.3371H15V17.5371H20V12.5371H18.8Z"
                                fill="#8C8C8C"
                              />
                              <path
                                d="M15 0.0371094V1.23706H18.8V5.03711H20V0.0371094H15Z"
                                fill="#8C8C8C"
                              />
                              <path
                                d="M0 0.0371094V5.03711H1.2V1.23706H5V0.0371094H0Z"
                                fill="#8C8C8C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2650_28240">
                                <rect width="20" height="17.5" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </OverlayTrigger>
                      </Link>
                      <OverlayTrigger
                        overlay={<Tooltip>{__("Full-Screen")}</Tooltip>}
                      >
                        <Link
                          onClick={toggleFullScreen}
                          hrefLang={Translator.getSelectedLanguage().code}
                        >
                          <svg
                            onClick={() => {
                              if (theaterMode) {
                                setTheaterMode(false);
                              } else {
                                setTheaterMode(true);
                              }
                            }}
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_2650_28247)">
                              <path
                                d="M2.52 14.22L0 11.7V18H6.3L3.78 15.48L7.65 11.61L6.39 10.35L2.52 14.22ZM15.48 3.78L18 6.3V0H11.7L14.22 2.52L10.35 6.39L11.61 7.65L15.48 3.78ZM14.22 15.48L11.7 18H18V11.7L15.48 14.22L11.61 10.35L10.35 11.61L14.22 15.48ZM3.78 2.52L6.3 0H0V6.3L2.52 3.78L6.39 7.65L7.65 6.39L3.78 2.52Z"
                                fill="#8C8C8C"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_2650_28247">
                                <rect width="18" height="18" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </Link>
                      </OverlayTrigger>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          {/* <CategoryGames
            {...props}
            gameList={gameList}
            categoryName="Popular Games"
            category="popular"
            setGameSorting={setGameSorting}
            gameSorting={gameSorting}
          /> */}
        </>
      )}

      {isMobileDevice && (
        <iframe
          onLoad={iframeSrcChanged}
          src={launchUrl}
          style={mobileIframeStyle}
        />
      )}

      <Modal
        className="gamePlayMsgModal"
        show={gameHubMessageModal.show}
        onHide={closeAllModals}
      >
        {/* <Modal className="gamePlayMsgModal" show={1} onHide={closeAllModals}> */}
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {gameHubMessageModal.message_type == "maximum_cash_wagers_per_round"
              ? // __("Maximum cash wager limit exceeded!")
                __("Uh oh...")
              : gameHubMessageModal.message_type ==
                "maximum_bonus_wagers_per_round"
              ? __("Maximum bonus wagers limit exceeded!")
              : gameHubMessageModal.message_type == "bonus_balance_not_support"
              ? __("Insufficient balance!")
              : gameHubMessageModal.message_type == "maximum_wagers"
              ? __("Maximum cash wager limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_loss"
              ? __("Maximum cash loss limit exceeded!")
              : gameHubMessageModal.message_type == "maximum_playing_time"
              ? __("Maximum play time limit exceeded!")
              : gameHubMessageModal.title}
          </h5>
          {/* <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            <i class="fas fa-exclamation-triangle"></i>
            <br />
            {gameHubMessageModal.message_type ==
            "maximum_cash_wagers_per_round" ? (
              <>
                {/* You have exceeded the maximum bet amount for cash money. Please
                lower the amount and try again. See our{" "}
                <Link hrefLang={Translator.getSelectedLanguage().code} to="">General Terms & Conditions</Link> for more
                information. */}
                {__("Opening month betting limits apply")}
                <br />
                {__("Max Bet")} $
                {gameDetails?.type == "video-slot"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.slots.limit_amount
                    : ""
                  : gameDetails?.type == "Live Casino"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : gameDetails?.type == "livecasino"
                  ? maximumWagersPerRoundSettings != ""
                    ? maximumWagersPerRoundSettings.live_casinos.limit_amount
                    : ""
                  : maximumWagersPerRoundSettings != ""
                  ? maximumWagersPerRoundSettings.others.limit_amount
                  : ""}
                <br />
                <br />
                <span style={{ fontSize: "14px" }}>
                  {__("Unlimited bets from May 19")}
                </span>
              </>
            ) : gameHubMessageModal.message_type ==
              "maximum_bonus_wagers_per_round" ? (
              <>
                {/* You have exceeded the maximum bet amount for bonus money. Please
                lower the amount and try again. See our{" "} */}
                {__("Opening month betting limits apply")}
                <br />
                {__("Max Bet")} $
                {gameDetails?.type == "video-slot"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.slots.limit_amount
                    : ""
                  : gameDetails?.type == "Live Casino"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.live_casinos
                        .limit_amount
                    : ""
                  : gameDetails?.type == "livecasino"
                  ? maximumBonusWagersPerRoundSettings != ""
                    ? maximumBonusWagersPerRoundSettings.live_casinos
                        .limit_amount
                    : ""
                  : maximumBonusWagersPerRoundSettings != ""
                  ? maximumBonusWagersPerRoundSettings.others.limit_amount
                  : ""}
                <Link hrefLang={Translator.getSelectedLanguage().code} to="">
                  {__("Bonus Terms")}
                </Link>{" "}
                {__("for more information")}.
              </>
            ) : gameHubMessageModal.message_type ==
              "bonus_balance_not_support" ? (
              <>
                {__(
                  "Bonus play is not supported for this game. You do not have sufficient cash balance to play this game"
                )}
                . {__("See our")}{" "}
                <Link hrefLang={Translator.getSelectedLanguage().code} to="">
                  {__("Bonus Terms")}
                </Link>{" "}
                {__("for more information")}. {__("Note")}:{" "}
                {__(
                  "For Live Casino games and Sports Betting, Only cash wagers are accepted"
                )}{" "}
                ({__("no bonus wagers")})
              </>
            ) : (
              <>{gameHubMessageModal.message}</>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="common_btn" onClick={closeAllModals}>
            OK
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={bonusUnsupportedWarningModal.show} onHide={closeAllModals}>
        <Modal.Header>
          <h5 className="modal-title" id="exampleModalLabel">
            {__("Game playing with bonus money!")}
          </h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeAllModals}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {__(
              "This game is not available for bonus money. Only cash money is accepted."
            )}
            <br />
            {__(
              "Note: All Live Casino games are not available for bonus money wagering."
            )}
            <br />
            <Link hrefLang={Translator.getSelectedLanguage().code} to="/bonus">
              {__("Click here")}
            </Link>{" "}
            {__("to see which games are available for Bonus Money playing.")}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
