import React, { useState, useEffect, useRef } from "react";
import { Accordion, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import { Helmet } from "react-helmet";
import Translator, { __ } from "services/Translator";
import HomeBanner from "elements/home/HomeBanner";
import AffiliatesTermsConditions from "elements/AffiliatesTermsConditions";
import api from "services/api";

export default function Affiliates(props) {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 3500,
    speed: 1000,
    arrows: true,
    fade: false,
    slidesToShow: 4,
    // slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const [showAffiliateTermsModal, setShowAffiliateTermsModal] = useState(false);

  return (
    <>
      <div className="vip_page_area affiliate_page">
        <div className="home_banners">
          <div className="row align-items-center">
            <div className="col-md-12">
              <div className="home_banner_area affiliateBanner">
                <div className="home_banner_single">
                  <img
                    alt=""
                    src={
                      require("../assets/images/kjb/banners/affiliate_950x500.png")
                        .default
                    }
                  />
                  <div className="banner_content">
                    <h2 className="banner_heading">{__("Affiliate")}</h2>
                    <p className="banner_details">
                      {__(
                        'Help Miss Slotty spreading the word and be rewarded with the commissions that are just "Out Of This World"'
                      )}
                    </p>
                    {/* <p className="banner_details">
                      {__(
                        "Miss Slotty Paradise: Over 6000 Games of Pure Thrill!"
                      )}
                    </p>
                    <Link to="/games/all" className="commonBtn bannerBtn">
                      {__("Play Now")}
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="clearfix"></div>
          </div>
        </div>
        <div className="vip_explore_area">
          <h3 className="vip_common_block_heading">
            {__("Explore our benefits")}
          </h3>
          <ul>
            <li>
              <img
                alt=""
                className="vipGiftIcon"
                src={
                  require("../assets/images/kjb/vip/vip_gift_icon.png").default
                }
              />
              <div className="explore_heading">{__("Generous Shares")}</div>
              <div className="explore_sub_heading">
                {__("Unlock Limitless earnings with up to 50% revenue share")}
              </div>
            </li>
            <li className="active">
              <img
                alt=""
                className="vipDiamondIcon"
                src={
                  require("../assets/images/kjb/vip/vip-diamond-icon.png")
                    .default
                }
              />
              <div className="explore_heading">{__("Monthly Payout")}</div>
              <div className="explore_sub_heading">
                {__(
                  "Get paid on the 1st in every month with no negative carry over."
                )}
              </div>
            </li>
            <li>
              <img
                alt=""
                className="vipLoveIcon"
                src={
                  require("../assets/images/kjb/vip/vip-love-icon.png").default
                }
              />
              <div className="explore_heading">{__("Passiv income")}</div>
              <div className="explore_sub_heading">
                {__(
                  "Earn lifetime commissions on all the players you refer to Miss Slotty"
                )}
              </div>
            </li>
          </ul>
        </div>
        <div className="vip_levels_area affiliate_commission_plan">
          <h3 className="vip_common_block_heading">{__("Commission Plan")}</h3>
          <ul>
            <Slider {...settings}>
              <li className="bronzeItem">
                <div className="outer_Layer">
                  <div className="vip_level_content">
                    <h3>{__("Bronze")}</h3>
                    <Link>{__("Learn more >")}</Link>
                  </div>

                  <img
                    alt=""
                    className="vipGiftIcon"
                    src={
                      require("../assets/images/kjb/affiliates/bronze-level affiliate.png")
                        .default
                    }
                  />
                </div>
              </li>
              <li className="silverItem">
                <div className="outer_Layer">
                  <div className="vip_level_content">
                    <h3>{__("Silver")}</h3>
                    <Link>{__("Learn more >")}</Link>
                  </div>

                  <img
                    alt=""
                    className="vipGiftIcon"
                    src={
                      require("../assets/images/kjb/affiliates/silver-level affiliate.png")
                        .default
                    }
                  />
                </div>
              </li>
              <li className="goldItem">
                <div className="outer_Layer">
                  <div className="vip_level_content">
                    <h3>{__("Gold")}</h3>
                    <Link>{__("Learn more >")}</Link>
                  </div>

                  <img
                    alt=""
                    className="vipGiftIcon"
                    src={
                      require("../assets/images/kjb/affiliates/gold-level affiliate.png")
                        .default
                    }
                  />
                </div>
              </li>
              <li className="platinumItem">
                <div className="outer_Layer">
                  <div className="vip_level_content">
                    <h3>{__("Platinum")}</h3>
                    <Link>{__("Learn more >")}</Link>
                  </div>

                  <img
                    alt=""
                    className="vipGiftIcon"
                    src={
                      require("../assets/images/kjb/affiliates/platinum-level affiliate.png")
                        .default
                    }
                  />
                </div>
              </li>
              {/* <li className="diamondItem">
                <div className="vip_level_content">
                  <h3>{__("Diamond")}</h3>
                  <Link>{__("Learn more >")}</Link>
                </div>

                <img
                  alt=""
                  className="vipGiftIcon"
                  src={
                    require("../assets/images/kjb/vip/bronze-bg.png").default
                  }
                />
              </li> */}
            </Slider>
          </ul>
        </div>
        <div className="centerBlock">
          <Link
            onClick={() => {
              setShowAffiliateTermsModal(true);
            }}
            className="commonBtn bannerBtn affiliateTerms"
          >
            {__("Affiliates Terms & Conditions")}
          </Link>
        </div>

        <div className="accordionBlock">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {__("What is the Miss Slotty affiliate program?")}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  The Miss Slotty affiliate program is a partnership opportunity
                  designed for individuals and organizations interested in
                  promoting the Miss Slotty brand and its online gaming
                  offerings. As an affiliate, you&apos;ll have the chance to
                  earn commissions by referring new players to Miss
                  Slotty&apos;s platform.
                </p>
                <p>
                  When you join the program, you&apos;ll receive a unique
                  affiliate link, which you can share through your website,
                  blog, social media channels, or other digital platforms.
                  Whenever a player signs up or makes a deposit at Miss Slotty
                  through your link, you&apos;ll earn a commission.
                </p>
                <p>
                  The program offers competitive commission structures,
                  including revenue share, CPA (Cost Per Acquisition), or a
                  hybrid model combining both. Affiliates have access to
                  real-time reporting tools and dedicated support to optimize
                  their campaigns and maximize earnings.
                </p>
                <p>
                  Miss Slotty&apos;s affiliate program is ideal for those who
                  have a strong online presence and are keen to monetize their
                  traffic by promoting a trusted and exciting online gaming
                  brand.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {__("What is the revenue share commission plan?")}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <span>
                    The revenue share commission plan in the Miss Slotty
                    affiliate program is a popular compensation model where
                    affiliates earn a percentage of the revenue generated by
                    players they refer to the Miss Slotty platform. This model
                    aligns the interests of both the affiliate and Miss Slotty,
                    as affiliates earn more when their referred players spend
                    more.
                  </span>
                </p>
                <p>
                  <span>Here&apos;s how it works:</span>
                </p>
                <ol start="1" type="1">
                  <li>
                    <em>
                      <span>Percentage-Based Earnings</span>
                    </em>
                    <span>
                      : As an affiliate, you&apos;ll earn a certain percentage
                      of the net revenue generated from the players you&apos;ve
                      referred. Net revenue is calculated as the gross gaming
                      revenue from these players minus certain expenses like
                      bonuses, chargebacks, network and transaction fees.
                    </span>
                  </li>
                  <li>
                    <em>
                      <span>Tiered Structure</span>
                    </em>
                    <span>
                      : Miss Slotty offers a tiered structure. This means the
                      percentage you earn can increases based on the performance
                      or the total revenue generated by your referrals. For
                      instance, you might earn a higher percentage as your
                      referrals generate more revenue.
                    </span>
                  </li>
                  <li>
                    <em>
                      <span>Longevity of Earnings</span>
                    </em>
                    <span>
                      : One key advantage of this plan is the long-term earning.
                      As long as the players you refer continue to play on Miss
                      Slotty, you&apos;ll continue to receive the percentage of
                      their generated revenue.
                    </span>
                  </li>
                  <li>
                    <em>
                      <span>Transparency and Tracking</span>
                    </em>
                    <span>
                      : Affiliates are provided with a login to the affiliate
                      dashboard, reporting tools to track their earnings, player
                      activity, and other key metrics. This transparency helps
                      in strategizing and optimizing referral methods. Also, all
                      needed marketing materials are available of course.&nbsp;
                    </span>
                  </li>
                </ol>
                <p>
                  <em>
                    <span>No Negative Carryover</span>
                  </em>
                  <span>
                    : Miss Slotty&rsquo;s revenue share plan ensures
                    there&apos;s no negative carryover. This means if your
                    referred players win more than they wager in a month
                    (resulting in a negative balance for you), the balance is
                    reset to zero at the beginning of the next month. We will
                    take the loss for that and you will keep earning.
                  </span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
                {__("Is there a negative carry-over?")}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <span>
                    No, there is no negative carry-over in the Miss Slotty
                    affiliate program. This policy is particularly beneficial
                    for you as an affiliate of ours, as it means that any
                    negative balance incurred in a given month does not roll
                    over to the next month.
                  </span>
                </p>
                <p>
                  <span>
                    With Miss Slotty&apos;s no negative carry-over policy, the
                    slate is wiped clean at the start of each new month. This
                    means that if your referred players win more than they lose
                    in one month, creating a negative commission balance, this
                    balance will be reset to zero at the beginning of the next
                    month. As a result, you start each month afresh, without
                    having to overcome a deficit from the previous month.
                  </span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
                {__("What is the restricted GEOs list?")}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  <span>
                    The &quot;restricted GEOs list&quot; in the Miss Slotty
                    affiliate program identifies geographic locations where the
                    promotion of Miss Slotty&apos;s services is either
                    prohibited or subject to specific restrictions. This list is
                    crucial for compliance with varying online gambling laws
                    across different jurisdictions.
                  </span>
                </p>
                <p>
                  <span>
                    You can see the full list in our{" "}
                    <Link
                      onClick={() => {
                        setShowAffiliateTermsModal(true);
                      }}
                    >
                      Affiliates Terms &amp; Conditions
                    </Link>{" "}
                    page.
                  </span>
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
                {__("How are the players connected to the affiliate?")}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Players are connected to affiliates in the Miss Slotty
                  affiliate program through a unique tracking mechanism. This
                  process ensures that affiliates receive credit for the players
                  they refer to Miss Slotty. Here&apos;s a (simplified)
                  overview:
                </p>
                <ol>
                  <li>
                    <strong>
                      <em>Unique Affiliate Links</em>
                    </strong>
                    : Each affiliate is given a unique tracking link. This link
                    contains a special code or identifier unique to the
                    affiliate.
                  </li>
                  <li>
                    <strong>
                      <em>Link Usage</em>
                    </strong>
                    : When potential players click on this link, they are
                    directed to the Miss Slotty website. The unique code in the
                    link is used to track their activities.
                  </li>
                  <li>
                    <strong>
                      <em>Cookie Tracking</em>
                    </strong>
                    : Upon clicking the affiliate link, a cookie is placed on
                    the user&apos;s device. This cookie contains the
                    affiliate&apos;s unique identifier and helps in tracking the
                    user&apos;s activities on the site. The cookie has a
                    predetermined lifespan, ensuring that the affiliate gets
                    credit for the player&apos;s actions (like signing up or
                    making a deposit) within a certain time frame after the
                    first click.
                  </li>
                  <li>
                    <strong>
                      <em>Registration and Activity Tracking</em>
                    </strong>
                    : If the user signs up or engages in other qualifying
                    activities (like making a deposit), these actions are
                    recorded and attributed to the affiliate, based on the
                    unique code from the cookie.
                  </li>
                  <li>
                    <strong>
                      <em>Reporting and Transparency</em>
                    </strong>
                    : Affiliates have access to a dashboard or reporting tools
                    where they can see the number of clicks, sign-ups, and other
                    relevant activities generated through their links.
                  </li>
                  <li>
                    <strong>
                      <em>Commission Calculation</em>
                    </strong>
                    : Based on these tracked activities, the affiliate earns
                    commissions as per the agreed terms in the affiliate program
                    (like a percentage of revenue generated from referred
                    players).
                  </li>
                </ol>
                <p>
                  This tracking mechanism is essential for the integrity of the
                  affiliate program, ensuring that affiliates are fairly
                  credited for the traffic and players they bring to Miss
                  Slotty.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
                {__("How are the commissions calculated?")}
              </Accordion.Header>
              <Accordion.Body>
                <p>
                  Commissions in the Miss Slotty affiliate program are
                  calculated based on the specific terms of the agreement
                  between the affiliate and Miss Slotty. The calculation
                  typically based on the revenue share plan. Here&apos;s a
                  general outline of how the commission is calculated:
                </p>
                <ol>
                  <li>
                    <strong>
                      <em>Revenue Share</em>
                    </strong>
                    : Under this model, commissions are a percentage of the net
                    revenue generated from the players referred by the
                    affiliate. Net revenue is usually calculated as the total
                    amount wagered by these players minus winnings, bonuses
                    given, transaction fees, and any other applicable costs. For
                    example, if the <em>net revenue</em> from your referred
                    players is $10,000 and your revenue share is 20%, your
                    commission would be $2,000.
                  </li>
                  <li>
                    <strong>
                      <em>Net Revenue</em>
                    </strong>
                    : Net Revenue is calculated by taking the Gross Gaming
                    Revenue (GGR), which is the total bets made by players minus
                    their winnings, and then deducting any bonuses converted to
                    cash, chargebacks, and a 28% network fee.
                  </li>
                </ol>
                <p>
                  <span>
                    For example, if the GGR is $10,000, with $500 in bonuses and
                    $200 in chargebacks, the calculation would be:
                  </span>
                </p>
                <ol>
                  <li>
                    <span>GGR: $10,000</span>
                  </li>
                  <li>
                    <span>Subtract Bonuses: $10,000 - $500 = $9,500</span>
                  </li>
                  <li>
                    <span>Subtract Chargebacks: $9,500 - $200 = $9,300</span>
                  </li>
                  <li>
                    <span>Subtract 28% Network Fee: $9,300 - 28% = $6,696</span>
                  </li>
                </ol>
                <p>
                  <span>
                    Thus, the Net Revenue, in this case, would be $6,696. This
                    figure is then used to calculate your commission.
                  </span>
                </p>
                <div>
                  <ol>
                    <li>
                      <strong>
                        <em>Time Frame and Calculations</em>
                      </strong>
                      : Commissions are calculated over a specific period,
                      usually monthly. Affiliates can track their earnings and
                      player activity through a provided dashboard.
                    </li>
                  </ol>
                </div>
                <p>
                  For more information please check out our{" "}
                  <Link
                    onClick={() => {
                      setShowAffiliateTermsModal(true);
                    }}
                  >
                    Affiliates Terms &amp; Conditions
                  </Link>{" "}
                  page.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>

      <AffiliatesTermsConditions
        showAffiliateTermsModal={showAffiliateTermsModal}
        setShowAffiliateTermsModal={setShowAffiliateTermsModal}
        {...props}
      />
    </>
  );
}
