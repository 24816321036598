import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";

export default function SafeGamblingTips(props) {
  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});

  const getResults = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: props.pageUid,
    });
    if (response.status === 200) {
      setPageContent(response.data.content_data);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    // getResults();
  }, []);

  return (
    <div className="feedback_container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="feedback_content_area showFull">
              <p>
                <strong>
                  <span>Responsible Gaming with Miss Slotty:&nbsp;</span>
                </strong>
              </p>
              <p>
                <strong>
                  <span>
                    Budget Calculator &amp; Smart Gambling Tips Miss Slotty
                  </span>
                </strong>
              </p>

              <p>
                Embrace responsible gaming at Miss Slotty to make the most of
                your experience, whether you&apos;re spinning online slots or
                placing bets on live sports events. It&apos;s essential to set
                personal limits and gamble within your means. Our budget
                calculator and safe gambling tips are here to keep you in
                control at Miss Slotty.
              </p>

              <p>
                <strong>Understanding Safer Gambling&nbsp;</strong>
              </p>
              <p>
                Safer gambling is about maintaining control while enjoying
                casino games and sports betting. It means spending within your
                means and balancing your gambling time, preventing it from
                becoming a problem. Miss Slotty offers various tools for
                managing your gaming activities, including our innovative{" "}
                <u>budget calculator</u>.
              </p>

              <p>
                Determining Your Gambling Budget Everyone&apos;s financial
                situation is different. Consider your disposable income and
                decide what portion you&apos;re comfortable allocating to
                gambling each month. Our budget calculator is a handy tool to
                assist you:
              </p>

              <p>
                Miss Slotty Budget Calculator Our budget calculator helps you
                determine your disposable income by considering your income
                sources and monthly expenses. It shows how much you can afford
                to spend on leisure activities, including gambling at Miss
                Slotty.
              </p>

              <p>
                Using the Budget Calculator The calculator is user-friendly.
                Enter your income and expenses, and it calculates your
                disposable income. Decide how much of this you&apos;d like to
                allocate to gambling, ensuring it&apos;s an amount you&apos;re
                comfortable with losing.
              </p>

              <p>
                <strong>Financial Management Tips</strong>
              </p>
              <ul>
                <li>
                  Create a budget using a template, considering your income and
                  expenses.
                </li>
                <li>
                  Ensure your essential living costs are covered before spending
                  on leisure activities.
                </li>
                <li>
                  Discuss significant budget decisions with your partner to stay
                  aligned.
                </li>
                <li>
                  Allocate a reasonable gambling budget as part of your monthly
                  expenses.
                </li>
                <li>
                  Do not gamble daily and do not gamble many hours each time
                </li>
              </ul>

              <p>
                Recognizing Signs of Problem Gambling It&apos;s vital to
                acknowledge potential gambling issues. Signs include chasing
                losses, feeling anxious or upset while gambling, excessive time
                spent on gambling sites, gambling affecting personal
                relationships or work, and incurring debts due to gambling.
              </p>

              <p>
                <strong>Regaining Control Over Gambling&nbsp;</strong>
              </p>
              <p>
                If you recognize a gambling problem, take steps to regain
                control:
              </p>

              <ul type="disc">
                <li>
                  Set deposit limits: Miss Slotty allows setting a monthly
                  deposit limit, helping you adhere to your budget.
                </li>
                <li>
                  Set loss limits: This tool limits potential losses over a
                  specified period, encouraging responsible gambling.
                </li>
                <li>
                  Seek support: Discuss your concerns with loved ones and
                  utilize resources from responsible gambling platforms,
                  helplines, and support groups.
                </li>
              </ul>
              <p>
                Stay in charge of your gambling activities with Miss
                Slotty&apos;s tools and tips, ensuring a fun and responsible
                gaming experience.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
