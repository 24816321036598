import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import emailValidate from "services/emailValidate";
import validator from "validator";
import swal from "sweetalert";
import api from "services/api";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../../elements/PageHeadline";

export default function ResetPassword(props) {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  var params = useParams();
  // const params = queryString.parse(window.location.search).p;

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully updated",
    variant: "success",
    showAlert: false,
  });

  const [changePasswordForm, setChangePasswordForm] = useState({
    user_id: params.userId,
    code: params.code,
    password: "",
    confirm_password: "",
  });

  const validateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);
    if (formData.password < 2 || formData.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: __("Please enter password") };
      valid = false;
    }

    setErrors(errorsTmp);
    return valid;
  };

  const updateFormData = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const [acceptField, setAcceptField] = useState({
    password: false,
    confirm_password: false,
  });

  const formInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    if (field == "password") {
      if (
        validator.isStrongPassword(value, {
          minLength: 8,
          minLowercase: 1,
          minUppercase: 1,
          minNumbers: 1,
          minSymbols: 1,
        })
      ) {
        setAcceptField({ ...acceptField, password: true });
        setErrors({ ...errors, password: "" });
        setChangePasswordForm({ ...changePasswordForm, [field]: value });
      } else {
        setAcceptField({ ...acceptField, password: false });
        setErrors({
          ...errors,
          password: __(
            "Your password must contain between 8 and 30 characters, including at least one number, one special character, one lowercase and one upercase. Do not use your email address or username as your password."
          ),
        });
      }
    }
    if (field == "confirm_password") {
      var password = formData.password;
      if (value === password) {
        setAcceptField({ ...acceptField, confirm_password: true });
        setErrors({ ...errors, confirm_password: "" });
        setChangePasswordForm({ ...changePasswordForm, [field]: value });
      } else {
        setAcceptField({ ...acceptField, confirm_password: false });
        setErrors({
          ...errors,
          confirm_password: __("password doesn't match"),
        });
      }
    }

    updateFormData(field, value);
  };
  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
  });

  const sendAction = () => {
    var valid = validateForm();
    if (valid) {
      swal({
        className: props.theme,
        title: __("Done!"),
        text: __("password changed"),
        icon: "success",
      });
      history.push("/login");
    }
  };

  const resetPasswordAction = async (ev) => {
    ev.preventDefault();
    var valid = validateForm();

    if (valid) {
      var response = await api.post(
        "/reset-password-action",
        changePasswordForm
      );

      if (response.data.status == "success") {
        setAlertMsg({
          message: response.data.message,
          variant: "success",
          showAlert: true,
        });

        setChangePasswordForm({
          user_id: params.userId,
          code: params.code,
          password: "",
          confirm_password: "",
        });
        setTimeout(() => {
          setAlertMsg({
            message: response.data.message,
            variant: "success",
            showAlert: false,
          });
          history.push("/");
        }, 7000);
      } else if (response.data.status == "error") {
        if (response.data.errors) {
          setErrors(response.data.errors);
        } else {
          setAlertMsg({
            message: response.data.message,
            variant: "danger",
            showAlert: true,
          });
          setInterval(() => {
            setAlertMsg({
              message: response.data.message,
              variant: "danger",
              showAlert: false,
            });
          }, 7000);
        }
      } else if (response.data.status == "LinkExpired") {
        if (response.data.errors) {
          setErrors(response.data.errors);
        } else {
          setAlertMsg({
            message: response.data.message,
            variant: "warning",
            showAlert: true,
            forgotLink: true,
          });
        }
      } else {
        setAlertMsg({
          message: response.data.message,
          variant: "danger",
          showAlert: true,
        });
        setInterval(() => {
          setAlertMsg({
            message: response.data.message,
            variant: "danger",
            showAlert: false,
          });
        }, 7000);
      }
      setTimeout(() => {
        setAlertMsg({
          message: response.data.message,
          variant: "warning",
          showAlert: false,
        });
      }, 7000);
    }
  };

  return (
    <div className="container">
      <br />
      <br />
      <PageHeadline page_name={__("Reset Password")} />
      <div className="user_dashboard">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="form_area page_content_area">
              <h3 className="common_headline text-center">
                {__("Set your password")}
              </h3>
              <br />

              <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
                {alertMsg.message}
              </Alert>

              <form onSubmit={resetPasswordAction}>
                <div className="input_item">
                  <div className="name reg_field">{__("New password")}</div>
                  <input
                    onChange={formInputChange}
                    type="password"
                    name="password"
                    className={
                      acceptField.password
                        ? "form-control name_value border_success"
                        : "form-control name_value"
                    }
                    placeholder={__("new password")}
                    value={formData.password}
                  />
                  <div className="error_msg">{errors.password}</div>
                </div>
                <div className="input_item">
                  <div className="name reg_field">
                    {__("Confirm new password")}
                  </div>
                  <input
                    onChange={formInputChange}
                    type="password"
                    name="confirm_password"
                    className={
                      acceptField.confirm_password
                        ? "form-control name_value border_success"
                        : "form-control name_value"
                    }
                    placeholder={__("Your email")}
                    value={formData.confirm_password}
                  />
                  <div className="error_msg">{errors.confirm_password}</div>
                </div>
                <br />
                <div className="input_item">
                  <button type="submit" className="commonBtn blackBtnBG">
                    {__("Save Changes")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
