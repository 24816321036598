import React, { useState, useEffect } from "react";
import UserLeftArea from "elements/dashboard_elements/UserLeftArea";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import AppContext from "contexts/AppContext";
import { Button, Modal, Accordion } from "react-bootstrap";
import Pagination from "elements/Pagination";
import NoRecordsFound from "elements/NoRecordsFound";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../PageHeadline";

export default function AccountActvity(props) {
  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    props.setSpinner(true);
    var response = await api.post("/history/account-activity", form);
    props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  return (
    <>
      <PageHeadline page_name={__("Account Activity")} />

      <div className="page_content_area">
        <div className="table_area">
          <div className="d-none d-sm-block">
            {results.length ? (
              <>
                <div className="heading_row">
                  <div className="item">{__("Account Action")}</div>
                  <div className="item">{__("IP Address")}</div>
                  <div className="item">{__("Device")}</div>
                  <div className="item">{__("Browser")}</div>
                  <div className="item text-center">{__("Date")}</div>
                </div>
                {results.map((item, index) => (
                  <div className="data_row" key={index}>
                    <div className="item">
                      <span className="action_icon">
                        <i className="far fa-check"></i>
                      </span>
                      {item.account_action}
                    </div>
                    <div className="item">{item.ip_address}</div>
                    <div className="item">{item.device}</div>
                    <div className="item">{item.browser}</div>
                    <div className="item">
                      <span className=" table_btn">{item.date_created}</span>
                    </div>
                  </div>
                ))}
                <div className="paginationArea">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
          {/* mobile */}
          <div className="mobile_accordion d-block d-sm-none">
            {results.length ? (
              <>
                <Accordion className="account_activity_accordion">
                  {results.map((item, index) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div className="account_activity_heading">
                          <div className="actionName">
                            {item.account_action}
                          </div>
                          <div className="actionDate">{item.date_created}</div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="accordion_content">
                          <div className="input_item">
                            <div className="name">{__("Account Action")}</div>
                            <div className="form-control name_value">
                              {item.account_action}
                            </div>
                          </div>
                          <div className="input_item">
                            <div className="name">{__("Ip Address")}</div>
                            <div className="form-control name_value">
                              {item.ip_address}
                            </div>
                          </div>
                          <div className="input_item">
                            <div className="name">{__("Device")}</div>
                            <div className="form-control name_value">
                              {item.device}
                            </div>
                          </div>
                          <div className="input_item">
                            <div className="name">{__("Browser")}</div>
                            <div className="form-control name_value">
                              {item.browser}
                            </div>
                          </div>
                          <div className="input_item">
                            <div className="name">{__("Date")}</div>
                            <div className="form-control name_value">
                              {item.date_created}
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>

                <br />
                <br />
                <div className="paginationArea">
                  <Pagination data={pagination} onPageChange={onPageChange} />
                </div>
              </>
            ) : (
              <NoRecordsFound />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
