import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Table,
  ProgressBar,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import NoRecordsFound from "elements/NoRecordsFound";
import GameItem from "elements/games_elements/GameItem";
import LoadMore from "elements/LoadMore";
import api from "services/api";
import moment from "moment";
import DOMPurify from "dompurify";
import { __ } from "services/Translator";
import { Helmet } from "react-helmet";
import GamesFilter from "elements/games_elements/GamesFilter";
import RemainintTime from "elements/RemainingTime";

export default function Races(props) {
  const [showDetails, setShowDetails] = useState(false);
  const [raceTab, setRaceTab] = useState("In Progress");
  const [showLeaderBoard, setShowLeaderBoard] = useState(false);

  const [races, setRaces] = useState([]);
  const [leaderBoard, setLeaderBoard] = useState([]);
  const [campaignDetails, setCampaignDetails] = useState("");
  const [campaignGames, setCampaignGames] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const [activeRaces, setActiveRaces] = useState([]);

  const getActiveRaces = async (raceStatus) => {
    var response = await api.get("/race/list/active");
    if (response.status === 200) {
      setActiveRaces(response.data);
    }
  };

  const getRaces = async (raceStatus) => {
    var response = await api.get("/race/list/" + raceStatus);
    if (response.status === 200) {
      setRaces(response.data);
    }
  };

  const [racePage, setRacePage] = useState(null);
  const getRacePage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "races",
    });
    if (response.status === 200) {
      setRacePage(response.data);
    }
  };
  useEffect(() => {
    getRacePage();
    getActiveRaces();
  }, []);

  useEffect(() => {
    if (raceTab === "In Progress") {
      getRaces("active");
    } else {
      getRaces("inactive");
    }
  }, [raceTab]);

  const openLeaderBoard = async (campaign) => {
    setShowLeaderBoard(true);
    setCampaignDetails(campaign);
    updateLeaderBoard(campaign);
  };
  const closeLeaderBoard = () => {
    setShowLeaderBoard(false);
  };

  const openDetails = async (campaign) => {
    setShowDetails(true);
    setCampaignDetails(campaign);
    getRaceGames(campaign.id, 1);
  };

  const updateLeaderBoard = async (campaign) => {
    if (campaign) {
      var response = await api.get(
        "/race/leaderboard/" + campaign.id + "/" + 50
      );
      if (response.status === 200) {
        setLeaderBoard(response.data);
      }
    }
  };

  useEffect(() => {
    if (showLeaderBoard && campaignDetails) {
      var interval2 = window.setInterval(() => {
        updateLeaderBoard(campaignDetails);
      }, 10000);
    }
    return () => {
      try {
        window.clearInterval(interval2);
      } catch (ex) {}
    };
  }, [showLeaderBoard, campaignDetails]);

  const getRaceGames = async (campaignId, page) => {
    var response = await api.get("/race/games/" + campaignId + "/" + page);
    if (response.status === 200) {
      setCampaignGames([...campaignGames, ...response.data.games]);
      setGameLoaded(campaignGames.length + response.data.games.length);
      setPagination(response.data.paginationData);
      setTotalGame(response.data.gameCount);
    }
  };

  const onPageChange = (page) => {
    getRaceGames(campaignDetails.id, page);
  };

  const calculateGridColumnsForGameImage = (gamesCount) => {
    if (!gamesCount) {
      return "repeat(1, 1fr)";
    }
    if (gamesCount > 3) {
      gamesCount = 3;
    }
    return "repeat(" + gamesCount + ", 1fr)";
  };

  const renderHTML = (rawHTML) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML) },
    });

  return (
    <>
      <Helmet>
        <title>
          {__(
            "Miss Slotty | Race against other players for your chance to win a share of the prize pool!"
          )}
        </title>
        <meta
          name="description"
          content={__(
            "Miss Slotty! View our current and upcoming tournaments where you take on other players - you all start with the same amount of chips, whoever ends with the most wins!"
          )}
        />
      </Helmet>

      <div className="races_page promotions">
        {activeRaces.length > 0 && (
          <>
            <div className="container-md">
              <div className="row">
                <div className="col-md-12 col-lg-8 offset-lg-2">
                  {activeRaces.length ? (
                    <>
                      {activeRaces.map((item, index) => (
                        <section className="promotions_banner_v1">
                          <div className="banner_img">
                            <img
                              className="promotions_banner_img desktopBanner"
                              src={
                                require("assets/images/v1/races/banner-bg.svg")
                                  .default
                              }
                              alt="Promotions Banner"
                            />
                            <img
                              className="promotions_banner_img mobileBanner"
                              src={
                                require("assets/images/v1/races/race-item-bg-mobile.png")
                                  .default
                              }
                              alt="Promotions Banner"
                            />
                          </div>
                          <div className="races_content">
                            <h1 className="banner_heading">
                              {item.lang?.title}
                            </h1>
                            <div className="races_conut_down">
                              <RemainintTime timestamp={item.ending_in} />
                            </div>
                            <Link
                              onClick={() => {
                                openLeaderBoard(item);
                              }}
                              className="commonBtn filledBtn"
                            >
                              {__("View Leaderboard")}
                            </Link>
                          </div>
                        </section>
                      ))}
                    </>
                  ) : (
                    <>{__("No Active Race")}</>
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        {/* <GamesFilter {...props} /> */}

        <div className="container-md">
          {activeRaces.length > 0 && (
            <>
              <section className="winner_section margin_bottom_0">
                <div className="winer_heading margin_bottom_0">
                  <Link
                    onClick={() => {
                      setRaceTab("In Progress");
                    }}
                    style={{ marginRight: "10px" }}
                    className={
                      raceTab === "In Progress"
                        ? "commonBtn filledBtn active"
                        : "commonBtn filledBtn"
                    }
                  >
                    <svg
                      width="21"
                      height="23"
                      viewBox="0 0 21 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.31845 21.9954L5.70536 21.8872C6.07753 21.7832 6.29491 21.3972 6.19091 21.025L1.76723 5.19439C1.70481 4.97098 1.55619 4.78152 1.35408 4.66769C1.15196 4.55386 0.912903 4.52499 0.689496 4.58742L0.639528 4.60137C0.17428 4.73136 -0.0975018 5.2139 0.0324833 5.67914L4.45627 21.5098C4.56027 21.882 4.94627 22.0994 5.31845 21.9954V21.9954Z"
                        fill="#10294F"
                      />
                      <path
                        d="M20.8966 10.9326C19.4937 10.2331 18.1959 9.34024 17.0412 8.28017C16.9441 8.19131 16.9061 8.05492 16.9433 7.92869C17.5155 5.76408 17.9404 3.5632 18.2152 1.34116C18.2587 0.971567 18.0594 0.862861 17.8492 1.11651C13.7257 6.04809 6.59098 0.214255 2.34424 4.69652C2.39522 4.79199 2.43533 4.89287 2.46381 4.99728L5.33363 15.2735C9.58762 10.8456 16.6969 16.6432 20.8132 11.7225C21.0234 11.4688 21.0632 11.005 20.8966 10.9326V10.9326Z"
                        fill="#10294F"
                      />
                    </svg>

                    {__("In Progress")}
                  </Link>
                  <Link
                    onClick={() => {
                      setRaceTab("Ended");
                    }}
                    className={
                      raceTab === "Ended"
                        ? "commonBtn filledBtn active"
                        : "commonBtn filledBtn"
                    }
                  >
                    <svg
                      width="21"
                      height="23"
                      viewBox="0 0 21 23"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.31845 21.9954L5.70536 21.8872C6.07753 21.7832 6.29491 21.3972 6.19091 21.025L1.76723 5.19439C1.70481 4.97098 1.55619 4.78152 1.35408 4.66769C1.15196 4.55386 0.912903 4.52499 0.689496 4.58742L0.639528 4.60137C0.17428 4.73136 -0.0975018 5.2139 0.0324833 5.67914L4.45627 21.5098C4.56027 21.882 4.94627 22.0994 5.31845 21.9954V21.9954Z"
                        fill="#10294F"
                      />
                      <path
                        d="M20.8966 10.9326C19.4937 10.2331 18.1959 9.34024 17.0412 8.28017C16.9441 8.19131 16.9061 8.05492 16.9433 7.92869C17.5155 5.76408 17.9404 3.5632 18.2152 1.34116C18.2587 0.971567 18.0594 0.862861 17.8492 1.11651C13.7257 6.04809 6.59098 0.214255 2.34424 4.69652C2.39522 4.79199 2.43533 4.89287 2.46381 4.99728L5.33363 15.2735C9.58762 10.8456 16.6969 16.6432 20.8132 11.7225C21.0234 11.4688 21.0632 11.005 20.8966 10.9326V10.9326Z"
                        fill="#10294F"
                      />
                    </svg>

                    {__("Ended")}
                  </Link>
                </div>
              </section>
            </>
          )}

          <section className="race_list_v1 leaderboard_v1 padding_top_25">
            {races.length ? (
              <>
                {races.map((item, index) => (
                  <>
                    <div className="single_race_item">
                      <div className="race_date">
                        {moment(item.expiry_date, "YYYY-MM-DD HH:mm:ss").format(
                          "MMMM Do YYYY, hh:mm:ss A"
                        )}
                      </div>
                      {item.race_banner ? (
                        <>
                          <div className="leaderbord_content single_race_body">
                            <div className="leaderboard_games">
                              <div className="leaderboard_image">
                                <img
                                  className=""
                                  src={item.race_banner}
                                  alt="Miss Slotty "
                                />
                              </div>
                            </div>
                            <div className="leaderboard_text">
                              <div className="info_btn">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {/* {item.lang?.campaign_information} */}
                                      {renderHTML(item.lang?.description)}
                                    </Tooltip>
                                  }
                                >
                                  <svg
                                    width="31"
                                    height="30"
                                    viewBox="0 0 31 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.4994 0C7.21619 0 0.5 6.71619 0.5 14.9994C0.5 23.2825 7.21619 30 15.4994 30C23.7825 30 30.5 23.2825 30.5 14.9994C30.5 6.71619 23.7825 0 15.4994 0ZM18.6219 23.247C17.8498 23.5517 17.2352 23.7829 16.7743 23.9429C16.3146 24.1029 15.78 24.1829 15.1717 24.1829C14.2371 24.1829 13.5095 23.9543 12.9914 23.4984C12.4733 23.0425 12.2156 22.4648 12.2156 21.7625C12.2156 21.4895 12.2346 21.2102 12.2727 20.9257C12.3121 20.6413 12.3743 20.3213 12.4594 19.9619L13.4257 16.5486C13.5108 16.221 13.5844 15.9098 13.6429 15.6203C13.7013 15.3283 13.7292 15.0603 13.7292 14.8165C13.7292 14.3822 13.639 14.0775 13.46 13.906C13.2784 13.7346 12.9368 13.6508 12.4276 13.6508C12.1787 13.6508 11.9222 13.6876 11.6594 13.7651C11.399 13.8451 11.173 13.9175 10.9876 13.9886L11.2429 12.9371C11.8752 12.6794 12.481 12.4584 13.0587 12.2756C13.6365 12.0902 14.1825 11.9987 14.6968 11.9987C15.6251 11.9987 16.3413 12.2248 16.8454 12.6717C17.347 13.12 17.5997 13.7029 17.5997 14.419C17.5997 14.5676 17.5819 14.8292 17.5476 15.2025C17.5133 15.5771 17.4486 15.9187 17.3546 16.2324L16.3933 19.6356C16.3146 19.9086 16.2448 20.221 16.1813 20.5702C16.119 20.9194 16.0886 21.186 16.0886 21.3651C16.0886 21.8171 16.1889 22.1257 16.3921 22.2895C16.5927 22.4533 16.9444 22.5359 17.4422 22.5359C17.6771 22.5359 17.94 22.494 18.2371 22.4127C18.5317 22.3314 18.7451 22.259 18.8797 22.1968L18.6219 23.247ZM18.4517 9.43365C18.0035 9.85016 17.4638 10.0584 16.8327 10.0584C16.2029 10.0584 15.6594 9.85016 15.2073 9.43365C14.7578 9.01714 14.5305 8.51048 14.5305 7.91873C14.5305 7.32825 14.759 6.82032 15.2073 6.4C15.6594 5.97841 16.2029 5.76889 16.8327 5.76889C17.4638 5.76889 18.0048 5.97841 18.4517 6.4C18.9 6.82032 19.1248 7.32825 19.1248 7.91873C19.1248 8.51175 18.9 9.01714 18.4517 9.43365Z"
                                      fill="#e9a147"
                                    />
                                  </svg>
                                </OverlayTrigger>
                              </div>
                              <div className="leaderboard_text_area">
                                <div className="headline">
                                  {item.lang?.title}
                                </div>
                                <div className="title small bold">
                                  {item.lang?.campaign_information}
                                </div>
                                {item.ending_in ? (
                                  <>
                                    <div className="countdown">
                                      <div className="ending_text">
                                        {__("ENDING IN")}
                                      </div>
                                      <div className="ending_clock">
                                        <RemainintTime
                                          timestamp={item.ending_in}
                                        />
                                      </div>
                                      <div className="ending_text">
                                        {__("MIN BET")} : ${item.minimum_wager}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="title small">
                                      {__("The race has")}
                                    </div>
                                    <div className="title colored">
                                      {__("Finished")}
                                    </div>
                                    <div className="title uppercase small">
                                      {__("MIN BET")} : ${item.minimum_wager}
                                    </div>
                                  </>
                                )}

                                <div className="leaderbook_link">
                                  <Link
                                    className="commonBtn filledBtn"
                                    onClick={() => {
                                      openLeaderBoard(item);
                                    }}
                                  >
                                    {__("View Leaderboard")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="leaderbord_content single_race_body">
                            <div className="leaderboard_games">
                              <div className="leaderboard_image">
                                {item.race_banner ? (
                                  <div className="race_banner">
                                    <img
                                      src={item.race_banner}
                                      className="race_banner_image"
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="race_banner"
                                    style={{
                                      gridTemplateColumns:
                                        calculateGridColumnsForGameImage(
                                          item.games?.length ?? 0
                                        ),
                                    }}
                                  >
                                    {item.games.map((game, key) => {
                                      if (key > 2) {
                                        return null;
                                      }
                                      return (
                                        <img
                                          src={game.game_snapshot_url}
                                          className="race_banner_image"
                                        />
                                      );
                                    })}
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="leaderboard_text">
                              <div className="info_btn">
                                <OverlayTrigger
                                  overlay={
                                    <Tooltip>
                                      {/* {item.lang?.campaign_information} */}
                                      {renderHTML(item.lang?.description)}
                                    </Tooltip>
                                  }
                                >
                                  <svg
                                    width="31"
                                    height="30"
                                    viewBox="0 0 31 30"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M15.4994 0C7.21619 0 0.5 6.71619 0.5 14.9994C0.5 23.2825 7.21619 30 15.4994 30C23.7825 30 30.5 23.2825 30.5 14.9994C30.5 6.71619 23.7825 0 15.4994 0ZM18.6219 23.247C17.8498 23.5517 17.2352 23.7829 16.7743 23.9429C16.3146 24.1029 15.78 24.1829 15.1717 24.1829C14.2371 24.1829 13.5095 23.9543 12.9914 23.4984C12.4733 23.0425 12.2156 22.4648 12.2156 21.7625C12.2156 21.4895 12.2346 21.2102 12.2727 20.9257C12.3121 20.6413 12.3743 20.3213 12.4594 19.9619L13.4257 16.5486C13.5108 16.221 13.5844 15.9098 13.6429 15.6203C13.7013 15.3283 13.7292 15.0603 13.7292 14.8165C13.7292 14.3822 13.639 14.0775 13.46 13.906C13.2784 13.7346 12.9368 13.6508 12.4276 13.6508C12.1787 13.6508 11.9222 13.6876 11.6594 13.7651C11.399 13.8451 11.173 13.9175 10.9876 13.9886L11.2429 12.9371C11.8752 12.6794 12.481 12.4584 13.0587 12.2756C13.6365 12.0902 14.1825 11.9987 14.6968 11.9987C15.6251 11.9987 16.3413 12.2248 16.8454 12.6717C17.347 13.12 17.5997 13.7029 17.5997 14.419C17.5997 14.5676 17.5819 14.8292 17.5476 15.2025C17.5133 15.5771 17.4486 15.9187 17.3546 16.2324L16.3933 19.6356C16.3146 19.9086 16.2448 20.221 16.1813 20.5702C16.119 20.9194 16.0886 21.186 16.0886 21.3651C16.0886 21.8171 16.1889 22.1257 16.3921 22.2895C16.5927 22.4533 16.9444 22.5359 17.4422 22.5359C17.6771 22.5359 17.94 22.494 18.2371 22.4127C18.5317 22.3314 18.7451 22.259 18.8797 22.1968L18.6219 23.247ZM18.4517 9.43365C18.0035 9.85016 17.4638 10.0584 16.8327 10.0584C16.2029 10.0584 15.6594 9.85016 15.2073 9.43365C14.7578 9.01714 14.5305 8.51048 14.5305 7.91873C14.5305 7.32825 14.759 6.82032 15.2073 6.4C15.6594 5.97841 16.2029 5.76889 16.8327 5.76889C17.4638 5.76889 18.0048 5.97841 18.4517 6.4C18.9 6.82032 19.1248 7.32825 19.1248 7.91873C19.1248 8.51175 18.9 9.01714 18.4517 9.43365Z"
                                      fill="#e9a147"
                                    />
                                  </svg>
                                </OverlayTrigger>
                              </div>
                              <div className="leaderboard_text_area">
                                <div className="headline">
                                  {item.lang?.title}
                                </div>
                                <div className="title small bold">
                                  {item.lang?.campaign_information}
                                </div>
                                {item.ending_in ? (
                                  <>
                                    <div className="countdown">
                                      <div className="ending_text">
                                        {__("ENDING IN")}
                                      </div>
                                      <div className="ending_clock">
                                        <RemainintTime
                                          timestamp={item.ending_in}
                                        />
                                      </div>
                                      <div className="ending_text">
                                        {__("MIN BET")} : ${item.minimum_wager}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="title small">
                                      {__("The race has")}
                                    </div>
                                    <div className="title colored">
                                      {__("Finished")}
                                    </div>
                                    <div className="title uppercase small">
                                      {__("MIN BET")} : ${item.minimum_wager}
                                    </div>
                                  </>
                                )}

                                <div className="leaderbook_link">
                                  <Link
                                    className="commonBtn filledBtn"
                                    onClick={() => {
                                      openLeaderBoard(item);
                                    }}
                                  >
                                    {__("View Leaderboard")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                ))}
              </>
            ) : (
              <>
                <NoRecordsFound />
              </>
            )}
          </section>

          <div className="col-md-8 offset-md-2 d-none">
            <div className="race_list">
              {races.length ? (
                <>
                  {races.map((item, index) => (
                    <li>
                      <div className="race_schedule dark_white text-center d-none d-md-block">
                        {moment(item.expiry_date, "YYYY-MM-DD HH:mm:ss").format(
                          "MMMM Do YYYY, hh:mm:ss A"
                        )}
                      </div>
                      <br />
                      <div className="race_dot d-none d-md-block"></div>
                      <div className="race_border d-none d-md-block"></div>
                      <div className="race_content">
                        <div className="more_btn">
                          <Button
                            onClick={() => {
                              openDetails(item);
                            }}
                            className="details_btn"
                          >
                            <svg
                              name="Checkmark"
                              width="16"
                              height="16"
                              className="race-item__QuestionIcon-sc-14u1juz-1 dmGzWa"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm0 13a1 1 0 1 1 0-2 1 1 0 0 1 0 2zm1.649-5.123c-.611.384-.899.596-.899.876v.5a.75.75 0 0 1-1.5 0v-.5c0-.525.203-.998.602-1.404.295-.3.653-.525.999-.742.712-.447 1.024-.687 1.024-1.107 0-.457-.264-.762-.485-.938-.355-.282-.849-.437-1.39-.437-.821 0-1.559.548-1.796 1.333a.75.75 0 0 1-1.436-.432 3.406 3.406 0 0 1 1.2-1.721 3.344 3.344 0 0 1 2.031-.68c.878 0 1.703.271 2.322.762.679.539 1.053 1.289 1.053 2.113 0 1.293-.998 1.92-1.726 2.377z"></path>
                            </svg>
                          </Button>
                        </div>
                        {item.race_banner ? (
                          <div className="race_banner">
                            <img
                              src={item.race_banner}
                              className="race_banner_image"
                            />
                          </div>
                        ) : (
                          <div
                            className="race_banner"
                            style={{
                              gridTemplateColumns:
                                calculateGridColumnsForGameImage(
                                  item.games?.length ?? 0
                                ),
                            }}
                          >
                            {item.games.map((game, key) => {
                              if (key > 2) {
                                return null;
                              }
                              return (
                                <img
                                  src={game.game_snapshot_url}
                                  className="race_banner_image"
                                />
                              );
                            })}
                          </div>
                        )}

                        <div className="race_details">
                          {item.ending_in ? (
                            <>
                              <h2 className="title">{item.lang?.title}</h2>
                              <div className="paragraph uppercase text-center no_margin">
                                <strong>
                                  {item.lang?.campaign_information}
                                </strong>
                              </div>
                              <div className="paragraph uppercase text-center no_margin">
                                {__("ENDING IN")}
                              </div>
                              <div className="time text-center">
                                <RemainintTime timestamp={item.ending_in} />
                              </div>
                            </>
                          ) : (
                            <>
                              <h2 className="title">{item.lang?.title}</h2>
                              <div className="paragraph uppercase text-center no_margin">
                                <strong>
                                  {item.lang?.campaign_information}
                                </strong>
                              </div>
                              <div className="paragraph uppercase text-center no_margin">
                                {__("THE RACE HAS")}
                              </div>
                              <div className="time text-center">
                                {__("FINISHED")}
                              </div>
                            </>
                          )}
                          <div className="bonuses">
                            {/*<div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                SPINS
                              </div>
                              <div className="bonus_value">60</div>
                            </div>*/}
                            <div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                {__("MIN BET")}
                              </div>
                              <div className="bonus_value min_bet">
                                ${item.minimum_wager}
                              </div>
                            </div>
                            {/*<div className="single_item">
                              <div className="paragraph uppercase text-center no_margin">
                                DURATION
                              </div>
                              <div className="bonus_value">3 days</div>
                            </div>*/}
                          </div>
                          <div className="view_leadboard">
                            <Button
                              onClick={() => {
                                openLeaderBoard(item);
                              }}
                              className="load_more_btn"
                            >
                              {__("View Leaderboard")}
                            </Button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </>
              ) : (
                <>
                  <NoRecordsFound />
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" show={showDetails}>
        <Modal.Body className="cashier_modal">
          <div>
            <div
              onClick={() => {
                setShowDetails(false);
              }}
              className="modal_close_reg small"
            >
              <svg
                width="17"
                height="18"
                viewBox="0 0 17 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                  fill="#10294F"
                />
              </svg>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(campaignDetails?.lang?.description),
              }}
            ></div>
            <div>
              <h4>{__("Games supported in the race")}</h4>
              <div className="details_games game_list">
                {campaignGames.map((game, index) => (
                  <GameItem game={game} />
                ))}
              </div>
              {pagination && (
                <>
                  <LoadMore data={pagination} onPageChange={onPageChange} />

                  <div className="gameLoadingProgress">
                    <div className="gameLoadingInfo">
                      {__("You've viewed")} {gameLoaded} {__("of")} {totalGame}{" "}
                      {__("games")}
                    </div>
                    <ProgressBar
                      variant="success"
                      now={(gameLoaded / totalGame) * 100}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="providersmodal radious_all"
        size="lg"
        onHide={closeLeaderBoard}
        show={showLeaderBoard}
      >
        <Modal.Body className="no_padding">
          <div
            onClick={() => {
              setShowLeaderBoard(false);
            }}
            className="modal_close_reg small"
          >
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="leaderboard_table table-responsive">
            <Table>
              <thead>
                <tr>
                  <td>
                    <svg
                      width="27"
                      height="27"
                      viewBox="0 0 27 27"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.8212 2.26777C25.2892 1.7025 24.5344 1.37661 23.7564 1.37661H21.2791C21.2891 0.931033 21.2958 0.472206 21.2958 0H5.31857C5.31857 0.472141 5.32519 0.931033 5.33519 1.37661H2.84136C2.06326 1.37661 1.30849 1.7025 0.776469 2.26777C0.237761 2.83642 -0.0414972 3.61444 0.00500251 4.40254C0.450581 11.8309 4.28447 16.4927 10.3627 17.1843L9.18564 21.2808C7.78578 21.2808 6.65193 22.4147 6.65193 23.8145V26.601H19.9657V23.8145C19.9657 22.4147 18.8319 21.2808 17.432 21.2808L16.2549 17.1843C22.3199 16.486 26.1504 11.8276 26.5927 4.40254C26.6392 3.61782 26.3599 2.83973 25.8212 2.26777ZM2.66179 4.2429C2.65847 4.16978 2.68841 4.1199 2.71497 4.09327C2.76485 4.04008 2.82473 4.04008 2.84136 4.04008H5.46158C5.85728 9.27052 7.01107 12.2365 8.13167 13.9124C3.86552 12.1234 2.84798 7.34527 2.66179 4.2429ZM18.4827 13.9024C19.6033 12.2232 20.7538 9.26052 21.1494 4.03677H23.7597C23.7796 4.03677 23.8362 4.03677 23.886 4.08996C23.9127 4.11659 23.9425 4.16646 23.9392 4.23959C23.7497 7.34195 22.7356 12.1068 18.4827 13.9024Z"
                        fill="white"
                      />
                    </svg>
                  </td>
                  <td>{__("Player")}</td>
                  <td>{__("Wagered")}</td>
                  <td>{__("Cash Reward")}</td>
                  <td>{__("Bonus")}</td>
                </tr>
              </thead>

              <tbody>
                {leaderBoard.map((item, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>
                      <Link
                        to="#"
                        onClick={() =>
                          item.player_login
                            ? props.getPlayerStatData(
                                item.player_login,
                                "player_login"
                              )
                            : props.getPlayerStatData(
                                item.player_anonymous_tmp_id,
                                "player_anonymous_tmp_id"
                              )
                        }
                      >
                        {item.player_name}
                      </Link>
                    </td>
                    <td>${item.wagered}</td>
                    {campaignDetails.id === 27 && index > 25 ? (
                      //Special halloween race. Should show trick or treat for winners 26 onwards as they are free spins, not cash
                      <>
                        <td colSpan={2}>
                          {item.free_spins
                            ? item.free_spins + " " + __("free spins")
                            : __("Trick or Treat")}
                        </td>
                      </>
                    ) : (
                      <>
                        <td>${item.cash_prize}</td>
                        <td>${item.bonus}</td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
