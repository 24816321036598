import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";

export default function PlaySafe(props) {
  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});

  const getResults = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: props.pageUid,
    });
    if (response.status === 200) {
      setPageContent(response.data.content_data);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    // getResults();
  }, []);

  return (
    <div className="feedback_container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="feedback_content_area showFull">
              <p>
                <strong>
                  <span>
                    Miss Slotty: Your Safe and Secure Online Gambling Haven
                  </span>
                </strong>
              </p>

              <p>
                At Miss Slotty, we prioritize creating a secure and enjoyable
                gambling environment for our players. We understand the
                importance of safe online casino gambling as a source of
                entertainment without negatively impacting other aspects of your
                life.
              </p>

              <p>
                <strong>Miss Slotty&apos;s Commitment to Safe Gambling:</strong>
              </p>

              <ul type="disc">
                <li>
                  We equip our players with information about their gaming
                  habits and expenditures, empowering them to make informed
                  gambling choices.
                </li>
                <li>
                  Access to safer gambling tools, including limits, breaks, and
                  self-exclusion options, is readily available for all our
                  players.
                </li>
                <li>
                  We provide resources and support for preventing gambling harm
                  and addressing problem gambling.
                </li>
                <li>
                  Our Customer Service Team is on standby 24/7 to assist with
                  any safer gambling queries.
                </li>
                <li>
                  We employ stringent measures to prevent minors from creating
                  accounts on Miss Slotty.
                </li>
              </ul>
              <p>
                <strong>What is Safe Gambling?&nbsp;</strong>
              </p>
              <p>
                Safe gambling on Miss Slotty ensures a balanced approach, where
                gaming is one of several recreational activities. It involves
                setting clear budgets and time limits, betting within your
                financial means, taking regular breaks, understanding the odds,
                and maintaining a composed mindset during gameplay.
              </p>

              <p>
                Learn more about responsible gambling with our{" "}
                <u>responsible gambling guide</u>.
              </p>

              <p>
                <strong>Understanding the Odds&nbsp;</strong>
              </p>
              <p>
                Grasping the odds in gambling helps put winning chances into
                perspective. Miss Slotty features both Casino and Sportsbook
                options, each with distinct win rates and probabilities.
                Awareness of these can help you understand the associated risks.
              </p>

              <ul type="disc">
                <li>
                  <strong>House Advantage/Edge</strong>: This ensures a return
                  percentage to Miss Slotty, maintaining fair play.
                </li>
                <li>
                  <strong>Randomness</strong>: All games involve an element of
                  chance, with outcomes beyond player control.
                </li>
                <li>
                  <strong>Independent Outcomes</strong>: Each game or spin
                  outcome is independent, unaffected by previous plays.
                </li>
                <li>
                  <strong>Odds and Probability</strong>: These indicate your
                  chances of winning, emphasizing the unpredictable nature of
                  betting.
                </li>
              </ul>
              <p>
                <strong>Staying Safe with Miss Slotty&nbsp;</strong>
              </p>
              <p>
                We encourage players to reflect on their gaming activities while
                providing tools to maintain control and play within affordable
                limits.
              </p>

              <ul>
                <li>
                  <strong>Budget Calculator</strong>: Use our{" "}
                  <u>Monthly Budget Calculator</u> to track your gambling
                  expenses. For more insights, check out our guide on{" "}
                  <u>how much you should gamble</u>.
                </li>
                <li>
                  <strong>Gambling Limits</strong>: Set loss and wager limits
                  over daily, weekly, or monthly periods. These help manage your
                  spending and betting behaviors.
                </li>
                <li>
                  <strong>Self-Exclusion</strong>: Opt for self-exclusion from
                  Miss Slotty for periods ranging from 1 months to 5 years. If
                  you feel you have lost control or losing control, we recommend
                  getting help and self-exclude for a minimum of 6 months. For
                  self-exclude options, click <u>here</u>.
                </li>
                <li>
                  <strong>Gamban</strong>: Eligible customers can access Gamban,
                  a gambling blocking software, complimentary with certain
                  self-exclusion periods.
                </li>
              </ul>

              <p>
                <strong>Addressing Problem Gambling</strong>
              </p>
              <p>
                A minority of gamblers develop issues where gambling adversely
                affects their mental, physical, financial, and relational
                wellbeing. Signs of problem gambling include distress over
                losses, chasing losses, inability to stop, and neglecting
                responsibilities.
              </p>

              <p>
                For assistance, consult trusted individuals or professional
                agencies like Gamblers Anonymous (
                <a href="https://gamblersanonymous.org/ga/" target="_new">
                  https://gamblersanonymous.org/ga/
                </a>
                ) and{" "}
                <a href="https://www.gamblingtherapy.org/">Gambling Therapy</a>{" "}
                and contact them also;{" "}
                <a href="mailto:support@gamblingtherapy.org">
                  support@gamblingtherapy.org
                </a>
                .
              </p>

              <p>
                <strong>Protecting Minors&nbsp;</strong>
              </p>
              <p>
                Miss Slotty strictly prohibits underage gambling. We take
                measures to ensure no underage players are allowed, and winnings
                are not disbursed to minors.
              </p>

              <p>
                <strong>Parental Tips and Tools:</strong>
              </p>
              <ul type="disc">
                <li>
                  Keep gambling software password-protected and out of reach of
                  minors.
                </li>
                <li>
                  Use filtering tools like Gamcare, Netnanny, and Betblocker to
                  prevent access to inappropriate online content.
                </li>
              </ul>

              <p>
                At Miss Slotty, we are dedicated to offering a safe,
                responsible, and enjoyable gambling experience for all our
                players.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
