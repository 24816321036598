import React, { useState, useEffect } from "react";
import api from "services/api";
import auth from "services/auth";
import ScrollTo from "elements/ScrollTo";
import Header from "elements/Header";
import Footer from "elements/Footer";
import Spinner from "elements/Spinner";
import swal from "sweetalert";
import Chat from "elements/Chat";
import ls from "services/ls";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import {
  isMobile,
  isMobileByViewPort,
  cauculateLandScape,
} from "services/isMobile";
import LeftSide from "elements/LeftSide";
import LoginReg, { loginData } from "elements/LoginReg";
import StickyFooterMenu from "elements/StickyFooterMenu";
import SecurityPopup from "elements/SecurityPopup";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const FullLayout = ({ children, ...rest }) => {
  const location = useLocation();

  const [spinner, setSpinner] = useState(false);

  const [isLandscape, setIsLandscape] = useState(cauculateLandScape());

  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [isMobileDeviceByViewPort, setIsMobileDeviceByViewPort] =
    useState(false);

  const showAlert = (title, message, isError, callback) => {
    swal({
      title: title,
      text: message,
      className: theme,
      icon: isError ? "warning" : "success",
    }).then((value) => {
      if (callback) {
        callback(value);
      }
    });
  };

  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userDetails, setUserDetails] = useState(auth.getUser);
  const [isAnonymous, setIsAnonymous] = useState(true);

  //Get user balance functionality
  const [userBalance, setUserBalance] = useState({
    cash_balance: 0,
    bonus_balance: 0,
    total_balance: 0,
    wallets: [],
    rates: [],
  });
  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    console.log(response);
    ls.set("conversionRates", response.data.rates);
    setUserBalance(response.data);
  };

  const setUsersWallet = async (currency) => {
    var response = await api.post("/set-wallet", { currency });
    console.log(response);
    if (response.status === 200) {
      setUserBalance(response.data);
      ls.set("conversionRates", response.data.rates);
    }
  };

  const [accountProgress, setAccountProgress] = useState(0);
  const getAccountProgress = async (ev) => {
    var response = await api.get("/account-progress");
    console.log("accountProgress", response);
    setAccountProgress(response.data);
  };
  const [leftSidebarSlide, setLeftSidebarSlide] = useState(
    isMobile() ? false : true
  );
  useEffect(() => {
    window.addEventListener("resize", () => {
      setIsLandscape(cauculateLandScape());
    });
    setIsMobileDevice(isMobile());
    setIsMobileDeviceByViewPort(isMobileByViewPort());
    if (isMobile()) {
      setLeftSidebarSlide(false);
    } else {
      setLeftSidebarSlide(true);
    }
    var interval;
    if (isAuthenticated) {
      getAccountProgress();
      getUserBalance();
      setUserDetails(auth.getUser());
    }

    interval = setInterval(() => {
      if (isAuthenticated) {
        getUserBalance();
      }
    }, 15000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  const [searchStr, setSearchStr] = useState(""); //Used in Header.js and Games.js
  const [providers, setProviders] = useState([]); //Used in Header.js and Games.js
  const [selectedProviders, setSelectedProviders] = useState([]); //Used in Header.js and Games.js
  const getProviders = async () => {
    var response = await api.get("/games/providers");
    if (response.status === 200) {
      setProviders(response.data);
    }
  };
  const [initialSettings, setInitialSettings] = useState([]);
  const getInitialSettings = async () => {
    var response = await api.get("/initial-settings");
    if (response.status === 200) {
      setInitialSettings(response.data);
    }
  };
  useEffect(() => {
    getProviders();
    getInitialSettings();
  }, []);

  const [supportSidebarOpen, setSupportSidebarOpen] = useState(false);
  const [modalSignUpPropsData, setModalSignUpPropsData] = useState(false);
  const [modalLoginPropsData, setModalLoginPropsData] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(false);
  const [playerLiveChatSlide, setPlayerLiveChatSlide] = useState(false);

  const [openWalletModalTab, setOpenWalletModalTab] = useState(null);
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [chatInPopup, setChatInPopup] = useState(null);

  const [metamaskConnected, setMetamaskConnected] = useState(false);
  const [theme, setTheme] = useState(
    ls.get("theme") ? ls.get("theme") : "dark"
  );

  const [isRacesPageActive, setIsRacesPageActive] = useState(false);
  const getIsRacesPageActive = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "races",
    });
    if (response.status === 200) {
      setIsRacesPageActive(true);
    }
  };

  //Get Total Jackpot
  const [totalJackpot, setTotalJackpot] = useState(0);
  const [totalJackpotReLoaded, setTotalJackpotReLoaded] = useState(Date.now());
  const getTotalJackpot = async (ev) => {
    var response = await api.get("/total-jackpot-amount");
    console.log(response);
    if (response.status === 200) {
      console.log("total-jackpot-amount", response.data.totalJackpot);
      // setUserBalance(response.data);
      setTotalJackpot(response.data.totalJackpot);
      //if total jackpot amount stays the same, it won't trigger the useEffect to re-set the dummy amount
      //We need a flag variable to trigger that
      setTotalJackpotReLoaded(Date.now());
    }
  };

  useEffect(() => {
    getIsRacesPageActive();
    // getTotalJackpot();
  }, []);

  //JackpotTicker
  useEffect(() => {
    var interval;

    getTotalJackpot();

    interval = setInterval(() => {
      getTotalJackpot();
    }, 9000000);
    return () => {
      try {
        clearInterval(interval);
      } catch (ex) {}
    };
  }, []);

  const [referralCode, setReferralCode] = useState("");

  const passableParams = {
    isRacesPageActive,
    showAlert,
    isMobileDevice,
    isMobileDeviceByViewPort,
    isLandscape,
    isAuthenticated,
    totalJackpot,
    setTotalJackpot,
    setSpinner,
    spinner,
    userDetails,
    userBalance,
    getUserBalance,
    setUsersWallet,
    accountProgress,
    getAccountProgress,
    searchStr,
    setSearchStr,
    providers,
    selectedProviders,
    setSelectedProviders,
    supportSidebarOpen,
    setSupportSidebarOpen,
    modalSignUpPropsData,
    setModalSignUpPropsData,
    modalLoginPropsData,
    setModalLoginPropsData,
    showLeftSidebar,
    setShowLeftSidebar,
    leftSidebarSlide,
    setLeftSidebarSlide,
    setPlayerLiveChatSlide,
    playerLiveChatSlide,
    openWalletModalTab,
    setOpenWalletModalTab,
    isChatVisible,
    setIsChatVisible,
    initialSettings,
    metamaskConnected,
    setMetamaskConnected,
    setTheme,
    theme,
    referralCode,
    setReferralCode,
    isAnonymous,
    setIsAnonymous,
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, passableParams);
    }
    return child;
  });

  const [inPlay, setInPlay] = useState(false);
  const playPath = location.pathname.split("/");

  useEffect(() => {
    setShowLeftSidebar(false);
  }, [location.pathname]);

  useEffect(() => {
    setInPlay(playPath.includes("play"));
  }, [playPath]);
  return (
    <>
      {/* <Spinner /> */}
      {spinner && <Spinner />}

      {/* <GoogleReCaptchaProvider reCaptchaKey="6LeS_4MfAAAAAFLVe_-eLMC-ozusZM6VtZm_w3NP"> */}
      <LoginReg {...passableParams} />
      <div
        className={`home ${theme} ${
          isChatVisible && isAuthenticated ? "chat_open" : ""
        }`}
      >
        {leftSidebarSlide && isMobileDevice && (
          <>
            <div
              className="backDrop"
              onClick={() => {
                setLeftSidebarSlide(false);
              }}
            ></div>
          </>
        )}

        <SecurityPopup {...passableParams} />
        {(isMobileDevice && inPlay) || <Header {...passableParams} />}
        <div
          className={
            "main_body_v1 " +
            (leftSidebarSlide ? "slideRight " : "slideLeft ") +
            (playerLiveChatSlide ? "opendPlayerLiveChat" : "")
          }
        >
          <div
            className={
              "left_bar_v1 " +
              (isMobileDevice
                ? "mobileBar " + (leftSidebarSlide ? "showMibileBar" : "")
                : "")
            }
          >
            <LeftSide {...passableParams} />
          </div>
          <div
            className={
              "right_bar_v1 " + (playPath.includes("games") ? "gamePage" : "")
            }
          >
            <div className="container-lg">
              {/* {(isMobileDevice && inPlay) || <Header {...passableParams} />} */}
              <main>{childrenWithProps}</main>
            </div>
            {(isMobileDevice && inPlay) || <Footer {...passableParams} />}
          </div>
          <div className="player_chat_sidebar_area">
            <div className="player_chat_content">Live Chat Placeholder</div>
          </div>
        </div>
      </div>
      {isMobileDevice && (!inPlay || !isLandscape) && (
        <StickyFooterMenu {...passableParams} />
      )}

      <ScrollTo />
      {/* {isAuthenticated && (
          <Chat
            {...passableParams}
            chatInPopup={chatInPopup}
            setChatInPopup={setChatInPopup}
          />
        )} */}
      {/* </GoogleReCaptchaProvider> */}
    </>
  );
};

export default FullLayout;
