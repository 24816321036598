import React, { useState, useEffect, useRef } from "react";
import { Button } from "react-bootstrap";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/games_elements/GameItem";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";
import { Badge, Table, Modal, Collapse } from "react-bootstrap";
import api from "services/api";
import Translator, { __ } from "services/Translator";

export default function CategoryGames(props) {
  const params = useParams();
  const category = params.category;
  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const [providersModal, setProvidersModal] = useState(false);
  const closeAllModals = () => {
    setProvidersModal(false);
  };
  const toggleProviersModal = () => {
    setProvidersModal(!providersModal);
  };
  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }
    setSelectedProviders(providersClone);
  };

  const clearAllProviders = () => {
    setSelectedProviders([]);
  };
  const __ = (text) => text;

  return (
    <div className="category_games_area">
      {props.categoryName !== "" && (
        <div className="category_games_header d-none">
          <div className="headline_left">
            <h4 className="game_headline">{props.categoryName}</h4>
            {props.isMobileDevice ? <></> : <></>}
          </div>
        </div>
      )}

      <div class="game_list_v1">
        {props.gameList.map((game, index) => (
          <GameItem
            game={game}
            isAuthenticated={props.isAuthenticated}
            key={index}
          />
        ))}
      </div>
      {props.showPagination && (
        <>
          <LoadMore data={props.pagination} onPageChange={props.onPageChange} />
          <br />
        </>
      )}

      <Modal size="lg" show={providersModal} onHide={closeAllModals}>
        <Modal.Body className="providers_modal">
          <div onClick={closeAllModals} className="modal_close_reg small">
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>

          <div className="modal_headline">{__("Filter By Providers")}</div>
          <div className="providers_list">
            <Link
              hrefLang={Translator.getSelectedLanguage().code}
              onClick={clearAllProviders}
              className={
                "common_btn small " +
                (selectedProviders.length === 0 ? "active" : "inactive")
              }
              to="#"
            >
              {__("All Providers")}
            </Link>
            {providers.map((item, index) => (
              <Link
                hrefLang={Translator.getSelectedLanguage().code}
                onClick={() => toggleSelectedProvider(item.provider_id)}
                key={index}
                className={
                  "common_btn small " +
                  (selectedProviders.indexOf(item.provider_id) !== -1
                    ? "active"
                    : "inactive")
                }
                to="#"
              >
                {item.name}
              </Link>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
