import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import api from "services/api";
import auth from "services/auth";
import AppContext from "contexts/AppContext";
import emailValidate from "services/emailValidate";
import ls from "services/ls";
import Translator, { __ } from "services/Translator";

import SelectBox from "elements/SelectBox";
import CheckBox from "elements/CheckBox";
import DatePicker from "elements/DatePicker";

import { Accordion } from "react-bootstrap";

import PageHeadline from "../PageHeadline";

export default function AccessControl(props) {
  // const __ = (text) => text;

  const { updateUserObj } = React.useContext(AppContext);
  const autoOrManual = [
    { label: __("Control Type"), value: null },
    { label: __("Automatic"), value: "automatic" },
    { label: __("Manual"), value: "manual" },
  ];

  const dayWeekMonth = [
    { label: __("Time Period"), value: null },
    { label: __("Per Day"), value: "day" },
    { label: __("Per Week"), value: "week" },
    { label: __("Per Month"), value: "month" },
  ];

  const [initialSettings, setInitialSettings] = useState({
    play_time_control: 0,
  });
  const getInitialSettings = async (ev) => {
    var response = await api.get("/initial-settings");
    if (response.status === 200) {
      setInitialSettings(response.data);
    }
  };
  useEffect(() => {
    getInitialSettings();
  }, []);

  const [idUploads, setIdUploads] = useState({
    id: null,
    poa: null,
    other: [],
  });
  const [idUploadsFetched, setIdUploadsFetched] = useState(false);
  const getIdUploads = async (force) => {
    if (!idUploadsFetched || force) {
      props.setSpinner(true);
      var response = await api.get("/id-uploads");
      props.setSpinner(false);
      if (response.status === 200) {
        setIdUploads(response.data);
        setIdUploadsFetched(true);
      }
    }
  };

  const [depositLimitFetched, setDepositLimitFetched] = useState(false);
  const [depositLimit, setDepositLimit] = useState({});
  const getDepositLimit = async (force) => {
    if (!depositLimitFetched || force) {
      props.setSpinner(true);
      var response = await api.get("/deposit-limit");
      props.setSpinner(false);
      if (response.status === 200) {
        setDepositLimit(response.data);
        setDepositLimitFetched(true);
      }
    }
  };

  const [wagerLimitFetched, setWagerLimitFetched] = useState(false);
  const [wagerLimit, setWagerLimit] = useState({});
  const getWagerLimit = async (force) => {
    if (!wagerLimitFetched || force) {
      props.setSpinner(true);
      var response = await api.get("/wager-limit");
      props.setSpinner(false);
      if (response.status === 200) {
        setWagerLimit(response.data);
        setWagerLimitFetched(true);
      }
    }
  };

  const [lossLimitFetched, setLossLimitFetched] = useState(false);
  const [lossLimit, setLossLimit] = useState({});
  const getLossLimit = async (force) => {
    if (!lossLimitFetched || force) {
      props.setSpinner(true);
      var response = await api.get("/loss-limit");
      props.setSpinner(false);
      if (response.status === 200) {
        setLossLimit(response.data);
        setLossLimitFetched(true);
      }
    }
  };

  const [playTimeLimitFetched, setPlayTimeLimitFetched] = useState(false);
  const [playTimeLimit, setPlayTimeLimit] = useState({});
  const getPlayTimeLimit = async (force) => {
    if (!playTimeLimitFetched || force) {
      props.setSpinner(true);
      var response = await api.get("/play-time-limit");
      props.setSpinner(false);
      if (response.status === 200) {
        setPlayTimeLimit(response.data);
        setPlayTimeLimitFetched(true);
      }
    }
  };

  const [accessLimitFetched, setAccessLimitFetched] = useState(false);
  const [accessLimit, setAccessLimit] = useState({});
  const getAccessLimit = async () => {
    if (!accessLimitFetched) {
      props.setSpinner(true);
      var response = await api.get("/access-limit");
      props.setSpinner(false);
      if (response.status === 200) {
        setAccessLimit(response.data);
        setAccessLimitConfirm(false);
        setAccessLimitFetched(true);
      }
    }
  };

  const [accountConrolsFetched, setAccountControlsFetched] = useState(false);
  const [accountConrols, setAccountControls] = useState({});
  const getAccountControls = async () => {
    if (!accountConrolsFetched) {
      props.setSpinner(true);
      var response = await api.get("/account-controls");
      props.setSpinner(false);
      if (response.status === 200) {
        setAccountControls(response.data);
        setAccountControlsFetched(true);
      }
    }
  };

  const [idUploadProgress, setIdUploadProgress] = useState({
    id: 0,
    poa: 0,
    other: 0,
  });

  const uploadIdDocument = async (event, type) => {
    try {
      const file = event.target.files[0];
      const data = new FormData();
      data.append("file", file, file.name);
      data.append("type", type);
      var headers = {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      };
      var response = await api.post("/id-uploads", data, headers, (progressEvent) => {
        console.log(progressEvent);
        var percent = parseInt((progressEvent.loaded / progressEvent.total) * 100);
        setIdUploadProgress({ ...idUploadProgress, [type]: percent });
      });
      if (response.status === 200) {
        props.showAlert("Success!!", response.data.message);
      } else {
        props.showAlert("Warning!!", response.data.message, true);
      }
      setIdUploadProgress({ ...idUploadProgress, [type]: 0 });
      getIdUploads(true);
    } catch (err) {}
  };

  const updateDepositLimit = (value, field) => {
    if (!depositLimit || !depositLimit.id) {
      setDepositLimit({
        ...depositLimit,
        [field]: value,
      });
    }
  };
  const submitDepositLimit = async () => {
    props.setSpinner(true);
    var response = await api.post("/deposit-limit", depositLimit);
    props.setSpinner(false);

    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      getDepositLimit(true);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const updateWagerLimit = (value, field) => {
    if (!wagerLimit || !wagerLimit.id) {
      setWagerLimit({
        ...wagerLimit,
        [field]: value,
      });
    }
  };
  const submitWagerLimit = async () => {
    props.setSpinner(true);
    var response = await api.post("/wager-limit", wagerLimit);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      getWagerLimit(true);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const updateLossLimit = (value, field) => {
    if (!lossLimit || !lossLimit.id) {
      setLossLimit({
        ...lossLimit,
        [field]: value,
      });
    }
  };
  const submitLossLimit = async () => {
    props.setSpinner(true);
    var response = await api.post("/loss-limit", lossLimit);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      getLossLimit(true);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const updatePlayTimeLimit = (value, field) => {
    if (!playTimeLimit || !playTimeLimit.id) {
      setPlayTimeLimit({
        ...playTimeLimit,
        [field]: value,
      });
    }
  };
  const submitPlayTimeLimit = async () => {
    props.setSpinner(true);
    var response = await api.post("/play-time-limit", playTimeLimit);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      getPlayTimeLimit(true);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const updateAccessLimit = (value, field) => {
    if (!accessLimit || !accessLimit.id) {
      setAccessLimit({
        ...accessLimit,
        [field]: value,
      });
    }
  };
  const [accessLimitConfirm, setAccessLimitConfirm] = useState(false);
  const [accessLimitConfirmText, setAccessLimitConfirmText] = useState({});
  const submitAccessLimit = async () => {
    props.setSpinner(true);
    var response = await api.post("/access-limit", {
      ...accessLimit,
      confirm: accessLimitConfirm,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      auth.logout();
      setTimeout(() => {
        updateUserObj();
      }, 2000);
    } else if (response.status === 202) {
      setAccessLimitConfirm(true); //Wait for confirmation
      setAccessLimitConfirmText(response.data);
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const toggleAccountControl = async (field) => {
    props.setSpinner(true);
    var response = await api.post("/account-controls", {
      field: field,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setAccountControls({ ...accountConrols, [field]: response.data });
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  const getAllData = async () => {
    getIdUploads(false);
  };

  useEffect(() => {
    console.log("idUploadsFetched", idUploadsFetched);
    getAllData();
  }, [idUploadsFetched]);

  const onRefresh = () => {
    getIdUploads(true);
    setDepositLimitFetched(false);
    setWagerLimitFetched(false);
    setLossLimitFetched(false);
    setPlayTimeLimitFetched(false);
    setAccessLimitFetched(false);
    setAccountControlsFetched(false);
  };

  const accordionToggled = (lastOpenItem) => {
    if (lastOpenItem !== null) {
      lastOpenItem = parseInt(lastOpenItem);
      if (lastOpenItem === 0) {
        getIdUploads(false);
      } else if (lastOpenItem === 1) {
        getDepositLimit(false);
      } else if (lastOpenItem === 2) {
        getWagerLimit(false);
      } else if (lastOpenItem === 3) {
        getLossLimit(false);
      } else if (lastOpenItem === 4) {
        getPlayTimeLimit(false);
      } else if (lastOpenItem === 5) {
        getAccessLimit();
      } else if (lastOpenItem === 6) {
        getAccountControls();
      }
    }
  };

  return (
    <>
      <PageHeadline page_name={__("Responsible Gaming")} />
      <div className="dashboard_content history_area_full">
        <Accordion className="chimp-accordions access_control_area" onSelect={accordionToggled}>
          <Accordion.Item className="chimp-accordion d-none" eventKey="0">
            <Accordion.Header>{__("Upload Your ID")}</Accordion.Header>
            <Accordion.Body className="myaccount_input_area">
              <p>
                {__("Please upload your identification documents")}. {__("Maximum size of 2MB per file.")}
                <br />
                &nbsp;
                <br />
                {__("Accepted file formats: JPG, PNG and PDF")}.<br />
                &nbsp;
                <br />
              </p>
              <div className="form-group">
                <label for="upload_your_id">{__("Upload your ID")}</label>
                <div className="photo_view_area">
                  {!!idUploads.id && (
                    <>
                      <img className="id_image_preview upload_id" src={idUploads.id.api_photo_url} />
                      <div className={"led-glow led-" + idUploads.id.approved} title={idUploads.id.approved}></div>
                    </>
                  )}
                </div>

                {!!idUploadProgress.id && (
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: idUploadProgress.id.toString() + "%" }}
                    >
                      {idUploadProgress.id.toString() + "%"}
                    </div>
                  </div>
                )}

                {(!idUploads.id || idUploads.id.approved !== "approved") && (
                  <label className="file_upload common_btn">
                    <span className="select_file">{__("Select file (JPG, PNG or PDF)")}</span>
                    <span className="file_selected"></span>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      onChange={(ev) => {
                        uploadIdDocument(ev, "id");
                      }}
                      style={{ display: "none" }}
                    />
                  </label>
                )}
                <div className="form_error id_image"></div>
              </div>

              <div className="form-group">
                <label for="upload_your_id">{__("Proof of Address")}</label>
                <div className="photo_view_area">
                  {!!idUploads.poa && (
                    <>
                      <img className="id_image_preview upload_id" src={idUploads.poa.api_photo_url} />
                      <div className={"led-glow led-" + idUploads.poa.approved} title={idUploads.poa.approved}></div>
                    </>
                  )}
                </div>

                {!!idUploadProgress.poa && (
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{ width: idUploadProgress.poa.toString() + "%" }}
                    >
                      {idUploadProgress.poa.toString() + "%"}
                    </div>
                  </div>
                )}

                {(!idUploads.poa || idUploads.poa.approved !== "approved") && (
                  <label className="file_upload common_btn">
                    <span className="select_file">{__("Select file (JPG, PNG or PDF)")}</span>
                    <span className="file_selected"></span>
                    <input
                      type="file"
                      accept=".pdf, .jpg, .jpeg, .png"
                      onChange={(ev) => {
                        uploadIdDocument(ev, "poa");
                      }}
                      style={{ display: "none" }}
                    />
                  </label>
                )}
                <div className="form_error id_image"></div>
              </div>

              <div className="form-group">
                <label for="upload_your_id">{__("Other Documents")}</label>
                <div>
                  {idUploads.other.map((doc, index) => (
                    <React.Fragment key={index}>
                      <div className="photo_view_area">
                        <img className="id_image_preview upload_id" src={doc.api_photo_url} />
                        <div className={"led-glow led-" + doc.approved} title={doc.approved}></div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
                {!!idUploadProgress.other && (
                  <div className="progress">
                    <div
                      className="progress-bar bg-success"
                      role="progressbar"
                      style={{
                        width: idUploadProgress.other.toString() + "%",
                      }}
                    >
                      {idUploadProgress.other.toString() + "%"}
                    </div>
                  </div>
                )}

                <label className="file_upload common_btn">
                  <span className="select_file">{__("Select file (JPG, PNG or PDF)")}</span>
                  <span className="file_selected"></span>
                  <input
                    type="file"
                    accept=".pdf, .jpg, .jpeg, .png"
                    onChange={(ev) => {
                      uploadIdDocument(ev, "other");
                    }}
                    style={{ display: "none" }}
                  />
                </label>

                <div className="form_error id_image"></div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="1">
            <Accordion.Header>
              {depositLimit && depositLimit.id && <i className="fa fa-bell"></i>}
              {__("Deposit Control")}
            </Accordion.Header>
            <Accordion.Body className="myaccount_input_area">
              <h4 className="details__heading">
                {!depositLimit || !depositLimit.id
                  ? __("Please specify a deposit limit for your account")
                  : __("Deposit limit is set to the following parameters")}
              </h4>

              <div className="row">
                <div className="col-sm-6">
                  <div className="input_item">
                    <DatePicker
                      className="form-control name_value"
                      placeholder={__("Starts At")}
                      value={depositLimit.start_date}
                      minValue={moment()}
                      onChange={(itemValue) => updateDepositLimit(itemValue, "start_date")}
                      readOnly={depositLimit && depositLimit.id}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input_item">
                    <DatePicker
                      className="form-control name_value"
                      placeholder={__("Ends At")}
                      value={depositLimit.end_date}
                      minValue={depositLimit.start_date ? depositLimit.start_date : moment()}
                      onChange={(itemValue) => updateDepositLimit(itemValue, "end_date")}
                      readOnly={depositLimit && depositLimit.id}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input_item">
                    <input
                      className="form-control name_value"
                      value={depositLimit.limit_amount ? depositLimit.limit_amount.toString() : "0"}
                      onChange={(ev) => updateDepositLimit(ev.target.value, "limit_amount")}
                      placeholder={__("Amount")}
                      readOnly={depositLimit && depositLimit.id}
                    />
                  </div>
                </div>
                {(!depositLimit || !depositLimit.id) && (
                  <div className="col-sm-6">
                    <button onClick={submitDepositLimit} className="commonBtn blackBtnBG">
                      {__("Apply")}
                    </button>
                  </div>
                )}
                <div className="clearfix"></div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="2">
            <Accordion.Header>
              {wagerLimit && wagerLimit.id && <i className="fa fa-bell"></i>}
              {__("Wager Control")}
            </Accordion.Header>

            <Accordion.Body className="myaccount_input_area">
              <h4 className="details__heading">
                {!wagerLimit || !wagerLimit.id
                  ? __("Please specify a wager limit for your account")
                  : wagerLimit.control_type == "automatic"
                  ? __("Wager limit set as USD ") + wagerLimit.limit_amount + __(" per ") + wagerLimit.time_period
                  : __("Wager limit set as USD ") +
                    wagerLimit.limit_amount +
                    __(" from ") +
                    wagerLimit.show_start_date +
                    __(" to ") +
                    wagerLimit.show_end_date}
              </h4>

              <div className="row">
                <div className="col-sm-6">
                  <div className="input_item">
                    <SelectBox
                      className="form-control name_value"
                      placeholder={__("Control Type")}
                      minwidth={100}
                      value={wagerLimit.control_type}
                      onChange={(itemValue) => updateWagerLimit(itemValue, "control_type")}
                      items={autoOrManual}
                      readOnly={wagerLimit && wagerLimit.id}
                    />
                  </div>
                </div>
                {wagerLimit.control_type === "manual" && (
                  <div className="col-sm-6 date_limit_area">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="input_item">
                          <DatePicker
                            className="form-control name_value"
                            placeholder={__("Starts At")}
                            value={wagerLimit.start_date}
                            minValue={moment()}
                            onChange={(itemValue) => updateWagerLimit(itemValue, "start_date")}
                            readOnly={wagerLimit && wagerLimit.id}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input_item">
                          <DatePicker
                            className="form-control name_value"
                            placeholder={__("Ends At")}
                            value={wagerLimit.end_date}
                            minValue={wagerLimit.start_date ? wagerLimit.start_date : moment()}
                            onChange={(itemValue) => updateWagerLimit(itemValue, "end_date")}
                            readOnly={wagerLimit && wagerLimit.id}
                          />
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}

                {wagerLimit.control_type === "automatic" && (
                  <div className="col-sm-6 time_period_limit_area">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="input_item">
                          <SelectBox
                            className="form-control"
                            placeholder={__("Restrict For")}
                            minwidth={100}
                            value={wagerLimit.time_period}
                            onChange={(itemValue) => updateWagerLimit(itemValue, "time_period")}
                            items={dayWeekMonth}
                            readOnly={wagerLimit && wagerLimit.id}
                          />
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}

                <div className="col-sm-6">
                  <div className="input_item">
                    <input
                      className="form-control name_value"
                      value={wagerLimit.limit_amount ? wagerLimit.limit_amount.toString() : "0"}
                      onChange={(ev) => updateWagerLimit(ev.target.value, "limit_amount")}
                      placeholder={__("Amount")}
                      readOnly={wagerLimit && wagerLimit.id}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  {(!wagerLimit || !wagerLimit.id) && (
                    <button onClick={submitWagerLimit} className="commonBtn blackBtnBG">
                      {__("Apply")}
                    </button>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="3">
            <Accordion.Header>
              {lossLimit && lossLimit.id && <i className="fa fa-bell"></i>}
              {__("Loss Control")}
            </Accordion.Header>

            <Accordion.Body className="myaccount_input_area">
              <h4 className="details__heading">
                {!lossLimit || !lossLimit.id
                  ? __("Please specify a loss limit for your account")
                  : lossLimit.control_type == "automatic"
                  ? __("Loss limit set as USD ") + lossLimit.limit_amount + __(" per ") + lossLimit.time_period
                  : __("Loss limit set as USD ") +
                    lossLimit.limit_amount +
                    __(" from ") +
                    lossLimit.show_start_date +
                    __(" to ") +
                    lossLimit.show_end_date}
              </h4>

              <div className="row">
                <div className="col-sm-6">
                  <div className="input_item">
                    <SelectBox
                      className="form-control name_value"
                      placeholder={__("Control Type")}
                      minwidth={100}
                      value={lossLimit.control_type}
                      onChange={(itemValue) => updateLossLimit(itemValue, "control_type")}
                      items={autoOrManual}
                      readOnly={lossLimit && lossLimit.id}
                    />
                  </div>
                </div>
                {lossLimit.control_type === "manual" && (
                  <div className="col-sm-6 date_limit_area">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="input_item">
                          <DatePicker
                            className="form-control name_value"
                            placeholder={__("Starts At")}
                            value={lossLimit.start_date}
                            minValue={moment()}
                            onChange={(itemValue) => updateLossLimit(itemValue, "start_date")}
                            readOnly={lossLimit && lossLimit.id}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input_item">
                          <DatePicker
                            className="form-control name_value"
                            placeholder={__("Ends At")}
                            value={lossLimit.end_date}
                            minValue={lossLimit.start_date ? lossLimit.start_date : moment()}
                            onChange={(itemValue) => updateLossLimit(itemValue, "end_date")}
                            readOnly={lossLimit && lossLimit.id}
                          />
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}

                {lossLimit.control_type === "automatic" && (
                  <div className="col-sm-6 time_period_limit_area">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="input_item">
                          <SelectBox
                            className="form-control name_value"
                            placeholder={__("Restrict For")}
                            minwidth={100}
                            value={lossLimit.time_period}
                            onChange={(itemValue) => updateLossLimit(itemValue, "time_period")}
                            items={dayWeekMonth}
                            readOnly={lossLimit && lossLimit.id}
                          />
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                )}

                <div className="col-sm-6">
                  <div className="input_item">
                    <input
                      className="form-control name_value"
                      value={lossLimit.limit_amount ? lossLimit.limit_amount.toString() : "0"}
                      onChange={(ev) => updateLossLimit(ev.target.value, "limit_amount")}
                      placeholder={__("Amount")}
                      readOnly={lossLimit && lossLimit.id}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  {(!lossLimit || !lossLimit.id) && (
                    <button onClick={submitLossLimit} className="commonBtn blackBtnBG">
                      {__("Apply")}
                    </button>
                  )}
                </div>
                <div className="clearfix"></div>
              </div>
            </Accordion.Body>
          </Accordion.Item>

          {!!initialSettings.play_time_control && (
            <Accordion.Item className="chimp-accordion" eventKey="4">
              <Accordion.Header>
                {playTimeLimit && playTimeLimit.id && <i className="fa fa-bell"></i>}
                {__("Play Time Control")}
              </Accordion.Header>

              <Accordion.Body className="myaccount_input_area">
                <h4 className="details__heading">
                  {!playTimeLimit || !playTimeLimit.id ? (
                    <span>
                      {__("Enforce a time limit (in minutes) you will be able to play for a selected period of time.")}
                      <br />
                      <br />
                      <small>
                        {playTimeLimit.control_type == "automatic"
                          ? __(
                              "You can select per day, per week or per month. The time you select will be the amount of time you will be able to play within each unit of the selected time in total. The limit will be enforced recurringly"
                            )
                          : playTimeLimit.control_type == "manual"
                          ? __(
                              "Set a date range between which the time limit will be enforced. The time you select will be the amount of time you will be able to play within this range in total."
                            )
                          : __("Please selecte a control type for more options.")}
                      </small>
                    </span>
                  ) : playTimeLimit.control_type == "automatic" ? (
                    <span>
                      {__("Play time limit set as")} {playTimeLimit.time_limit} {__("minutes per")}{" "}
                      {playTimeLimit.time_period}
                    </span>
                  ) : (
                    <span>
                      {__("Play time limit set as")} {playTimeLimit.time_limit} {__("minutes from")}{" "}
                      {playTimeLimit.show_start_date} {__("to")} {playTimeLimit.show_end_date}
                    </span>
                  )}
                </h4>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="input_item">
                      <SelectBox
                        className="form-control name_value"
                        placeholder={__("Control Type")}
                        minwidth={100}
                        value={playTimeLimit.control_type}
                        onChange={(itemValue) => updatePlayTimeLimit(itemValue, "control_type")}
                        items={autoOrManual}
                        readOnly={playTimeLimit && playTimeLimit.id}
                      />
                    </div>
                  </div>
                  {playTimeLimit.control_type === "manual" && (
                    <div className="col-sm-6 date_limit_area">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="input_item">
                            <DatePicker
                              className="form-control name_value"
                              placeholder={__("Starts At")}
                              value={playTimeLimit.start_date}
                              minValue={moment()}
                              onChange={(itemValue) => updatePlayTimeLimit(itemValue, "start_date")}
                              readOnly={playTimeLimit && playTimeLimit.id}
                            />
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="input_item">
                            <DatePicker
                              className="form-control name_value"
                              placeholder={__("Ends At")}
                              value={playTimeLimit.end_date}
                              minValue={playTimeLimit.start_date ? playTimeLimit.start_date : moment()}
                              onChange={(itemValue) => updatePlayTimeLimit(itemValue, "end_date")}
                              readOnly={playTimeLimit && playTimeLimit.id}
                            />
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  )}

                  {playTimeLimit.control_type === "automatic" && (
                    <div className="col-sm-6 time_period_limit_area">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="input_item">
                            <SelectBox
                              className="form-control name_value"
                              placeholder={__("Restrict For")}
                              minwidth={100}
                              value={playTimeLimit.time_period}
                              onChange={(itemValue) => updatePlayTimeLimit(itemValue, "time_period")}
                              items={dayWeekMonth}
                              readOnly={playTimeLimit && playTimeLimit.id}
                            />
                          </div>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  )}

                  <div className="col-sm-6">
                    <div className="input_item">
                      <input
                        className="form-control name_value"
                        value={playTimeLimit.time_limit ? playTimeLimit.time_limit.toString() : "0"}
                        onChange={(ev) => updatePlayTimeLimit(ev.target.value, "time_limit")}
                        placeholder={__("Amount in minutes")}
                        readOnly={playTimeLimit && playTimeLimit.id}
                      />
                    </div>
                  </div>

                  <div className="col-sm-12">
                    {(!playTimeLimit || !playTimeLimit.id) && (
                      <button onClick={submitPlayTimeLimit} className="commonBtn blackBtnBG">
                        {__("Apply")}
                      </button>
                    )}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          )}
          
          <Accordion.Item className="chimp-accordion" eventKey="5">
            <Accordion.Header>{__("Access Control")}</Accordion.Header>
            <Accordion.Body className="myaccount_input_area">
              {!accessLimitConfirm ? (
                <React.Fragment>
                  <h4 className="details__heading">
                    {__("Please specify the time period that you will not be able to access your account.")}
                  </h4>

                  <div className="row">
                    <div className="col-sm-6">
                      <div className="input_item">
                        <DatePicker
                          className="form-control name_value"
                          placeholder={__("Starts At")}
                          startYear={2021}
                          value={accessLimit.start_date}
                          minValue={moment()}
                          onChange={(itemValue) => updateAccessLimit(itemValue, "start_date")}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="input_item">
                        <DatePicker
                          className="form-control name_value"
                          placeholder={__("Ends At")}
                          startYear={2021}
                          value={accessLimit.end_date}
                          minValue={accessLimit.start_date ? accessLimit.start_date : moment()}
                          onChange={(itemValue) => updateAccessLimit(itemValue, "end_date")}
                        />
                      </div>
                    </div>

                    {(!accessLimit || !accessLimit.id) && (
                      <div className="col-md-12">
                        <button onClick={submitAccessLimit} className="commonBtn blackBtnBG">
                          {__("Apply")}
                        </button>
                      </div>
                    )}
                    <div className="clearfix"></div>
                  </div>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h4 className="details__heading">
                    {accessLimitConfirmText.title}
                    <br />
                    <br />
                    <small>{accessLimitConfirmText.message}</small>
                  </h4>

                  {(!accessLimit || !accessLimit.id) && (
                    <div className="row">
                      <div className="col-6 col-md-4 col-lg-2 col-md-offset-2 col-lg-offset-4">
                        <button
                          onClick={() => setAccessLimitConfirm(false)}
                          className=" common_btn medium submit_button"
                        >
                          {__("Cancel")}
                        </button>
                      </div>
                      <div className="col-6 col-md-4 col-lg-2">
                        <button onClick={submitAccessLimit} className="common_btn medium submit_button">
                          {__("Confirm")}
                        </button>
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item className="chimp-accordion" eventKey="6">
            <Accordion.Header>{__("Account Control")}</Accordion.Header>
            <Accordion.Body className="myaccount_input_area">
              <div className="player_responsible_area">
                <CheckBox
                  type="checkbox"
                  onClick={() => {
                    toggleAccountControl("real_money_wagering_exclusion");
                  }}
                  checked={accountConrols.real_money_wagering_exclusion}
                >
                  <span>{__("Exclude your account from real money wagering")}.</span>
                </CheckBox>

                <CheckBox
                  type="checkbox"
                  onClick={() => {
                    toggleAccountControl("player_accepts_bonuses");
                  }}
                  checked={!accountConrols.player_accepts_bonuses}
                >
                  <span>{__("Exclude your account from receiving bonuses")}</span>
                </CheckBox>

                <CheckBox
                  type="checkbox"
                  onClick={() => {
                    toggleAccountControl("player_accepts_bonuses");
                  }}
                  checked={!accountConrols.player_accepts_bonuses}
                >
                  <span>{__("Exclude your account from receiving free spins")}</span>
                </CheckBox>

                <CheckBox
                  type="checkbox"
                  onClick={() => {
                    toggleAccountControl("player_accepts_subscr");
                  }}
                  checked={accountConrols.player_accepts_subscr}
                >
                  <span>{__("Receive e-mail newsletters (and promotions)")}</span>
                </CheckBox>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    </>
  );
}
