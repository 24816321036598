import { useEffect } from "react";
import Translator, { __ } from "services/Translator";
export default function VipLevelText(props) {
  // const __ = (text) => text;
  var identityText = "";
  switch (props.identity) {
    case 0:
    case 1:
      identityText = __("Beginner");
      break;
    case 2:
      identityText = __("Bronze");
      break;
    case 3:
      identityText = __("Silver");
      break;
    case 4:
      identityText = __("Gold");
      break;
    case 5:
      identityText = __("Platinum");
      break;
    case 6:
      identityText = __("Diamond");
      break;
    default:
      identityText = __("Calculating");
  }
  return identityText;
}

export function VipIcon(props) {
  var vipLevelClass = "vip_level_icon ";
  var identity = props.identity;
  // identity = 5;
  if (props.noFadedIcons) {
    switch (identity) {
      case 0:
      case 1:
        return <i className="far fa-star beginner"></i>;
        break;
      case 2:
        return <i className="far fa-star bronze"></i>;
        break;
      case 3:
        return <i className="fas fa-star silver"></i>;
        break;
      case 4:
        return <i className="fas fa-star gold"></i>;
        break;
      case 5:
        return <i className="fal fa-stars platinum"></i>;
        break;
      case 6:
        return <i className="fal fa-star-christmas diamond"></i>;
        break;
      default:
        break;
    }
  } else {
    switch (identity) {
      case 0:
      case 1:
        return <i className="far fa-star beginner"></i>;
        break;
      case 2:
        return <i className="far fa-star bronze"></i>;
        break;
      case 3:
        return <i className="fad fa-star silver"></i>;
        break;
      case 4:
        return <i className="fad fa-star gold"></i>;
        break;
      case 5:
        return <i className="fad fa-stars platinum"></i>;
        break;
      case 6:
        return <i className="fad fa-star-christmas diamond"></i>;
        break;
      default:
        break;
    }
  }
}

export function NextVipLevelText(props) {
  const __ = (text) => text;
  var identityText = "";
  switch (props.identity) {
    case 0:
    case 1:
      identityText = __("Bronze");
      break;
    case 2:
      identityText = __("Silver");
      break;
    case 3:
      identityText = __("Gold");
      break;
    case 4:
      identityText = __("Platinum");
      break;
    case 5:
      identityText = __("Diamond");
      break;
  }
  return identityText;
}
