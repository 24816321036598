import React, { useState, useEffect, useContext, useRef } from "react";
import Translator, { __ } from "services/Translator";
import api from "services/api";
import CategoryGames from "elements/games_elements/CategoryGames";

import {
  Redirect,
  Route,
  Link,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
// custom component

import GameSlider from "elements/games_elements/GameSlider";

const LandingMobileContent = (props) => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const __ = (text) => text; //Placeholder for future translations function

  const gameTypeAPISlugs = {
    all: "all",
    slots: "video_slots",
    "live-casino": "live_casino",
    "table-games": "table_games",
    search: "search_results",
    popular: "popular",
    new: "new",
    bonusbuy: "bonus_buy",
    megaways: "megaways",
    other: "other",
  };

  const gameTitles = {
    slots: __("Slots"),
    "live-casino": __("Live Casino"),
    "table-games": __("Table Games"),
    all: __("All Games"),
    popular: __("Popular Games"),
    search_results: __("Search Results"),
    new: "New",
    bonusbuy: "Bonus Buy",
    megaways: "Mega Ways",
    other: "Others",
  };

  const pageTitles = {
    slots: __(
      "Miss Slotty | Choose thrilling slots from Pragmatic, Mascot Gaming,  NetEnt and many more…"
    ),
    "live-casino": __(
      "Miss Slotty | Bring live casino games to your screen with real-live-dealer casino games from Ezugi, VIVO, Evolution, and more!"
    ),
    "table-games": __(
      "Miss Slotty | The best Video Table Games with chances of BIG winnings! "
    ),
    popular: __(
      "Miss Slotty | Check out some of the games that your fellow Miss Slotty players have been winning with!"
    ),
    new: __(
      "Miss Slotty | See what awesome games our Game Providing Partner have released recently."
    ),
    search_results: __(
      "Miss Slotty | The crypto casino with over 5.000 of games to choose from!"
    ),

    all: __(
      "Miss Slotty | The crypto casino with over 5.000 of games to choose from!"
    ),
    other: __(
      "Miss Slotty | The crypto casino with over 5.000 of games to choose from!"
    ),
  };

  const metaDescriptions = {
    slots: __(
      "Thrilling casino slot games provided by the industry’s greatest like Pragmatic, NetEnt, Betsoft, Microgaming, and many more."
    ),
    "live-casino": __(
      "Real live casino dealers for mobile and desktop devices providing 100% fair games of wager with highest pay-out rates."
    ),
    "table-games": __(
      "Gambling online with the biggest chances of winning with Mounte Olympus Casino’s variety of video table games that have RTPs."
    ),
    popular: __(
      "Online casino winners that play on the most popular slots including high volatility games and mega jackpot slot games."
    ),
    new: __(
      "Weekly fresh casino games being added to Mounte Olympus Casino’s superb catalogue with over 5.000 casino games and growing each week."
    ),
    search_results: __(
      "The online casino with the most games to choose from slots, live casino games to jackpot games and provably fair crash games."
    ),
    all: __(
      "The online casino with the most games to choose from slots, live casino games to jackpot games and provably fair crash games."
    ),
    other: __(
      "Online scratch cards and virtual sport games that have the best gambling odds for the casino players all over the world."
    ),
  };

  const gameType = params.category ? params.category : "all";

  //Backup codes
  const [pagination, setPagination] = useState(null);
  const [games, setGames] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);

  const getGames = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: 56,
      game_types: [gameTypeAPISlugs[gameType]],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      props.setSearchStr("");
      if (page === 1) {
        setGames(response.data.games);
      } else {
        var realGameType = gameTypeAPISlugs[gameType];

        var gamesClone = JSON.parse(JSON.stringify(games));

        gamesClone[realGameType].gamelist = [
          ...gamesClone[realGameType].gamelist,
          ...response.data.games[realGameType].gamelist,
        ];
        setGames(gamesClone);
      }
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    if (gameType === "search") {
      searchGames(page, null);
    } else {
      getGames(page);
    }
  };

  useEffect(() => {
    if (gameType === "search") {
      if (props.searchStr.length) {
        searchGames(1);
      } else {
        history.push("/games/all");
      }
    } else {
      getGames(1);
    }
  }, [gameSorting, gameType, props.searchStr, props.selectedProviders]);

  const searchGames = async (page) => {
    var perPage = 30;
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);

  return (
    <>
      <CategoryGames
        {...props}
        gameList={games[gameTypeAPISlugs[gameType] ?? "all"]?.gamelist ?? []}
        categoryName={gameTitles[gameType] ?? ""}
        isSearchResults={isSearchResultsShowing}
        category={gameType}
        onPageChange={onPageChange}
        pagination={pagination}
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        showPagination={true}
      />
    </>
  );
};

export default LandingMobileContent;
