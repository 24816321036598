import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import api from "../services/api";
import auth from "../services/auth";
import { Helmet } from "react-helmet";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../elements/PageHeadline";
import PlaySafe from "./content_page/PlaySafe";
import SafeGamblingTips from "./content_page/SafeGamblingTips";
import BudgetCalculator from "./content_page/BudgetCalculator";

export default function ResponsibleGambling(props) {
  const params = useParams();
  const pageTab = params.page;
  // const [pageContent, setPageContent] = useState("");
  // const getContentPage = async () => {
  //   var response = await api.get("/get-content-page", {
  //     page_uid: "responsible_gaming",
  //   });

  //   if (response.status == 200) {
  //     setPageContent(response.data);
  //   }
  // };

  // useEffect(async () => {
  //   getContentPage();
  // }, []);
  // const renderHTML = (rawHTML: string) =>
  //   React.createElement("div", {
  //     dangerouslySetInnerHTML: { __html: rawHTML },
  //   });

  // const [travelingToRight, setTravelingToRight] = useState(false);

  // useEffect(() => {
  //   setTravelingToRight(true);
  //   setTimeout(function () {
  //     setTravelingToRight(false);
  //   }, 500);

  //   // alert(tab)
  // }, []);

  return (
    <>
      <Helmet>
        <title>
          {__(
            "Miss Slotty | Your well-being is important to us. Play responsibly, read all about that in our Responsible Gaming Page."
          )}
        </title>
        <meta name="keywords" content={require("elements/Keywords").default} />
        <meta
          name="description"
          content={__(
            "Fastest pay-outs, highest winning rate, biggest jackpots, and responsible gaming features is all possible at the number 1 crypto casino."
          )}
        />
        <link
          rel="canonical"
          href={"https://casinomonteolympus.com" + window.location.pathname}
        />
      </Helmet>
      <div className="rg_container">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <ul className="rg_menu">
                <li>
                  <Link
                    to="/responsible-gambling/play-safe"
                    className={" " + (pageTab == "play-safe" ? "active" : "")}
                  >
                    {__("Play safe at Miss Slotty")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/responsible-gambling/safe-gambling-tips"
                    className={
                      " " + (pageTab == "safe-gambling-tips" ? "active" : "")
                    }
                  >
                    {__("Safe gambling tips")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/responsible-gambling/budget-calculator"
                    className={
                      " " + (pageTab == "budget-calculator" ? "active" : "")
                    }
                  >
                    {__("Budget Calculator")}
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-9">
              <Link to="/" className="modal_close_reg">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.68964 12.2073L0.9655 4.78928C0.551706 4.39188 0.275845 3.79579 0.275845 3.1997C0.275845 2.60361 0.551706 2.00752 0.9655 1.61012C1.86205 0.7491 3.37929 0.7491 4.27584 1.61012L12 9.02815L19.7241 1.61012C20.6207 0.7491 22.1379 0.7491 23.0345 1.61012C23.4483 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4483 4.39188 23.0345 4.78928L15.3103 12.2073L23.0345 19.6253C23.4483 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4483 22.4071 23.0345 22.8045C22.6207 23.2019 22 23.4668 21.3793 23.4668C20.7586 23.4668 20.1379 23.2019 19.7241 22.8045L12 15.3865L4.27584 22.8045C3.37929 23.6655 1.86205 23.6655 0.9655 22.8045C0.551706 22.4071 0.275845 21.811 0.275845 21.2149C0.275845 20.6188 0.551706 20.0227 0.9655 19.6253L8.68964 12.2073Z"
                    fill="#10294F"
                  />
                </svg>
              </Link>
              {pageTab == "play-safe" && <PlaySafe />}
              {pageTab == "safe-gambling-tips" && <SafeGamblingTips />}
              {pageTab == "budget-calculator" && <BudgetCalculator />}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </>
  );
}
