import React, { useState, useEffect, useContext, useRef } from "react";
import Translator, { __ } from "services/Translator";

import {
  Redirect,
  Route,
  Link,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
// custom component

import GameSlider from "elements/games_elements/GameSlider";

const LandingDesktopContent = (props) => {
  const params = useParams();
  const history = useHistory();
  const location = useLocation();

  return (
    <>
      {props.isAuthenticated && (
        <GameSlider
          categoryName="recently-played"
          showLebel={"Recently played"}
          {...props}
        />
      )}
      <GameSlider
        search="true"
        categoryName="Popular"
        showLebel={"Hot Games"}
        {...props}
      />

      <GameSlider categoryName="Slots" showLebel={"Slots Games"} {...props} />
      <GameSlider
        categoryName="Table Games"
        showLebel={"Table Games"}
        {...props}
      />

      <GameSlider categoryName="New Games" showLebel={"New Games"} {...props} />
      <GameSlider
        categoryName="Live Casino"
        showLebel={"Live Casino"}
        {...props}
      />
      <GameSlider categoryName="Mega Ways" showLebel={"Mega Ways"} {...props} />
      <GameSlider categoryName="Bonus Buy" showLebel={"Bonus Buy"} {...props} />
    </>
  );
};

export default LandingDesktopContent;
