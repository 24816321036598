import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import api from "services/api";
import auth from "services/auth";
import ls from "services/ls";
import config from "configs/config";
import InputBox from "elements/InputBox";
import { Accordion } from "react-bootstrap";
import Translator, { __ } from "services/Translator";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { support } from "jquery";

export default function SupportSidebar(props) {
  // const __ = (text) => text;
  const location = useLocation();
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;
  const supportSidebarOpen = props.supportSidebarOpen;
  const setSupportSidebarOpen = props.setSupportSidebarOpen;
  const selectedLanguage = Translator.getSelectedLanguage();

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const updateForm = (value, field) => {
    if (isAuthenticated && field === "email") {
      return false;
    }
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setForm({
        ...form,
        email: userDetails.email,
        name: userDetails.first_name + " " + userDetails.last_name,
      });
    }
  }, [userDetails]);

  const [selectedTab, setSelectedTab] = useState("faq"); //faq, email, chat

  const [launchUrl, setLaunchUrl] = useState(config.liveChatUrl);
  // const [launchUrl, setLaunchUrl] = useState("https://google.com");

  useEffect(() => {
    setLaunchUrl(config.liveChatUrl + "&lang=" + selectedLanguage.code);
  }, [selectedLanguage]);

  const [faqs, setFaqs] = useState([]);

  const getFaqs = async () => {
    props.setSpinner(true);
    var response = await api.get("/get-faqs");
    props.setSpinner(false);
    if (response.status === 200) {
      setFaqs(response.data);
    }
  };

  const sendMail = async () => {
    props.setSpinner(true);
    var response = await api.post("/create-ticket", form);
    props.setSpinner(false);
    if (response.status === 200) {
      props.showAlert("Success!!", response.data.message);
      setForm({ ...form, subject: "", message: "" });
    } else {
      props.showAlert("Warning!!", response.data.message, true);
    }
  };

  useEffect(() => {
    getFaqs();
  }, []);

  const onWebViewLoaded = (web) => {
    props.setSpinner(false);
  };
  const onWebViewLoadedIframe = () => {
    props.setSpinner(false);
  };

  const toggleSupportSidebar = () => {
    if (supportSidebarOpen) {
      setSupportSidebarOpen(false);
    } else {
      setSupportSidebarOpen("chat");
    }
  };

  useEffect(() => {
    // setSupportSidebarOpen(false);
  }, [location.pathname]);

  const accordionToggled = (lastOpenItem) => {
    if (lastOpenItem !== null) {
      lastOpenItem = parseInt(lastOpenItem);
    }
  };

  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      {supportSidebarOpen && <div className="invisible_overlay" onClick={toggleSupportSidebar}></div>}
      {/*<a onClick={toggleSupportSidebar} href="#" className="support_btn">
        {__("Get Support")}
      </a>*/}
      <div className={"support_panel support_panel_area " + (supportSidebarOpen ? "open" : "")} id="autoprocessed">
        <a onClick={toggleSupportSidebar} className="close_icon" href="#">
          <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.807617 20L19.1924 1.61522" stroke="#1F2933" stroke-width="1.5" stroke-linecap="round" />
            <path d="M0.807617 2L19.1924 20.3848" stroke="#1F2933" stroke-width="1.5" stroke-linecap="round" />
          </svg>
        </a>

        <div className="panel_header">
          <h3>{__("Support")}</h3>
        </div>
        <Tabs
          onSelect={(activeKey, ev) => setSupportSidebarOpen(activeKey)}
          activeKey={supportSidebarOpen}
          id="fill-tab-example"
          className="supportbar_tabs"
        >
          <Tab eventKey="faq" title="FAQ">
            <Accordion onSelect={accordionToggled}>
              {faqs.map((faqCategory, index) => (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header className="faq-section faq__question">
                    {faqCategory.category}
                    <i></i>
                  </Accordion.Header>
                  <Accordion.Body className="faq_category_details">
                    <Accordion>
                      {faqCategory.faqs.map((faq, index2) => (
                        <Accordion.Item key={index2} eventKey={index2} className="faq-item">
                          <Accordion.Header>{faq.title}</Accordion.Header>
                          <Accordion.Body className="faq-body">{faq.description}</Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </Tab>
          <Tab eventKey="support" title="Email Us">
            <div id="support_email_us" className="email_us">
              {__("You can Live Chat with out Support Team.")}
              <br />

              {__(
                "Click on the Live Support tab when openening the support panel. Feel free to ask them any question you have regarding Miss Slotty, your account or (Live) games on the website."
              )}
              <br />
              <br />

              {__("If you want to send us a message through our contact form then you can send it from here.")}

              <div className="contact_us_area">
                <div className="row">
                  <div className="reg_field col-sm-6">
                    <InputBox
                      type="text"
                      className="form-control required_field"
                      // readOnly={isAuthenticated}
                      required={true}
                      value={form.name}
                      onChange={(value) => updateForm(value, "name")}
                      placeholder={__("Full Name")}
                    />
                  </div>
                  <div className="reg_field col-sm-6">
                    <InputBox
                      type="email"
                      className="form-control required_field"
                      readOnly={isAuthenticated}
                      required={true}
                      value={form.email}
                      onChange={(value) => updateForm(value, "email")}
                      placeholder={__("Email")}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="reg_field col-sm-12">
                    <InputBox
                      required
                      type="text"
                      className="form-control required_field"
                      value={form.subject}
                      onChange={(value) => updateForm(value, "subject")}
                      placeholder={__("Subject")}
                    />
                  </div>
                </div>

                <div className="reg_field">
                  <textarea
                    required
                    name="message"
                    className="form-control required_field"
                    rows="10"
                    value={form.message}
                    onChange={(ev) => updateForm(ev.target.value, "message")}
                    placeholder={__("Message")}
                  ></textarea>
                </div>
                <input type="hidden" name="g-recaptcha-response" value="" />
                <div className="reg-submit">
                  <button onClick={sendMail} className="commonBtn blackBtnBG margin_auto">
                    {__("Submit")}
                  </button>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="chat" title="Live Chat">
            <iframe className="support_chat" src={launchUrl} onLoad={onWebViewLoadedIframe} />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}
