import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import api from "../services/api";
import { Helmet } from "react-helmet";
import Translator, { __ } from "services/Translator";
import PageHeadline from "../elements/PageHeadline";

export default function TermsAndconditions(props) {
  const params = useParams();
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "terms_conditions",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect(async () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });

  const [travelingToRight, setTravelingToRight] = useState(false);

  useEffect(() => {
    setTravelingToRight(true);
    setTimeout(function () {
      setTravelingToRight(false);
    }, 500);

    // alert(tab)
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {__(
            "Miss Slotty | We have the best terms and odds in favor of our Miss Slotty crypto casino players."
          )}
        </title>
        <meta name="keywords" content={require("elements/Keywords").default} />
        <meta
          name="description"
          content={__(
            "Fair casino policies which have the best odds and rules for the casino players in the crypto gambling industry. "
          )}
        />
        <link
          rel="canonical"
          href={"https://casinomonteolympus.com" + window.location.pathname}
        />
      </Helmet>
      <div className="container">
        <br />
        <br />
        <PageHeadline page_name={__("Terms & conditions")} />
        <br />
        <div className="privacy_policy">
          {/* <h1 className="page_title title">
            {pageContent && pageContent.content_data.page_title}
          </h1> */}

          <p className="paragraph desc">
            {pageContent && renderHTML(pageContent.content_data.content)}
          </p>
        </div>
      </div>
    </>
  );
}
