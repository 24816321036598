import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import Slider from "react-slick";
import JackpotTicker from "elements/JackpotTicker";
export default function VipBanner(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    // initialSlide: 1,
  };
  return (
    <div className="home_banners">
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="home_banner_area">
            <Slider {...settings}>
              <div className="home_banner_single">
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/banners/vip_950x500.png")
                      .default
                  }
                />
                <div className="banner_content d-none">
                  <h2 className="banner_heading">
                    {__("Miss")} <span>{__("Slotty")}</span> {__("VIP Club")}
                  </h2>
                  {/* <p className="banner_details">
                    {__(
                      "We are excited to partner with Raphinha in one of the greatest years in World Cup history for football fans."
                    )}
                  </p> */}
                  <p className="banner_details">
                    {__("Where Loyalty Meets Luxury!")}
                  </p>
                  <Link to="/games/all" className="commonBtn bannerBtn">
                    {__("Play Now")}
                  </Link>
                </div>
              </div>
              <div className="home_banner_single ">
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/banners/promo-950x500.png")
                      .default
                  }
                />
                <div className="banner_content d-none">
                  <h2 className="banner_heading">{__("Welcome Package")}</h2>
                  {/* <p className="banner_details">
                    {__(
                      "We are excited to partner with Raphinha in one of the greatest years in World Cup history for football fans."
                    )}
                  </p> */}
                  <p className="banner_details">
                    <span>{__("200%")}</span> {__("up to €100")}
                  </p>
                  <Link to="/games/all" className="commonBtn bannerBtn">
                    {__("Play Now")}
                  </Link>
                </div>
              </div>
              <div className="home_banner_single">
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/banners/slots_950x500.png")
                      .default
                  }
                />
                <div className="banner_content d-none">
                  <h2 className="banner_heading">
                    {__("Miss")} <span>{__("Slotty")}</span> {__("Paradise")}
                  </h2>
                  {/* <p className="banner_details">
                    {__(
                      "We are excited to partner with Raphinha in one of the greatest years in World Cup history for football fans."
                    )}
                  </p> */}
                  <p className="banner_details">
                    {__("Over 6000 Games of Pure Thrill!")}
                  </p>
                  <Link to="/games/all" className="commonBtn bannerBtn">
                    {__("Play Now")}
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
        </div>

        <div className="clearfix"></div>
      </div>
    </div>
  );
}
