import React, { useState } from "react";
import moneyFormatter from "services/moneyFormatter";

const BudgetCalculator = (props) => {
  const [income, setIncome] = useState({
    wages: 0.0,
    pensions: 0.0,
    benefits: 0.0,
    otherIncome: 0.0,
  });

  const [expenses, setExpenses] = useState({
    rent: 0,
    utilityBills: 0,
    loans: 0,
    otherExpenses: 0,
  });

  const handleIncomeChange = (e) => {
    const { name, value } = e.target;
    setIncome((prevIncome) => ({
      ...prevIncome,
      [name]: parseFloat(value),
    }));
  };

  const handleExpensesChange = (e) => {
    const { name, value } = e.target;
    setExpenses((prevExpenses) => ({
      ...prevExpenses,
      [name]: parseFloat(value),
    }));
  };

  const totalIncome = Object.values(income).reduce((a, b) => a + b, 0);
  const totalExpenses = Object.values(expenses).reduce((a, b) => a + b, 0);
  const disposableIncome = totalIncome - totalExpenses;

  const handleFocus = (event) => {
    // Select the input value when the input gains focus

    event.target.select();
  };

  return (
    <>
      <div className="budget-calculator">
        <h2>Monthly Budget Calculator</h2>
        <p>
          Your information is confidential and is not visible to Miss Slotty.
        </p>
        <div className="calculator-section">
          <div className="income-section">
            <h3>Income</h3>
            {Object.keys(income).map((key) => (
              <div key={key} className="input-group">
                <div className="heading">
                  <label>
                    {key.charAt(0).toUpperCase() +
                      key.slice(1).replace(/([A-Z])/g, " $1")}
                  </label>
                  <label>
                    {moneyFormatter.currencyIcon("USD")}{" "}
                    {moneyFormatter.balanceFormat(income[key])}
                  </label>
                </div>
                <input
                  type="number"
                  name={key}
                  value={income[key]}
                  onFocus={handleFocus}
                  onChange={handleIncomeChange}
                  step="0.01"
                />
              </div>
            ))}
            <div className="input-group">
              <div className="heading">
                <label>Total income</label>
                <label>
                  {moneyFormatter.currencyIcon("USD")}{" "}
                  {moneyFormatter.balanceFormat(totalIncome)}
                </label>
              </div>
              <input type="number" value={totalIncome.toFixed(2)} readOnly />
            </div>
          </div>
          <div className="expenses-section">
            <h3>Expenses</h3>
            {Object.keys(expenses).map((key) => (
              <div key={key} className="input-group">
                <div className="heading">
                  <label>
                    {key.charAt(0).toUpperCase() +
                      key.slice(1).replace(/([A-Z])/g, " $1")}
                  </label>
                  <label>
                    {moneyFormatter.currencyIcon("USD")}{" "}
                    {moneyFormatter.balanceFormat(expenses[key])}
                  </label>
                </div>

                <input
                  type="number"
                  name={key}
                  value={expenses[key]}
                  onFocus={handleFocus}
                  onChange={handleExpensesChange}
                  step="0.01"
                />
              </div>
            ))}
            <div className="input-group">
              <div className="heading">
                <label>Total expenses</label>
                <label>
                  {moneyFormatter.currencyIcon("USD")}{" "}
                  {moneyFormatter.balanceFormat(totalExpenses)}
                </label>
              </div>
              <input type="number" value={totalExpenses.toFixed(2)} readOnly />
            </div>
          </div>
        </div>
        <div className="disposable-income-section">
          <h3>Disposable income</h3>
          {/* <input type="number" value={disposableIncome.toFixed(2)} readOnly /> */}
          <div className="total_amount">
            <div className="heading">
              <label>
                {moneyFormatter.currencyIcon("USD")}{" "}
                {moneyFormatter.balanceFormat(disposableIncome)}
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BudgetCalculator;
