import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "services/api";
import DOMPurify from "dompurify";
import BudgetCalculatorComponent from "elements/BudgetCalculator";

export default function BudgetCalculator(props) {
  const isAuthenticated = props.isAuthenticated;
  const [pageContent, setPageContent] = useState({});

  const getResults = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: props.pageUid,
    });
    if (response.status === 200) {
      setPageContent(response.data.content_data);
    } else {
      props.showAlert(null, response.data.message, true);
    }
  };

  useEffect(() => {
    // getResults();
  }, []);

  return <BudgetCalculatorComponent {...props} />;
}
