import React, { useState, useEffect } from "react";

import { __ } from "services/Translator";
import api from "services/api";
import { Modal, Accordion } from "react-bootstrap";
import { isMobile } from "services/isMobile";
import { Link } from "react-router-dom";

export default function FiatCashier(props) {
  // const depositAmounts = [10, 20, 50, 100, 200, 500, 1000, 5000];

  const depositAmounts = [10, 50, 150, 20, 75, 250, 30, 100, 500];
  const [depositAmount, setDepositAmount] = useState(10);
  const [isCustomAmount, setIsCustomAmount] = useState(false);
  const [depositMethods, setDepositMethods] = useState([]);
  const [depositMethod, setDepositMethod] = useState(null);
  const [bonusCampaigns, setBonusCampaigns] = useState([]);
  const [bonusCampaign, setBonusCampaign] = useState("no_bonus");
  const [depositFinalData, setDepositFinalData] = useState(null);
  const [infoUrl, setInfoUrl] = useState("");

  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const userBalance = props.userBalance;

  const [depositForm, setDepositForm] = useState({});
  const [depositModalOpen, setDepositModalOpen] = useState(false);
  const [deleteBonusModalOpen, setDeleteBonusModalOpen] = useState(false);
  const [customDepositAmountOpen, setCustomDepositAmountOpen] = useState(false);
  const [depositFinalModalOpen, setDepositFinalModalOpen] = useState(false);
  const [readCarefullyModalOpen, setReadCarefullyModalOpen] = useState(false);

  const [creditCardInfo, setCreditCardInfo] = useState({
    NameOnCC: "",
    CardNumber: "",
    ExpMonth: "",
    ExpYear: "",
    CVV: "",
  });

  const [regErrors, setRegErrors] = useState({
    NameOnCC: "",
    CardNumber: "",
    ExpMonth: "",
    ExpYear: "",
    CVV: "",
  });

  const toggleDepositModal = () => {
    if (!depositModalOpen) {
      getDepositMethods();
    }
    setDepositModalOpen(!depositModalOpen);
  };
  const toggleDeleteBonusModalOpen = () => {
    setDeleteBonusModalOpen(!deleteBonusModalOpen);
  };
  const toggleReadCarefullyModal = () => {
    setReadCarefullyModalOpen(!readCarefullyModalOpen);
  };
  const toggleDepositFinalModalOpen = () => {
    setDepositFinalModalOpen(!depositFinalModalOpen);
  };

  useEffect(() => {
    getDepositMethods();
  }, [depositAmount]);

  const getDepositMethods = async () => {
    if (depositAmount) {
      props.setSpinner(true);
      var response = await api.post("/deposit/methods", {
        amount: depositAmount,
      });
      props.setSpinner(false);
      if (response.status === 200) {
        setDepositMethods(response.data.methods);
        if (
          !response.data.methods.filter(
            (method) => method.method_id === depositMethod
          ).length
        ) {
          setDepositMethod(null);
        }
        setBonusCampaigns(response.data.campaigns);
        if (
          !response.data.campaigns.filter(
            (campaign) => campaign.bonus_campaign.id === bonusCampaign
          ).length
        ) {
          setBonusCampaign("no_bonus");
        }
      }
    }
  };

  const submitDeposit = async (event) => {
    event.preventDefault();
    props.setSpinner(true);

    if (depositMethod == "BzPay:CC") {
      var valid = validateCCForm();
      if (!valid) {
        props.setSpinner(false);
        return;
      }
    }

    var postData = {
      amount: depositAmount,
      // amount: 1,
      method: depositMethod,
      campaign: bonusCampaign,
    };

    if (depositMethod == "BzPay:CC") {
      postData.creditCardInfo = creditCardInfo;
    }

    var response = await api.post("/deposit", postData);
    props.setSpinner(false);
    if (response.status === 200) {
      if (
        response.data.status === "APPROVED" &&
        response.data.fields_for === "BzPay:CC"
      ) {
        // setDepositFinalData(response.data.data);

        props.showAlert(__("Success"), __(response.data.message));

        setCreditCardInfo({
          NameOnCC: "",
          CardNumber: "",
          ExpMonth: "",
          ExpYear: "",
          CVV: "",
        });
        setDepositModalOpen(false);
      } else if (
        response.data.status === "additional_fields" &&
        response.data.fields_for === "webpayment"
      ) {
        setDepositModalOpen(false);
        setDepositFinalData(response.data.data);
        setDepositFinalModalOpen(true);
      } else {
        props.showAlert(__("Warning!!"), __(response.data.message), true);
      }
    } else {
      props.showAlert(__("Warning!!"), __(response.data.message), true);
    }
  };

  const doDeleteBonuses = async () => {
    props.setSpinner(true);
    var response = await api.post("/delete-bonus");
    if (response.status === 200) {
      props.setSpinner(false);
      toggleDeleteBonusModalOpen();
      props.getUserBalance();
      props.showAlert(__("Warning!!"), __(response.data.message));
    } else {
      props.showAlert(__("Warning!!"), __(response.data.message));
    }
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
    setDepositFinalData(null);
  }, []);

  const updateCCInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    setCreditCardInfo({ ...creditCardInfo, [field]: value });
  };

  const validateCCForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};
    // alert(44);

    if (
      creditCardInfo.NameOnCC.length < 2 ||
      creditCardInfo.NameOnCC.length > 250
    ) {
      errorsTmp = { ...errorsTmp, NameOnCC: __("Please enter the name") };
      valid = false;
    }

    if (
      creditCardInfo.CardNumber.length < 2 ||
      creditCardInfo.CardNumber.length > 250
    ) {
      errorsTmp = {
        ...errorsTmp,
        CardNumber: __("Please enter the card number"),
      };
      valid = false;
    }

    if (
      creditCardInfo.CardNumber.length < 2 ||
      creditCardInfo.CardNumber.length > 250
    ) {
      errorsTmp = {
        ...errorsTmp,
        CardNumber: __("Please enter the card number"),
      };
      valid = false;
    }

    if (creditCardInfo.ExpMonth.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        ExpMonth: __("Please enter the expiry month"),
      };
      valid = false;
    }

    if (creditCardInfo.ExpYear.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        ExpYear: __("Please enter the expiry year"),
      };
      valid = false;
    }

    if (creditCardInfo.CVV.length < 1) {
      errorsTmp = {
        ...errorsTmp,
        CVV: __("Please enter the CVV"),
      };
      valid = false;
    }

    setRegErrors(errorsTmp);
    return valid;
  };

  return (
    <>
      <div className="button_group">
        <Link onClick={toggleDepositModal} className="commonBtn blackBtnBG">
          {__("Deposit")}
        </Link>
        <Link to="/dashboard/withdraw" className="commonBtn blackBtnBG deep">
          {__("Withdraw")}
        </Link>
      </div>
      <Modal size="lg" show={depositModalOpen} onHide={toggleDepositModal}>
        <Modal.Body className={"login_modal " + props.theme}>
          <div onClick={toggleDepositModal} className="modal_close_reg small">
            <svg
              width="17"
              height="18"
              viewBox="0 0 17 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="modal_headline">{__("Deposit")}</div>
          <div className="deposit-methods">
            <div className="deposit-method">
              <div className="alert d-none alert-danger"></div>
              <form
                method="post"
                id="internalDepositForm"
                className="depositform"
                action={""}
                onSubmit={submitDeposit}
              >
                <div className="modal_amount_area">
                  <div className="input_item">
                    <div className="name">{__("Amount")}</div>

                    <div className="chimp-radio-field">
                      {depositAmounts.map((val, index) => (
                        <div
                          className="cashier_amount_lavel"
                          key={index}
                          style={{ marginRight: 10 }}
                        >
                          <input
                            type="radio"
                            className="deposit_campaign_check"
                            id={"fiat_amount_selector" + val}
                            onChange={(ev) => {
                              setDepositAmount(val);
                              setCustomDepositAmountOpen(false);
                            }}
                            checked={depositAmount === val}
                            value={val}
                          />

                          <label
                            for={"fiat_amount_selector" + val}
                            className="radio_area"
                          >
                            <div className="radio_check_and_text_area">
                              <div className="radio_box">
                                <div className="radio_checked_box"></div>
                              </div>
                              <div className="radio_text">
                                <span className="currency">{val}</span>
                              </div>
                            </div>
                          </label>
                        </div>
                      ))}
                      <div className="cashier_amount_lavel">
                        <input
                          type="radio"
                          id="CustomDepositAmount"
                          className="deposit_campaign_check"
                          onChange={(ev) => {
                            setDepositAmount(depositAmount.toString());
                            setCustomDepositAmountOpen(ev.target.checked);
                          }}
                          checked={customDepositAmountOpen}
                        />
                        <label
                          for={"CustomDepositAmount"}
                          className="radio_area"
                        >
                          <div className="radio_check_and_text_area">
                            <div className="radio_box">
                              <div className="radio_checked_box"></div>
                            </div>
                            <div className="radio_text">
                              <span className="currency">
                                {__("Custom Amount")}
                              </span>
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal_amount_area">
                  <div></div>
                  {customDepositAmountOpen && (
                    <div className="form_area">
                      <div className="input_item">
                        <input
                          type="number"
                          className="custom_ut_value form-control name_value "
                          placeholder="Amount"
                          value={depositAmount.toString()}
                          onChange={(ev) =>
                            setDepositAmount(
                              parseInt(ev.target.value).toString()
                            )
                          }
                        />
                        <br />
                        &nbsp;
                        <br />
                      </div>
                    </div>
                  )}
                  <div></div>
                </div>
                <strong>{__("Payment Method")}</strong>
                <div className="reg-field chimp-select-field payment_method_list">
                  <div className="banking_area">
                    <div className="banking_list_area">
                      {depositMethods.map((method, index) => (
                        <>
                          {method.method_id.indexOf("WEBPAYMENT:") > -1 ? (
                            ""
                          ) : (
                            <div className={"single_banking e_wallet_method "}>
                              <input
                                id={"all_" + method.id}
                                onChange={() => {
                                  setDepositMethod(method.method_id);
                                  // alert(method.method_id);
                                }}
                                type="radio"
                                checked={method.method_id === depositMethod}
                              />
                              <label htmlFor={"all_" + method.id}>
                                <ul>
                                  <li className="payment_logo_area">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">
                                      <span className="helper"></span>
                                      <span className="method_logo_text">
                                        {__("Method Logo")}
                                      </span>
                                    </span>
                                    <div
                                      className="method_logo"
                                      style={{
                                        backgroundImage:
                                          "url(" + method.logo + ")",
                                      }}
                                    ></div>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="payment_method_name">
                                    <span className="helper"></span>
                                    <span className="payment_text_mobile">
                                      {__("Method Name")}
                                    </span>
                                    <span className="payment_content">
                                      {" "}
                                      {method.method}
                                    </span>
                                    <div className="clearfix"></div>
                                  </li>
                                  <li className="min_payment_amount">
                                    <span className="payment_text_mobile">
                                      {__("Limit Amount")}
                                    </span>
                                    <span className="payment_content">
                                      {__("MIN")} {method.min_withdrawal}
                                    </span>
                                    <div className="clearfix"></div>
                                  </li>
                                </ul>
                              </label>
                              <label
                                htmlFor={"all_" + method.id}
                                className="payment_check_mark"
                              >
                                <i className="fas fa-check selected_method_icon"></i>
                              </label>
                            </div>
                          )}
                        </>
                      ))}

                      <div className="chimp-accordions access_control_area payment_methods_accordions">
                        <Accordion>
                          <Accordion.Item
                            key={0}
                            eventKey={0}
                            className="chimp-accordion"
                          >
                            <Accordion.Header className="faq__question">
                              {__("Crypto")}
                            </Accordion.Header>
                            <Accordion.Body className="faq__answer">
                              {depositMethods.map((method, index) => (
                                <>
                                  {method.method_id.indexOf("WEBPAYMENT:") >
                                  -1 ? (
                                    <div
                                      className={
                                        "single_banking e_wallet_method "
                                      }
                                    >
                                      <input
                                        id={"all_" + method.id}
                                        onChange={() => {
                                          setDepositMethod(method.method_id);
                                          // alert(method.method_id);
                                        }}
                                        type="radio"
                                        checked={
                                          method.method_id === depositMethod
                                        }
                                      />
                                      <label htmlFor={"all_" + method.id}>
                                        <ul>
                                          <li className="payment_logo_area">
                                            <span className="helper"></span>
                                            <span className="payment_text_mobile">
                                              <span className="helper"></span>
                                              <span className="method_logo_text">
                                                {__("Method Logo")}
                                              </span>
                                            </span>
                                            <div
                                              className="method_logo"
                                              style={{
                                                backgroundImage:
                                                  "url(" + method.logo + ")",
                                              }}
                                            ></div>
                                            <div className="clearfix"></div>
                                          </li>
                                          <li className="payment_method_name">
                                            <span className="helper"></span>
                                            <span className="payment_text_mobile">
                                              {__("Method Name")}
                                            </span>
                                            <span className="payment_content">
                                              {" "}
                                              {method.method}
                                            </span>
                                            <div className="clearfix"></div>
                                          </li>
                                          <li className="min_payment_amount">
                                            <span className="payment_text_mobile">
                                              {__("Limit Amount")}
                                            </span>
                                            <span className="payment_content">
                                              {__("MIN")}{" "}
                                              {method.min_withdrawal}
                                            </span>
                                            <div className="clearfix"></div>
                                          </li>
                                        </ul>
                                      </label>
                                      <label
                                        htmlFor={"all_" + method.id}
                                        className="payment_check_mark"
                                      >
                                        <i className="fas fa-check selected_method_icon"></i>
                                      </label>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="campaigns-wrap">
                  <label>
                    <h3>{__("Bonus Campaigns")}</h3>
                  </label>
                  <div className="campaigns">
                    {bonusCampaigns.length ? (
                      <>
                        {bonusCampaigns.map((campaign, index) => (
                          <div className="campaign">
                            <input
                              type="radio"
                              id={campaign.uniqid + "_id"}
                              value={campaign.bonus_campaign.id}
                              checked={
                                bonusCampaign === campaign.bonus_campaign.id
                              }
                              onChange={(ev) =>
                                setBonusCampaign(campaign.bonus_campaign.id)
                              }
                            />
                            <label
                              htmlFor={campaign.uniqid + "_id"}
                              className="check_box"
                            >
                              <i className="fas fa-check"></i>
                            </label>{" "}
                            <label
                              htmlFor={campaign.uniqid + "_id"}
                              className="campaign_label"
                            >
                              {campaign.bonus_campaign.title}
                              {campaign.bonus_campaign.campaign_information && (
                                <a
                                  className="info_popover"
                                  data-trigger="hover"
                                  href="javascript:"
                                  data-toggle="popover"
                                  img={campaign.campaign_image}
                                  content={
                                    campaign.bonus_campaign.campaign_information
                                  }
                                >
                                  <i
                                    className="fa fa-info-circle"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              )}
                            </label>
                            <div className="clearfix"></div>
                          </div>
                        ))}
                        <div className="campaign">
                          <input
                            id="no_bonus"
                            checked={bonusCampaign === "no_bonus"}
                            onChange={() => setBonusCampaign("no_bonus")}
                            type="radio"
                            value="no_bonus"
                          />
                          <label htmlFor="no_bonus" className="check_box">
                            <i className="fas fa-check"></i>
                          </label>{" "}
                          <label htmlFor="no_bonus" className="campaign_label">
                            {__("No Bonus")}
                          </label>
                        </div>
                      </>
                    ) : (
                      <div className="campaign">
                        <input
                          id="no_bonus"
                          checked={true}
                          type="radio"
                          onChange={() => setBonusCampaign("no_bonus")}
                        />
                        <label htmlFor="no_bonus" className="check_box">
                          <i className="fas fa-check"></i>
                        </label>
                        {userDetails.acceptsBonus ? (
                          <>
                            <label
                              htmlFor="no_bonus"
                              className="campaign_label"
                            >
                              {__("No bonus available")}
                            </label>
                          </>
                        ) : (
                          <label htmlFor="no_bonus" className="campaign_label">
                            {__("You have chosen not to accept bonus")}
                          </label>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={
                    "credit_card_from_area " +
                    (depositMethod == "BzPay:CC" ? "d-block" : "d-none")
                  }
                >
                  <div className="form_area">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Card Name")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="text"
                            name="NameOnCC"
                            class={"form-control name_value "}
                            value={creditCardInfo.NameOnCC}
                            onChange={updateCCInput}
                          />
                          <div class="errorMsg">{regErrors.NameOnCC}</div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input_item">
                          <div className="name">
                            {__("Card Number")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="CardNumber"
                            class={"form-control name_value "}
                            value={creditCardInfo.CardNumber}
                            onChange={updateCCInput}
                          />
                          <div class="errorMsg">{regErrors.CardNumber}</div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">
                            {__("Expiry Month")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="ExpMonth"
                            step="1"
                            min="1"
                            max="12"
                            placeholder="EX: 01"
                            class={"form-control name_value "}
                            value={creditCardInfo.ExpMonth}
                            onChange={updateCCInput}
                          />
                          <div class="errorMsg">{regErrors.ExpMonth}</div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">
                            {__("Expiry Year")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="ExpYear"
                            step="1"
                            min="1900"
                            placeholder="EX: 2022"
                            value={creditCardInfo.ExpYear}
                            onChange={updateCCInput}
                            class={"form-control name_value "}
                          />
                          <div class="errorMsg">{regErrors.ExpYear}</div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input_item">
                          <div className="name">
                            {__("CVV")}
                            <span className="requried_icon">*</span>
                          </div>
                          <input
                            type="number"
                            name="CVV"
                            class={"form-control name_value "}
                            value={creditCardInfo.CVV}
                            onChange={updateCCInput}
                          />
                          <div class="errorMsg">{regErrors.CVV}</div>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>

                <div className="form_error ubonus_campaign"></div>
                <div className="reg-submit">
                  <button
                    type="submit"
                    className="commonBtn blackBtnBG uppercase"
                  >
                    {__("Continue")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="cashierModal"
        size="lg"
        show={depositFinalModalOpen}
        onHide={toggleDepositFinalModalOpen}
      >
        <Modal.Header className="modal-header">
          <h5 className="modal-title">{__("Complete Deposit")}</h5>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={toggleDepositFinalModalOpen}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="">
          {!!depositFinalData && !!depositFinalData.invoice_url && (
            <iframe
              style={{ width: "100%", minHeight: "350px" }}
              src={depositFinalData.invoice_url}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
