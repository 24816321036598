import React from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import Slider from "react-slick";
import JackpotTicker from "elements/JackpotTicker";
export default function HomeBanner(props) {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    // initialSlide: 1,
  };
  return (
    <div className="home_banners">
      <div className="row align-items-center">
        <div className="col-md-12">
          <div className="home_banner_area">
            <Slider {...settings}>
              <div className="home_banner_single ">
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/banners/promo-950x500.png")
                      .default
                  }
                />
                <div className="banner_content">
                  <h2 className="banner_heading">{__("Welcome Package")}</h2>
                  {/* <p className="banner_details">
                    {__(
                      "We are excited to partner with Raphinha in one of the greatest years in World Cup history for football fans."
                    )}
                  </p> */}
                  <p className="banner_details">
                    <span>{__("200%")}</span> {__("up to €100")}
                  </p>
                  <Link to="/games/all" className="commonBtn bannerBtn">
                    {__("Play Now")}
                  </Link>
                </div>
              </div>
              <div className="home_banner_single">
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/banners/vip_950x500.png")
                      .default
                  }
                />
                <div className="banner_content">
                  <h2 className="banner_heading">
                    {__("Miss")} <span>{__("Slotty")}</span> {__("VIP Club")}
                  </h2>
                  {/* <p className="banner_details">
                    {__(
                      "We are excited to partner with Raphinha in one of the greatest years in World Cup history for football fans."
                    )}
                  </p> */}
                  <p className="banner_details">
                    {__("Where Loyalty Meets Luxury!")}
                  </p>
                  <Link to="/games/all" className="commonBtn bannerBtn">
                    {__("Play Now")}
                  </Link>
                </div>
              </div>
              <div className="home_banner_single">
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/banners/slots_950x500.png")
                      .default
                  }
                />
                <div className="banner_content">
                  <h2 className="banner_heading">
                    {__("Miss")} <span>{__("Slotty")}</span> {__("Paradise")}
                  </h2>
                  {/* <p className="banner_details">
                    {__(
                      "We are excited to partner with Raphinha in one of the greatest years in World Cup history for football fans."
                    )}
                  </p> */}
                  <p className="banner_details">
                    {__("Over 6000 Games of Pure Thrill!")}
                  </p>
                  <Link to="/games/all" className="commonBtn bannerBtn">
                    {__("Play Now")}
                  </Link>
                </div>
              </div>
            </Slider>
          </div>
        </div>
        <div className="col-md-3 d-none">
          <div className="banner_top_game_lists">
            <h3>{__("Top Game Today")}</h3>
            <ul>
              <li>
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/common/top-game-bg.png")
                      .default
                  }
                />
                <div className="top_winner_count">980 Winners Today</div>
                <Link className="top_winner_game_name">
                  <span className="text">Crash</span>
                  <span className="icon commonBtn bannerBtn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M3.40784 8.26709H14.2884"
                        stroke="#057383"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.83716 3.76709L14.2883 8.26709L9.83716 12.7671"
                        stroke="#057383"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Link>
              </li>
              <li>
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/common/top-game-bg-3.png")
                      .default
                  }
                />
                <div className="top_winner_count">980 Winners Today</div>
                <Link className="top_winner_game_name">
                  <span className="text">Crash</span>
                  <span className="icon commonBtn bannerBtn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M3.40784 8.26709H14.2884"
                        stroke="#057383"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.83716 3.76709L14.2883 8.26709L9.83716 12.7671"
                        stroke="#057383"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Link>
              </li>
              <li>
                <img
                  alt=""
                  src={
                    require("../../assets/images/kjb/common/top-game-bg-2.png")
                      .default
                  }
                />
                <div className="top_winner_count">980 Winners Today</div>
                <Link className="top_winner_game_name">
                  <span className="text">Crash</span>
                  <span className="icon commonBtn bannerBtn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                    >
                      <path
                        d="M3.40784 8.26709H14.2884"
                        stroke="#057383"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.83716 3.76709L14.2883 8.26709L9.83716 12.7671"
                        stroke="#057383"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {!props.isMobileDevice && (
          <div className="col-md-12">
            <ul className="banner_items">
              <li className="bg-one">
                <Link>
                  <span className="icon">
                    <img
                      className="spinIcon"
                      alt=""
                      src={
                        require("../../assets/images/kjb/icons/spin-icon.png")
                          .default
                      }
                    />
                  </span>
                  <span className="text">
                    {__("20 Free Spins For Your Registration!")}
                  </span>
                </Link>
              </li>
              <li className="bg-two">
                <Link>
                  <span className="icon">
                    <img
                      alt=""
                      className="tickIcon"
                      src={
                        require("../../assets/images/kjb/icons/tick-icon.png")
                          .default
                      }
                    />
                  </span>
                  <span className="text">
                    {__("All Our Games Are 100% Provavly Fair")}
                  </span>
                </Link>
              </li>
              <li className="bg-three">
                <Link>
                  <span className="icon">
                    <img
                      alt=""
                      className="boxIcon"
                      src={
                        require("../../assets/images/kjb/icons/box-icon.png")
                          .default
                      }
                    />
                  </span>
                  <span className="text">
                    {__("Welcom Package Up To $4,000 + 250 Spins")}
                  </span>
                </Link>
              </li>
              <li className="bg-four jackpotTicker">
                <div className="heading">{__("Total Jackpots")}</div>
                <JackpotTicker {...props} />
              </li>
            </ul>
          </div>
        )}

        <div className="clearfix"></div>
      </div>
    </div>
  );
}
